/** @format */

import React from 'react';
import config from 'config';
import { routeService } from 'services';
import { Switch, Route } from 'react-router-dom';
import RoutedComponent from 'components/RoutedComponent';

/** Containers sorted according to the registration flow order */

/**
 * Registration views
 */
import PageNotFound from 'views/Static/PageNotFound';
import Start from 'views/Registration/Start';
import LoginMode from 'views/Registration/LoginMode';
import Login from 'views/Registration/Login';
import BankIdRedirect from 'components/BankIdRedirect';
import LoggingIn from 'views/Common/LoggingIn';
import LoggingOut from 'views/Common/LoggingOut';
import Welcome from 'views/Registration/Welcome';
import PersonalInfo from 'views/Registration/PersonalInfo';
import ValidateEmail from 'views/Registration/ValidateEmail';
import ValidateEmailSuccess from 'views/Registration/ValidateEmailSuccess';
import SetValidityForPowerOfAttorney from 'views/Common/SetValidityForPowerOfAttorney';
import FaqPowerOfAttorney from 'views/Common/FaqPowerOfAttorney';
import AgreeToTerms from 'views/Common/AgreeToTerms';
import StripePayment from 'views/Common/StripePayment';
import ApprovePowerOfAttorney from 'views/Common/ApprovePowerOfAttorney';
import SignPowerOfAttorney from 'views/Common/SignPowerOfAttorney';
import TermsAndConditions from 'views/Common/TermsAndConditions';
import PrivacyPolicy from 'views/Common/PrivacyPolicy';
import CreatingPowerOfAttorney from 'views/Common/CreatingPowerOfAttorney';
import AddStatePension from 'views/Registration/AddStatePension';
import RegistrationComplete from 'views/Registration/RegistrationComplete';

/**
 * Onboardiing views
 */
import WelcomeToOnboarding from 'views/Onboarding/WelcomeToOnboarding';

/**
 * Profile page views
 */

import MyAccount from 'views/Dashboard/MyAccount';
import Subscription from 'views/Dashboard/Subscription';
import Email from 'views/Dashboard/Email';
import HowWeChooseFunds from 'views/Common/HowWeChooseFunds';
import Cookies from 'views/Common/Cookies';
import HowWeCalculate from 'views/Common/HowWeCalculate';
import SustainabilityPolicy from 'views/Common/SustainabilityPolicy';

/**
 * common views
 */
import Alert from 'react-bootstrap/Alert';
import { isMobile } from 'react-device-detect';

const {
  constants: { flows, views, colors, flowOrigins }
} = config;

export default function Routes() {
  const [show, setShow] = React.useState(false);

  return (
    <>
      {/** Temporary alert for users on mobile devices */}
      {show && isMobile && (
        <Alert
          variant='danger'
          className='small-screen-alert'
          onClose={() => setShow(false)}
          dismissible>
          <p>
            Webb-versionen av tjänsten är inte anpassad för mindre skärmar och
            vi rekommenderar att du registrerar på en enhet med större skärm, t
            ex dator.
          </p>
        </Alert>
      )}
      <Switch>
        <RoutedComponent /*** start-page ***/
          exact={true}
          isPrivateRoute={false}
          Component={Start}
          flow={flows.registration}
          view={views.start}
          path={routeService.register_start}
          btnColor={colors.pink}
        />
        <RoutedComponent /*** login-mode : same-device or another-device ***/
          exact={true}
          isPrivateRoute={false}
          Component={LoginMode}
          flow={flows.registration}
          view={views.login_mode}
          path={routeService.register_login_mode}
          btnColor={colors.pink}
        />
        <RoutedComponent /*** login-page: enter ssn and submit ***/
          exact={true}
          isPrivateRoute={false}
          Component={Login}
          flow={flows.registration}
          view={views.login}
          path={routeService.register_login}
          btnColor={colors.pink}
        />
        <RoutedComponent /*** redirect page to redirect from criipto iframe back to thethirdact ***/
          exact={true}
          isPrivateRoute={false}
          Component={BankIdRedirect}
          flow={flows.registration}
          view={views.bankid_redirect}
          path={routeService.register_bankid_redirect}
        />
        <RoutedComponent /*** logging-in page: this is where the criipto tokens will be sent to aws for authentiation ***/
          exact={true}
          isPrivateRoute={false}
          Component={LoggingIn}
          flow={flows.common}
          view={views.logging_in}
          path={routeService.common_logging_in}
          btnColor={colors.pink}
        />
        <RoutedComponent /*** logout-loading page: this is where the criipto tokens will be sent to aws for authentiation ***/
          exact={true}
          isPrivateRoute={false}
          Component={LoggingOut}
          flow={flows.common}
          view={views.logging_out}
          path={routeService.common_logging_out}
          btnColor={colors.pink}
        />
        <RoutedComponent /*** welcome-page: welcome the logged in user with a message***/
          exact={true}
          isPrivateRoute={true}
          Component={Welcome}
          flow={flows.registration}
          view={views.welcome}
          path={routeService.register_welcome}
          btnColor={colors.pink}
          stepsWizard={[true, false, false, false, false]}
        />
        <RoutedComponent /*** personal-info page: collect or update user account details obtained from roaring.io ***/
          exact={true}
          isPrivateRoute={true}
          Component={PersonalInfo}
          flow={flows.registration}
          view={views.personal_info}
          path={routeService.register_personal_info}
          btnColor={colors.pink}
          stepsWizard={[true, false, false, false, false]}
        />
        <RoutedComponent /*** validate-email: email vaildation step using aws cognito ***/
          exact={true}
          isPrivateRoute={true}
          Component={ValidateEmail}
          flow={flows.registration}
          view={views.validate_email}
          path={routeService.register_validate_email}
          btnColor={colors.pink}
          stepsWizard={[true, false, false, false, false]}
        />
        <RoutedComponent /*** temporary page: this view will show when the email validation is procesing in the background ***/
          exact={true}
          isPrivateRoute={true}
          Component={ValidateEmailSuccess}
          flow={flows.registration}
          view={views.validating_email}
          path={routeService.register_validating_email}
          btnColor={colors.pink}
        />
        <RoutedComponent /*** power-of-attorney-settings-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={SetValidityForPowerOfAttorney}
          flow={flows.registration}
          view={views.set_validity_for_power_of_attorney}
          path={routeService.register_set_power_of_attorney}
          btnColor={colors.pink}
          stepsWizard={[false, true, false, false, false]}
        />
        <RoutedComponent /*** power-of-attorney-faq-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={FaqPowerOfAttorney}
          flow={flows.registration}
          view={views.faq_power_of_attorney}
          path={routeService.register_faq_power_of_attorney}
          btnColor={colors.pink}
          stepsWizard={[false, true, false, false, false]}
        />
        <RoutedComponent /*** agree-to-terms-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={AgreeToTerms}
          flow={flows.registration}
          view={views.agree_to_terms}
          path={routeService.register_agree_to_terms}
          btnColor={colors.pink}
          stepsWizard={[false, false, true, false, false]}
        />

        <RoutedComponent /***stripe-payment-page***/
          exact={true}
          isPrivateRoute={true}
          Component={StripePayment}
          flow={flows.registration}
          view={views.stripe_payment}
          path={routeService.register_stripe_payment}
          btnColor={colors.pink}
          stepsWizard={[false, false, false, true, false]}
        />

        <RoutedComponent /*** power-of-attorney-signing-and-approval-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={ApprovePowerOfAttorney}
          flow={flows.registration}
          view={views.approve_power_of_attorney}
          path={routeService.register_approve_power_of_attorney}
          btnColor={colors.pink}
          stepsWizard={[false, false, false, false, true]}
        />
        <RoutedComponent /*** power-of-attorney-digital-signature-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={SignPowerOfAttorney}
          flow={flows.registration}
          view={views.sign_power_of_attorney}
          path={routeService.register_sign_power_of_attorney}
          btnColor={colors.pink}
        />
        <RoutedComponent /*** terms-and-conditions-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={TermsAndConditions}
          flow={flows.registration}
          view={views.terms_and_conditions}
          path={routeService.register_terms_and_conditions}
          btnColor={colors.blue}
        />
        <RoutedComponent /*** privacy-policy-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={PrivacyPolicy}
          flow={flows.registration}
          view={views.privacy_policy}
          path={routeService.register_privacy_policy}
          btnColor={colors.pink}
        />
        <RoutedComponent /*** creating-power-of-attorney-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={CreatingPowerOfAttorney}
          flow={flows.registration}
          view={views.creating_power_of_attorney}
          path={routeService.register_creating_power_of_attorney}
          btnColor={colors.blue}
        />
        <RoutedComponent /*** add-state-pension-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={AddStatePension}
          flow={flows.registration}
          view={views.add_state_pension}
          path={routeService.register_add_state_pension}
          btnColor={colors.pink}
        />
        <RoutedComponent /*** thank-you-for-registering-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={RegistrationComplete}
          flow={flows.registration}
          view={views.registration_complete}
          path={routeService.register_registration_complete}
        />
        <RoutedComponent /*** onboarding welcome page ***/
          exact={true}
          isPrivateRoute={true}
          Component={WelcomeToOnboarding}
          flow={flows.onboarding}
          view={views.welcome_to_onboarding}
          path={routeService.onboarding_welcome_to_onboarding}
        />
        <RoutedComponent /*** profile--my-account page ***/
          exact={true}
          isPrivateRoute={true}
          Component={MyAccount}
          flow={flows.dashboard}
          view={views.my_account}
          path={routeService.profile_my_account}
          btnColor={colors.pink}
        />
        <RoutedComponent /*** profile--subscription page ***/
          exact={true}
          isPrivateRoute={true}
          Component={Subscription}
          flow={flows.dashboard}
          view={views.subscription}
          path={routeService.profile_subscription}
          btnColor={colors.pink}
        />
        <RoutedComponent /*** profile--edit email page ***/
          exact={true}
          isPrivateRoute={true}
          Component={Email}
          flow={flows.dashboard}
          view={views.email}
          path={routeService.profile_email}
          btnColor={colors.pink}
        />

        {/*
         * START start new subscription flow
         */}

        <RoutedComponent /*** power-of-attorney-settings-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={SetValidityForPowerOfAttorney}
          flow={flows.common}
          view={views.set_validity_for_power_of_attorney}
          path={routeService.profile_set_power_of_attorney}
          btnColor={colors.pink}
          flowOrigin={flowOrigins.profile}
        />
        <RoutedComponent /*** power-of-attorney-faq-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={FaqPowerOfAttorney}
          flow={flows.common}
          view={views.faq_power_of_attorney}
          path={routeService.profile_faq_power_of_attorney}
          btnColor={colors.pink}
          flowOrigin={flowOrigins.profile}
        />
        <RoutedComponent /*** agree-to-terms-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={AgreeToTerms}
          flow={flows.common}
          view={views.agree_to_terms}
          path={routeService.profile_agree_to_terms}
          btnColor={colors.pink}
          flowOrigin={flowOrigins.profile}
        />

        <RoutedComponent /***stripe-payment-page***/
          exact={true}
          isPrivateRoute={true}
          Component={StripePayment}
          flow={flows.common}
          view={views.stripe_payment}
          path={routeService.profile_stripe_payment}
          btnColor={colors.pink}
          flowOrigin={flowOrigins.profile}
        />

        <RoutedComponent /*** power-of-attorney-signing-and-approval-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={ApprovePowerOfAttorney}
          flow={flows.common}
          view={views.approve_power_of_attorney}
          path={routeService.profile_approve_power_of_attorney}
          btnColor={colors.pink}
          flowOrigin={flowOrigins.profile}
        />
        <RoutedComponent /*** power-of-attorney-digital-signature-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={SignPowerOfAttorney}
          flow={flows.common}
          view={views.sign_power_of_attorney}
          path={routeService.profile_sign_power_of_attorney}
          btnColor={colors.pink}
          flowOrigin={flowOrigins.profile}
        />
        <RoutedComponent /*** terms-and-conditions-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={TermsAndConditions}
          flow={flows.common}
          view={views.terms_and_conditions}
          path={routeService.profile_terms_and_conditions}
          btnColor={colors.blue}
          flowOrigin={flowOrigins.profile}
        />
        <RoutedComponent /*** how-we-choose-funds-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={HowWeChooseFunds}
          flow={flows.common}
          view={views.how_we_choose_funds}
          path={routeService.profile_how_we_choose_funds}
          btnColor={colors.blue}
          flowOrigin={flowOrigins.profile}
        />

        <RoutedComponent /*** how-we-calculate-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={HowWeCalculate}
          flow={flows.common}
          view={views.how_we_calculate}
          path={routeService.profile_how_we_calculate}
          btnColor={colors.blue}
          flowOrigin={flowOrigins.profile}
        />

        <RoutedComponent /*** sustainability-policy-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={SustainabilityPolicy}
          flow={flows.common}
          view={views.sustainability_policy}
          path={routeService.profile_sustainability_policy}
          btnColor={colors.blue}
          flowOrigin={flowOrigins.profile}
        />

        <RoutedComponent /*** cookies-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={Cookies}
          flow={flows.common}
          view={views.cookies}
          path={routeService.profile_cookies}
          btnColor={colors.blue}
          flowOrigin={flowOrigins.profile}
        />
        <RoutedComponent /*** privacy-policy-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={PrivacyPolicy}
          flow={flows.common}
          view={views.privacy_policy}
          path={routeService.profile_privacy_policy}
          btnColor={colors.pink}
          flowOrigin={flowOrigins.profile}
        />

        <RoutedComponent /*** redirect page to redirect from criipto iframe back to thethirdact for profile originated actions ***/
          exact={true}
          isPrivateRoute={false}
          Component={BankIdRedirect}
          flow={flows.common}
          view={views.bankid_redirect}
          path={routeService.profile_bankid_redirect}
          flowOrigin={flowOrigins.profile}
        />

        <RoutedComponent /*** creating-power-of-attorney-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={CreatingPowerOfAttorney}
          flow={flows.common}
          view={views.creating_power_of_attorney}
          path={routeService.profile_creating_power_of_attorney}
          btnColor={colors.blue}
          flowOrigin={flowOrigins.profile}
        />

        {/*
         * END of start new subscription flow
         */}

        <Route>
          <PageNotFound flow={flows.common} view={views.page_not_found} />
        </Route>
      </Switch>
    </>
  );
}
