/** @format */

import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routeService from 'services/routeService';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardLayout from 'components/Layout/DashboardLayout';
import RowItemBlock from 'views/Dashboard/MyAccount/RowItemBlock';
import LineItemBlock from 'views/Dashboard/MyAccount/LineItemBlock';
import ActivityHistory from 'views/Dashboard/Subscription/ActivityHistory';
import CancelSubscriptionModal from 'components/Modals/CancelSubscriptionModal';
import {
  cancelSubscriptionAction,
  fetchListSubscriptionAction
} from 'stateContainer/actions';

export default function Subscription(props) {
  const { t } = useTranslation();
  const { tprop } = props;

  const [getShowModal, setShowModal] = useState(false);
  const [getCancelCompleted, setCancelCompleted] = useState(false);
  const [getStartNewSubscription, setStartNewSubscription] = useState(false);

  const subscriptionState = useSelector((state) => state.subscription);
  const appRoutingState = useSelector((state) => state.appRouting);
  const { subscriptionId = '', stripeCustomerId = '' } =
    subscriptionState.data || {};

  const dispatch = useDispatch();

  useEffect(() => {
    stripeCustomerId.length > 0 &&
      dispatch(fetchListSubscriptionAction({ customerId: stripeCustomerId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelSubscription = () => {
    setShowModal(true);

    dispatch(
      cancelSubscriptionAction({
        subscriptionId
      })
    );
  };

  const abortCancellation = () => {
    setShowModal(false);
  };

  const setCancellationComplete = () => {
    setShowModal(false);
    setCancelCompleted(true);
  };

  let { price = '', currentPeriodTo = '', subscriptions = [], payments = [] } =
    subscriptionState.data || {};
  price = price / 100;

  currentPeriodTo = currentPeriodTo * 1000;
  currentPeriodTo = moment(currentPeriodTo).format('YYYY-MM-DD');

  const selector = appRoutingState.isSubscriptionCancelled
    ? 'inactive'
    : 'active';

  const subscriptionInfoObject = t(
    `lang_${props.flow}:${props.view}.information.${selector}.items`,
    { price: price, nextPaymentDate: currentPeriodTo }
  );

  const showCancelConfirm = getShowModal && appRoutingState.isUserSubscribed;
  const showCancelProcessing =
    appRoutingState.isUserSubscribed && subscriptionState.requesting;

  const showCancelCompleted =
    !subscriptionState.requesting &&
    !appRoutingState.isUserSubscribed &&
    appRoutingState.isSubscriptionCancelled;

  const manageSubscription = () => {
    if (appRoutingState.isSubscriptionCancelled) {
      setStartNewSubscription(true);
    } else {
      setShowModal(true);
    }
  };

  return (
    <>
      <DashboardLayout
        {...props}
        hidePreviousButton={false}
        goBackUrl={routeService.profile_my_account}>
        {getStartNewSubscription && (
          <Redirect to={routeService.profile_set_power_of_attorney} />
        )}

        {getCancelCompleted && (
          <Redirect
            to={`${routeService.onboarding_welcome_to_onboarding}?profile_update=success}`}
          />
        )}

        <div className='profile profile-view'>
          <div className='profile-container'>
            <div className='profile-heading'>
              <h1>{tprop.heading.text}</h1>
            </div>

            <div className='profile-subscription-container'>
              <RowItemBlock heading={tprop.information.heading.text}>
                {subscriptionInfoObject.map((item, key) => (
                  <LineItemBlock
                    key={key}
                    editButton={item.props && item.props.editButton}
                    onClick={() => manageSubscription()}>
                    <input
                      className='edit-subscription-input form-control'
                      value={item.text}
                      disabled
                    />
                  </LineItemBlock>
                ))}
              </RowItemBlock>
            </div>

            <ActivityHistory
              {...props}
              t={t}
              selector={selector}
              payments={payments}
              subscriptions={subscriptions}
            />
          </div>

          <CancelSubscriptionModal
            tprop={tprop}
            abortCancellation={abortCancellation}
            setCancellationComplete={setCancellationComplete}
            getShowModal={getShowModal}
            showCancelConfirm={showCancelConfirm}
            cancelSubscription={cancelSubscription}
            showCancelCompleted={showCancelCompleted}
            showCancelProcessing={showCancelProcessing}
          />
        </div>
      </DashboardLayout>
    </>
  );
}
