/** @format */

import { mutations } from 'services';
import { push } from 'connected-react-router';
import { put, takeLatest, call } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  try {
    yield call(mutations.addPOASpecialCompanyMutation, payload);

    if (payload.isLastItemInArray) {
      yield put(push(payload.successUrl));
      yield put({
        type: actionTypes.ADD_POA_SPECIAL_COMPANY_SUCCESS
      });

      /**
       * update appRouting state with registration progress
       */

      yield put({
        type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
        payload: { storePOASpecialCompanyData: 'done' }
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.ADD_POA_SPECIAL_COMPANY_FAILED,
      data: error.errors[0]
    });
    yield put(push(payload.failedUrl));
  }
}

export function* addPOASpecialCompanySaga() {
  yield takeLatest(actionTypes.ADD_POA_SPECIAL_COMPANY_REQUESTED, workerSaga);
}
