/** @format */

import { fetchRoaringDataService } from 'services';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ ssn }) {
  try {
    /**
     * fetch person-data from roaring
     */

    const response = yield call(fetchRoaringDataService, {
      ssn
    });

    /**
     * update person data state with info fetched from roaring
     */

    yield put({
      type: actionTypes.ROARING_DATA_SUCCESS,
      data: response.data.posts[0]
    });

    /**
     * update appRouting state with registration progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { fetchRoaringPersonData: 'done' }
    });
  } catch (error) {
    /**
     * throw error when the roaring call fails
     */

    yield put({ type: actionTypes.ROARING_DATA_FAILED, error });
  }
}

export function* fetchRoaringDataSaga() {
  yield takeLatest(actionTypes.ROARING_DATA_REQUESTED, workerSaga);
}
