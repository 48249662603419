/** @format */

import { push } from 'connected-react-router';
import { confirmVerificationCodeService } from 'services';
import { put, takeLatest, call } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  try {
    const response = yield call(confirmVerificationCodeService, payload);
    const isEmailVerified =
      response && response.email && response.email !== null;

    /**
     * update email verification state with verfication status
     */

    yield put({
      type: actionTypes.CONFIRM_VERIFICATION_CODE_SUCCESS,
      data: response,
      isEmailVerified
    });

    /**
     * update appRouting state with registration progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_DATA_SUCCESS,
      payload: { isEmailVerified }
    });

    /**
     * update appRouting state with registration progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { confirmEmailVerificationCode: 'done' }
    });

    yield put(push(payload.successUrl));
  } catch (error) {
    yield put({
      type: actionTypes.CONFIRM_VERIFICATION_CODE_FAILED,
      data: error.message
    });
    yield put(push(payload.failedUrl));
  }
}

export function* confirmVerificationCodeSaga() {
  yield takeLatest(actionTypes.CONFIRM_VERIFICATION_CODE_REQUESTED, workerSaga);
}
