/** @format */

import config from 'config';
import { isMobile } from 'react-device-detect';

const {
  // scope,
  client_id,
  same_device,
  sign_redirect_uri,
  // response_type,
  another_device,
  force_same_device,
  wa
} = config.app.criipto.settings;

const { sign } = config.app.criipto.endpoints;

// choose to trigger an eid service in same device / another device
const getAcrValues = (samedevice) => {
  let acr_values = another_device;
  if (samedevice) {
    acr_values = JSON.parse(samedevice) ? same_device : another_device;
  } else if (force_same_device || isMobile) {
    acr_values = same_device;
  }
  return acr_values;
};

const getCriiptoDigitalSignatureUri = ({
  samedevice,
  signtext,
  clientReturnUrl
}) => {
  const acr_values = getAcrValues(samedevice);
  const digitalSignatureApiUri = `${sign}?wa=${wa}&wtrealm=${client_id}&wreply=${sign_redirect_uri}?clientReturnUrl=${clientReturnUrl}&selectedSignMethod=${acr_values}&wauth=${acr_values}&signtext=${signtext}&ui_locales=sv`;

  return digitalSignatureApiUri;
};

export default getCriiptoDigitalSignatureUri;
