/** @format */

import moment from 'moment';
import config from 'config';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import { routeService } from 'services';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import ButtonLoader from 'components/ButtonLoader';
import FormField from 'components/Forms/FormField';
import { getNumericValue } from 'utils/geNumericValue';
import { useDispatch, useSelector } from 'react-redux';
import SplitLayout from 'components/Layout/SplitLayout';
import { createUserRequestAction } from 'stateContainer/actions';
import { getIsUserExists } from 'views/Registration/PersonalInfo/getIsUserExists';
import { validationSchema } from 'views/Registration/PersonalInfo/validationSchema';

const { defaultDateValue } = config.app;

export default function PersonalInfo(props) {
  const { tprop } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [getPhoneNumber, setPhoneNumber] = useState('');

  const userState = useSelector((state) => state.user);
  const companyState = useSelector((state) => state.company);
  const roaringState = useSelector((state) => state.roaring.data);
  const sessionState = useSelector((state) => state.session.data);
  const emailVerificationState = useSelector((state) => state.verification);

  const roaringUserAddress = roaringState.address || {};
  const roaringUserDetails = roaringState.details || {};

  let { city = '', postalNumber = '', deliveryAddress2 = '' } =
    ('nationalRegistrationAddress' in roaringUserAddress &&
      roaringUserAddress.nationalRegistrationAddress[0]) ||
    {};

  let { surName = '', gender = '', birthDate = '', firstName = '' } =
    (roaringUserDetails.length > 0 && roaringUserDetails[0]) || {};

  /**
   * if the user has aleardy verified their email, the data must be available in the user state
   */

  if (Object.keys(roaringState).length === 0) {
    gender = userState.data.sex;
    birthDate = userState.data.birthDate;
    firstName = userState.data.firstName;
    surName = userState.data.lastName;
    city = userState.data.city;
    postalNumber = userState.data.postCode;
    deliveryAddress2 = userState.data.address1;
  }

  const initialValues = {
    address: deliveryAddress2,
    city: city,
    postCode: postalNumber,
    email: userState.data.email || '',
    phone: userState.data.phone || ''
  };

  const formattedBirthDate = moment(birthDate).format('YYYY-MM-DD');

  const onSubmit = (values) => {
    const { email, phone } = values;
    const {
      isPhoneNumberExists,
      isEmailVerified,
      isUserExists
    } = getIsUserExists({
      values,
      userState,
      companyState,
      emailVerificationState
    });

    let organisationId = companyState.defaultCompany.organisationId;

    /** user has chosen one of the many valid employers */

    if (
      companyState.chosenEmployer &&
      companyState.chosenEmployer.organisationId.length > 0
    ) {
      organisationId = companyState.chosenEmployer.organisationId;
    }

    /** is currently employed */

    if (companyState.currentEmployer) {
      organisationId = companyState.currentEmployer.organisationId;
    }

    /** is not currently employed and has one valid employer */

    if (
      companyState.hasValidEmployer &&
      !companyState.currentEmployer &&
      !companyState.hasMultipleEmployers
    ) {
      organisationId = companyState.data[0].organisationId;
    }

    const data = {
      fid: sessionState.fid,
      ssn: sessionState.ssn,
      firstName,
      lastName: surName,
      sex: gender,
      birthDate: formattedBirthDate,
      phone: phone,
      email,
      organisationId,
      address1: deliveryAddress2,
      city,
      postCode: postalNumber,
      defaultDateValue,
      isUserExists,
      isEmailVerified,
      isPhoneNumberExists,
      successUrl: routeService.register_validate_email,
      setPowerOfAttorneyUrl: routeService.register_set_power_of_attorney
    };

    dispatch(createUserRequestAction(data));
  };

  return (
    <div className='personal-info-view' id='registration-flow'>
      <SplitLayout
        flow={props.flow}
        stepsWizard={props.stepsWizard}
        className='postlogin-template'
        backgroundImage='registration-welcome-left-background-image'>
        <div className='text-left personal-info-container'>
          <div className='split-layout-right-heading-container'>
            <h1>{tprop.heading.text}</h1>
          </div>
          <div className='split-layout-right-subheading-container'>
            <p className='subheading-text'>{tprop.description.text}</p>
            <p>{`${firstName} ${surName}`}</p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema(t)}
            onSubmit={onSubmit}>
            <Form className='form-group'>
              <div className='content'>
                <Row>
                  <Col>
                    <FormField
                      name='address'
                      labeltext={tprop.input[0].label}
                    />
                  </Col>
                </Row>
                <Row className='address2-container'>
                  <Col sm={8}>
                    <FormField
                      sm={1}
                      name='city'
                      labeltext={tprop.input[1].label}
                    />
                  </Col>

                  <Col sm={4}>
                    <FormField
                      sm={1}
                      name='postCode'
                      labeltext={tprop.input[2].label}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormField
                      sm={1}
                      name='email'
                      labeltext={tprop.input[3].label}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormField
                      sm={1}
                      name='phone'
                      onChange={(e) =>
                        setPhoneNumber(getNumericValue(e.target.value))
                      }
                      value={getPhoneNumber}
                      labeltext={tprop.input[4].label}
                    />
                  </Col>
                </Row>
              </div>
              <div className='split-layout-right-form-button-container'>
                <div className='text-center'>
                  {userState.requesting ? (
                    <ButtonLoader />
                  ) : (
                    <Button className={`${props.btnColor}`} type='submit'>
                      {tprop.buttons.text}
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </SplitLayout>
    </div>
  );
}
