/** @format */

import * as actionTypes from 'stateContainer/action-types';

const initialState = { requesting: false, data: {} };

export const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * subscription-creation
     */
    case actionTypes.STORE_SUBSCRIPTION_REQUESTED:
      return { ...state, requesting: true };

    case actionTypes.STORE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        requesting: false,
        isUserSubscribed: true
      };

    case actionTypes.STORE_SUBSCRIPTION_FAILED:
      return {
        ...state,
        requesting: false,
        isUserSubscribed: false
      };

    /**
     * subscription-transistions or changes post subscription
     */

    case actionTypes.UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload }
      };

    /**
     * subscription-prices
     */

    case actionTypes.UPDATE_SUBSCRIPTION_PRICES_REQUESTED:
      return { ...state, requesting: true };

    case actionTypes.UPDATE_SUBSCRIPTION_PRICES_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        requesting: false
      };

    case actionTypes.UPDATE_SUBSCRIPTION_PRICES_FAILED:
      return { ...state, requesting: false };

    /**
     * cancel-subscription
     */

    case actionTypes.CANCEL_SUBSCRIPTION_REQUESTED:
      return { ...state, requesting: true };

    case actionTypes.CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        requesting: false,
        data: { ...state.data, ...action.payload }
      };

    case actionTypes.CANCEL_SUBSCRIPTION_FAILED:
      return { ...state, requesting: false };

    /**
     * subscription-data
     */

    case actionTypes.LIST_SUBSCRIPTION_DATA_REQUESTED:
      return { ...state, requesting: true };

    case actionTypes.LIST_SUBSCRIPTION_DATA_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        requesting: false
      };

    case actionTypes.LIST_SUBSCRIPTION_DATA_FAILED:
      return { ...state, requesting: false };

    /**
     * payment-data
     */

    case actionTypes.LIST_PAYMENT_DATA_REQUESTED:
      return { ...state, requesting: true };

    case actionTypes.LIST_PAYMENT_DATA_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        requesting: false
      };

    case actionTypes.LIST_PAYMENT_DATA_FAILED:
      return { ...state, requesting: false };

    default:
      return state;
  }
};
