/** @format */

import { useTranslation } from 'react-i18next';
import routeService from 'services/routeService';
import PuffLoader from 'react-spinners/PuffLoader';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBaseDataAction } from 'stateContainer/actions';
import DashboardLayout from 'components/Layout/DashboardLayout';
import NotificationMessage from 'components/NotificationMessage';
import { Link, useLocation, withRouter, Redirect } from 'react-router-dom';
import ActionRequiredMessage from 'views/Dashboard/MyAccount/ActionRequiredMessage';
import { getActionMessageSelector } from 'views/Onboarding/WelcomeToOnboarding/getActionMessageSelector';

export default withRouter(function WelcomeToOnboarding(props) {
  const { tprop } = props;
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();

  const [isMessageDismissed, setIsMessageDismissed] = useState();
  const [showFailedNotification, setShowFailedNotification] = useState(false);
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);

  const { t } = useTranslation();

  const heading = t(`lang_${props.flow}:${props.view}.heading.text`);
  const description = t(`lang_${props.flow}:${props.view}.description`);
  const appStoreUrl = t(`lang_${props.flow}:${props.view}.buttons`)[1].url;
  const playStoreUrl = t(`lang_${props.flow}:${props.view}.buttons`)[0].url;

  const userState = useSelector((state) => state.user);
  const appRoutingState = useSelector((state) => state.appRouting);
  const { ssn = '' } = userState.data || {};

  const showActionMessage =
    !userState.requesting && appRoutingState.showActionRequiredMessage;

  const hideNotification = () => {
    setTimeout(() => {
      setShowSuccessNotification(false);
    }, 5000);
  };

  /**
   * fetch basedata
   */

  useEffect(() => {
    dispatch(fetchBaseDataAction(ssn));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * hide profile update status notifiation
   */

  useEffect(() => {
    if (search.includes('profile_update=success')) {
      setShowSuccessNotification(true);
      props.history.push(pathname);
      hideNotification();
    } else if (search.includes('profile_update=failed')) {
      setShowFailedNotification(true);
      props.history.push(pathname);
      hideNotification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * select the appropriate action message
   */

  const selector = getActionMessageSelector(appRoutingState);

  return (
    <>
      {/* redirect unregistered users to register welcome page */}
      {!appRoutingState.isRegistrationCompleted && (
        <Redirect to={routeService.register_welcome} />
      )}
      <DashboardLayout
        {...props}
        hidePreviousButton={true}
        disabled={userState.requesting}>
        {showSuccessNotification && (
          <NotificationMessage
            heading={tprop.notification.success.heading.text}
            description={tprop.notification.success.description.text}
          />
        )}

        {showFailedNotification && (
          <NotificationMessage
            heading={tprop.notification.failure.heading.text}
            description={tprop.notification.failure.description.text}
          />
        )}

        <ActionRequiredMessage
          actionUrl={routeService.profile_set_power_of_attorney}
          showActionMessage={showActionMessage}
          setIsMessageDismissed={setIsMessageDismissed}
          isMessageDismissed={isMessageDismissed}
          selector={selector}
          {...props}
        />
        {userState.requesting ? (
          <div className='loading-indicator-container'>
            <PuffLoader size={96} color={'var(--color-pink)'} loading />
          </div>
        ) : (
          <div className='onboarding-content'>
            <h1>{heading}</h1>

            {description.map((item, key) => (
              <p key={key}>{item.text}</p>
            ))}

            <div className='mobile-app-icons-container'>
              <Link
                to={{ pathname: appStoreUrl }}
                target='_blank'
                rel='noopener noreferrer'>
                <div className='logo-appstore-black'></div>
              </Link>
              <Link
                to={{ pathname: playStoreUrl }}
                target='_blank'
                rel='noopener noreferrer'>
                <div className='logo-playstore-black'></div>
              </Link>
            </div>
          </div>
        )}
      </DashboardLayout>
    </>
  );
});
