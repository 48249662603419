/** @format */

import AWS from 'aws-sdk';
import config from 'config';
import { Auth } from 'aws-amplify';
import jwt_decode from 'jwt-decode';
import { store } from 'stateContainer/store';
import {
  logoutRequestAction,
  confirmEndOfSessionAction,
  sessionIsAboutToExpireMessageAction
} from 'stateContainer/actions';

let logoutTimer, sessionExpiryMessageTimer, forcedLogoutTimer;

const startSessionTimers = () => {
  sessionExpiryMessageTimer = setTimeout(() => {
    // dispatch action to show message after 40 mins before 10 mins
    store.dispatch(sessionIsAboutToExpireMessageAction());
    logoutTimer = setTimeout(() => {
      // logout user 10 mins after showing logout message
      store.dispatch(confirmEndOfSessionAction());
      forcedLogoutTimer = setTimeout(() => {
        // force logout the user if they dont click on logout confirmation modal
        store.dispatch(logoutRequestAction());
      }, 60000 * 2); // 2 minutes
    }, 60000 * 10); // 10 minutes
  }, 60000 * 35); // 35 minutes
};

async function getFederatedId({ token, domain }) {
  const params = {
    AccountId: process.env.REACT_APP_AWS_ACCOUNT_ID,
    IdentityPoolId:
      process.env.REACT_APP_AWS_CONFIG_COGNITO_IDENTITY_POOL_ID /* required */,
    Logins: {}
  };
  params.Logins[domain] = token;

  const cognitoidentity = new AWS.CognitoIdentity();

  return cognitoidentity
    .getId(params)
    .promise()
    .then((response) => {
      return response.IdentityId;
    })
    .catch((error) => {
      return error;
    });
}

/** login-service */
export async function loginService({ token }) {
  const decodedToken = jwt_decode(token);
  const {
    exp,
    name,
    ssn,
    country,
    surname,
    givenname,
    nameidentifier
  } = decodedToken;

  const domain = config.app.criipto.settings.authority;
  const user = { ssn, name, surname, country, givenname, nameidentifier };

  return await Auth.federatedSignIn(
    domain,
    {
      token,
      expires_at: exp
    },
    user
  )
    .then((credentials) => {
      AWS.config.update({
        ...credentials,
        region: process.env.REACT_APP_AWS_CONFIG_COGNITO_REGION
      });

      return Auth.currentAuthenticatedUser();
    })
    .then((user) => {
      const data = { ...user };
      return getFederatedId({ token, domain })
        .then((identityId) => {
          startSessionTimers();

          data.fid = identityId;
          return data;
        })
        .catch((error) => {
          return error;
        });
    })

    .catch((error) => {
      throw error;
    });
}

/** logout-service */
export async function logoutService() {
  // clear all timers
  clearTimeout(sessionExpiryMessageTimer);
  clearTimeout(logoutTimer);
  clearTimeout(forcedLogoutTimer);
  return await Auth.signOut();
}

export default {
  loginService,
  logoutService
};
