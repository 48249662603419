/** @format */

import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import routeService from 'services/routeService';
import React, { useState, useEffect } from 'react';
import FormField from 'components/Forms/FormField';
import ButtonLoader from 'components/ButtonLoader';
import { useSelector, useDispatch } from 'react-redux';
import { updateEmailAction } from 'stateContainer/actions';
import DashboardLayout from 'components/Layout/DashboardLayout';
import RowItemBlock from 'views/Dashboard/MyAccount/RowItemBlock';
import LineItemBlock from 'views/Dashboard/MyAccount/LineItemBlock';
import ValidateEmailModal from 'components/Modals/ValidateEmailModal';

const emailValidationSchema = (t) =>
  Yup.object({
    newEmail: Yup.string().email(t(`lang_error:forms.formatInvalid`))
  });

export default function Email(props) {
  const { tprop } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [getNewEmail, setNewEmail] = useState('');
  const [getShowModal, setShowModal] = useState(false);
  const userState = useSelector((state) => state.user) || {};
  const { ssn = '', email = '', firstName = '' } = userState.data;

  const initialValues = {
    newEmail: ''
  };

  const onSubmit = (values) => {
    setNewEmail(values.newEmail);
    if (
      // values.newEmail !== email &&  // allow change from & to same email until TP-694 has been done
      values.newEmail !== initialValues.newEmail
    ) {
      dispatch(
        updateEmailAction({ ssn, email, firstName, newEmail: values.newEmail })
      );
      setShowModal(true);
    }
  };

  useEffect(() => {
    search.includes('validation_failed') && setShowModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DashboardLayout
        {...props}
        hidePreviousButton={false}
        goBackUrl={routeService.profile_my_account}>
        <div className='profile profile-email-view'>
          <div className='profile-container'>
            <div className='profile-heading'>
              <h1>{tprop.heading.text}</h1>
            </div>

            <RowItemBlock heading={tprop.inputs.currentEmail.label}>
              <LineItemBlock text={email} />
            </RowItemBlock>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={emailValidationSchema(t)}>
              {({ isValid, dirty }) => (
                <Form>
                  <RowItemBlock heading={tprop.inputs.newEmail.label}>
                    <LineItemBlock>
                      <FormField name='newEmail' />
                    </LineItemBlock>
                  </RowItemBlock>

                  <div className='edit-email-button-container'>
                    {userState.requesting ? (
                      <ButtonLoader />
                    ) : (
                      <Button
                        className={`${props.btnColor}`}
                        type='submit'
                        disabled={!isValid || !dirty}>
                        {tprop.buttons.text}
                      </Button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <ValidateEmailModal
          getShowModal={getShowModal && !userState.requesting}
          setShowModal={setShowModal}
          newEmail={getNewEmail}
          {...props}
        />
      </DashboardLayout>
    </>
  );
}
