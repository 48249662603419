/** @format */

import { routeService } from 'services';
import React, { useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import ValidatingEmailStatus from 'components/ValidatingEmailStatus';

export default withRouter(function ValidateEmailSuccess(props) {
  const { search } = useLocation();

  useEffect(() => {
    const that = props;
    const destinationUrl = `${routeService.register_set_power_of_attorney}${search}`;
    setTimeout(() => {
      that.history.push(destinationUrl);
    }, 2000); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ValidatingEmailStatus isEmailVerified={true} flow={props.flow} />;
});
