/** @format */

import { makeGraphQlRequest } from 'services/graphqlService/makeGraphQlRequest';

export const fetchBaseDataQuery = ({ ssn }) => {
  return makeGraphQlRequest(`
    {
      User(ssn:"${ssn}") {
        fid
        ssn
        firstName
        lastName
        sex
        birthDate
        phone
        email
        salary
        poa{
         isPoaValid: isValid,
         poaSignedDate: signedDate
        }
        address {
          city
          address1
          address2
          postCode
        }
        validationCode{
          isEmailVerified:isVerified
        }
        retailBilling{
          subscription{
              subscriptionId
              currentPeriodTo
              currentPeriodFrom
          }
          stripeCustomerId
        }
        statePension{
          type
        }
        family {
          age
          type
          createdAt
          _id
        }
        employedBy: company {
          name
          organisationId
        }
      }
      DefaultCompany: Company(default:true) {
        name
        organisationId
      }
      Employee (filter:{identity:"${ssn}"}) {
        identity
        companies {
          name
          contact
          contactEmail
          contactPhone
          organisationId
          isDefaultCompany: default
        }
      }
      POAText(isValid:true) {
        text
      }
      RetailPrice (active:"true") {
        isPriceActive: active
        price
        priceId
        currency
      }
      TaxRate{
        taxRateId
        taxPercentage: percentage
      }
      StripeLocale: Country(alpha2: "SE" ){
        stripeLocale
      }
    }
    `);
};
