/** @format */

import React from 'react';
import config from 'config';
import { routeService } from 'services';
import ToggleCheckBox from 'components/ToggleCheckBox';

export default function ToggleCheckBoxAndDisclaimer(props) {
  const { t, setTermsIsAgreed, isTermsAgreed, flowOrigin } = props;

  const termsUrl =
    flowOrigin === config.constants.flowOrigins.profile
      ? routeService.profile_terms_and_conditions
      : routeService.register_terms_and_conditions;
  const privacyPolicyUrl =
    flowOrigin === config.constants.flowOrigins.profile
      ? routeService.profile_privacy_policy
      : routeService.register_privacy_policy;

  const setTermsAndPolicyText = (
    <p
      className=''
      dangerouslySetInnerHTML={{
        __html: t(`lang_registration:agree_to_terms.disclaimer.text`, {
          openLink1: `<a href=${termsUrl}>`,
          closeLink1: '</a>',
          openLink2: `<a href=${privacyPolicyUrl}>`,
          closeLink2: '</a>'
        })
      }}
    />
  );

  return (
    <>
      <div className='agree-terms-toggle-container'>
        <div>
          <div className='agree-terms-toggle-button-container'>
            <ToggleCheckBox
              name='agreement'
              value='isTermsAgreed'
              isTermsAgreed={isTermsAgreed}
              onChange={(e) => setTermsIsAgreed(e.target.checked)}
            />
          </div>
        </div>
        <div className='agree-terms-toggle-text-container'>
          {setTermsAndPolicyText}
        </div>
      </div>
    </>
  );
}
