/** @format */

import config from 'config';
import { invokeRestApi } from 'utils/invokeRestApi';

const {
  services: { FETCH_ROARING_DATA_SERVICE_PATHNAME }
} = config.app;

export async function fetchRoaringDataService({ ssn }) {
  return await invokeRestApi({
    service: FETCH_ROARING_DATA_SERVICE_PATHNAME,
    payload: { ssn }
  });
}

export default {
  fetchRoaringDataService
};
