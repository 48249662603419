/** @format */

import { listPaymentService } from 'services';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  try {
    /**
     * fetch payment-data from Stripe
     */

    const response = yield call(listPaymentService, {
      customerId: payload.customerId
    });

    /**
     * update subscription state with info fetched from Stripe
     */

    yield put({
      type: actionTypes.LIST_PAYMENT_DATA_SUCCESS,
      payload: {
        payments: response.payments && response.payments.data
      }
    });

    /**
     * update appRouting state with registration progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { fetchPaymentData: 'done' }
    });
  } catch (error) {
    /**
     * throw error when the api call fails
     */

    yield put({ type: actionTypes.LIST_PAYMENT_DATA_FAILED, error });
  }
}

export function* listPaymentSaga() {
  yield takeLatest(actionTypes.LIST_PAYMENT_DATA_REQUESTED, workerSaga);
}
