/** @format */

export default {
  /**
   * application flows
   */
  flows: {
    registration: 'registration',
    onboarding: 'onboarding',
    common: 'common',
    dashboard: 'dashboard'
  },
  /**
   * flowOrigins to identify the source of the flow
   */
  flowOrigins: {
    registration: 'registration',
    profile: 'profile'
  },
  /**
   * views represent every single page in the appliation excluding modals
   */
  views: {
    start: 'start',
    login_mode: 'login_mode',
    login: 'login',
    bankid_redirect: 'bankid_redirect',
    logging_in: 'logging_in',
    logging_out: 'logging_out',
    welcome: 'welcome',
    personal_info: 'personal_info',
    validate_email: 'validate_email',
    validating_email: 'validating_email',
    questions: 'questions',
    set_validity_for_power_of_attorney: 'set_validity_for_power_of_attorney',
    faq_power_of_attorney: 'faq_power_of_attorney',
    agree_to_terms: 'agree_to_terms',
    terms_and_conditions: 'terms_and_conditions',
    privacy_policy: 'privacy_policy',
    stripe_payment: 'stripe_payment',
    approve_power_of_attorney: 'approve_power_of_attorney',
    sign_power_of_attorney: 'sign_power_of_attorney',
    creating_power_of_attorney: 'creating_power_of_attorney',
    add_state_pension: 'add_state_pension',
    registration_complete: 'registration_complete',
    page_not_found: 'page_not_found',
    welcome_to_onboarding: 'welcome_to_onboarding',
    my_account: 'my_account',
    subscription: 'subscription',
    email: 'email',
    how_we_choose_funds: 'how_we_choose_funds',
    cookies: 'cookies',
    sustainability_policy: 'sustainability_policy',
    how_we_calculate: 'how_we_calculate'
  },
  colors: {
    // this object would represent the classNames of colors instead of the actual color
    blue: 'btn-blue',
    pink: 'btn-pink'
  }
};
