/** @format */

import * as actionTypes from 'stateContainer/action-types';

const initialState = {
  requesting: false,
  data: {}
};

export const powerOfAttorneyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_VALIDITY_FOR_POWER_OF_ATTORNEY:
      return { ...state, data: action.payload };

    case actionTypes.SET_IS_TERMS_AGREED_REQUESTED:
      return { ...state, isTermsAgreed: action.payload };

    case actionTypes.STORE_POWER_OF_ATTORNEY_SUCCESS:
      return { ...state, isPoaViewed: true };

    /** fetch poa text from fullmaktskollen*/
    case actionTypes.UPDATE_POATEXT_REQUESTED:
      return { ...state, requesting: true };

    case actionTypes.UPDATE_POATEXT_SUCCESS:
      return { ...state, requesting: false, poaText: action.payload };

    case actionTypes.UPDATE_POATEXT_FAILED:
      return { ...state, requesting: false };

    /** fetch and update poa signature status for already subscribed users */
    case actionTypes.UPDATE_POWER_OF_ATTORNEY:
      return {
        ...state,
        isPoaViewed: action.payload,
        isPoaValid: action.isPoaValid
      };

    default:
      return state;
  }
};
