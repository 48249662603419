/** @format */

import React from 'react';
import ActivityHistoryItems from 'views/Dashboard/Subscription/ActivityHistory/ActivityHistoryItems';

export default function ActivityHistory(props) {
  const { t, flow, view, payments, selector, subscriptions } = props;

  const subscriptionActivity = t(
    `lang_${flow}:${view}.information.${selector}.activity.heading.text`
  );

  return (
    <div className='list-container'>
      <div className='list-container list-container-heading'>
        <h1>{subscriptionActivity}</h1>
      </div>
      <div className='list-item-container'>
        {selector === 'active' && (
          <ActivityHistoryItems
            t={t}
            activityList={payments}
            itemLink={true}
            {...props}
          />
        )}

        {selector === 'inactive' && (
          <ActivityHistoryItems
            t={t}
            activityList={subscriptions}
            itemLink={false}
            {...props}
          />
        )}
      </div>
    </div>
  );
}
