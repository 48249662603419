/**
 * Add url to listitem
 *
 * @format
 * @param {Object} item
 * @param {Number} key
 * @returns html url tag generated using text and url obtained from `item` parameter
 */

export const getItemLinkified = ({ t, item, key }) => {
  return {
    __html: t(`lang_registration:add_state_pension.instructions.steps`, {
      openLink1: `<a target='_blank' key=${key} rel='noopener noreferrer'href=${item.props.url}>`,
      closeLink1: '</a>'
    })[key].text
  };
};
