/** @format */

import { emailService } from 'services';
import { push } from 'connected-react-router';
import { put, takeLatest, call } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  try {
    const response = yield call(emailService, {
      ...payload,
      template: 'verificationCodeTemplate'
    });

    /**
     * update email verification state
     */

    yield put({
      type: actionTypes.SEND_VERIFICATION_CODE_SUCCESS,
      data: response.data
    });

    /**
     * update appRouting state with registration progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { sendEmailVerificationCode: 'done' }
    });

    yield put(push(payload.successUrl));
  } catch (error) {
    yield put({ type: actionTypes.SEND_VERIFICATION_CODE_FAILED, error });
  }
}

export function* sendVerificationCodeSaga() {
  yield takeLatest(actionTypes.SEND_VERIFICATION_CODE_REQUESTED, workerSaga);
}
