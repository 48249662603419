/** @format */

import { getNumericValue } from 'utils/geNumericValue';

/**
 * Filter out non numeric values and update the state with the numeric values typed in the input
 *
 * @format
 * @param {Object} e
 * @returns updates one of `generalPensionValue` or  `ppmPensionValue` states if the value is and integer based on the input name
 */

export const handleNumericValue = ({
  e,
  setPpmPensionValue,
  setGeneralPensionValue
}) => {
  const value = getNumericValue(e.target.value);

  if (e.target.name === 'generalPension') {
    setGeneralPensionValue(value);
  } else {
    setPpmPensionValue(value);
  }
};
