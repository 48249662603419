/** @format */

import { mutations } from 'services';
import { put, call, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  try {
    /**
     * Delete adults and child members from family
     */

    for (let i = 0; i < payload.items.length; i++) {
      yield call(mutations.deleteFamilyMemberMutation, {
        ssn: payload.ssn,
        _id: payload.items[i]
      });
    }

    /**
     * update family object in user state with new family members
     */

    yield put({ type: actionTypes.DELETE_USER_FAMILY_SUCCESS });

    /**
     * update appRouting state with registration progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { deletedFamilyMembers: 'done' }
    });
  } catch (error) {
    yield put({ type: actionTypes.DELETE_USER_FAMILY_FAILED, error });
  }
}

export function* deleteFamilyMemberSaga() {
  yield takeLatest(actionTypes.DELETE_USER_FAMILY_REQUESTED, workerSaga);
}
