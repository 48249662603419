/** @format */

import * as actionTypes from 'stateContainer/action-types';

const initialState = {
  requesting: false,
  data: {}
};

export const appRoutingReducer = (state = initialState, action) => {
  switch (action.type) {
    /** check if poa has already been signed for a user who attempts to re-register*/
    case actionTypes.__APP_ROUTING_DATA_REQUESTED:
      return { ...state, requesting: true };

    case actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED:
      return {
        ...state,
        data: { ...state.data, ...action.payload }
      };

    case actionTypes.__APP_ROUTING_DATA_SUCCESS:
      return {
        ...state,
        requesting: false,
        ...action.payload
      };

    case actionTypes.__APP_ROUTING_DATA_FAILED:
      return {
        ...state,
        requesting: false,
        data: { ...state.data, ...action.payload, error: true }
      };

    /** additional state updates  */

    case actionTypes.SET_IS_TERMS_AGREED_REQUESTED:
      return {
        ...state,
        data: {
          ...state.data,
          agreeToTerms: action.payload === true && 'done',
          isTermsAgreed: action.payload
        }
      };

    default:
      return state;
  }
};
