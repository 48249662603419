/** @format */

import React from 'react';

export default function AdultsNumberBox(props) {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='102'
        height='66'
        viewBox='0 0 102 66'>
        <defs>
          <clipPath id='clipPath'>
            <rect width='26' height='26' fill='none' />
          </clipPath>
        </defs>
        <g id='Group_765' data-name='Group 765' transform='translate(-62 -266)'>
          <rect
            id='Rectangle_1494'
            data-name='Rectangle 1494'
            width='102'
            height='66'
            rx='20'
            transform='translate(62 266)'
            fill='#fac7c6'
          />
          <g id='Group_763' data-name='Group 763' transform='translate(7)'>
            <g
              id='Profile'
              transform='translate(78 292)'
              clipPath='url(#clipPath)'>
              <path
                id='Union_1'
                data-name='Union 1'
                d='M0,26V22.75c0-3.576,5.85-6.5,13-6.5s13,2.925,13,6.5V26ZM6.5,6.5A6.5,6.5,0,1,1,13,13,6.5,6.5,0,0,1,6.5,6.5Z'
                fill='#0c0c0c'
              />
            </g>
            <text
              id='_1'
              data-name='1'
              transform='translate(124 313)'
              fill='#0c0c0c'
              fontSize='20'>
              <tspan x='0' y='0'>
                {props.value}
              </tspan>
            </text>
          </g>
          <text
            id={props.labelText}
            transform='translate(96 284)'
            fill='#0c0c0c'
            fontSize='12'>
            <tspan x='0' y='0'>
              {props.labelText}
            </tspan>
          </text>
        </g>
      </svg>
    </>
  );
}
