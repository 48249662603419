/** @format */

import React from 'react';

export default function ToggleCheckBox(props) {
  const { name, onChange, isTermsAgreed, value } = props;
  return (
    <label className='switch'>
      <input type='checkbox' name={name} onChange={onChange} value={value} />
      <span
        className={`slider round ${
          isTermsAgreed && 'slider-border-pink'
        }`}></span>
    </label>
  );
}
