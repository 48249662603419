/** @format */

import React from 'react';
import LinkifyHelper from 'utils/linkifyHelper';

export default function TermsAndConditions(props) {
  const { heading, description } = props;
  return (
    <>
      <div className='terms-and-conditions-container'>
        <div className='heading-container'>
          {heading.map((item, key) => (
            <h5 key={key}>{item.text}</h5>
          ))}
        </div>

        {(Array.isArray(description) &&
          description.map((listItem, ref) => (
            <p className='description' key={ref}>
              {listItem.text}
            </p>
          ))) || (
          <LinkifyHelper>
            <p className='description'>{description}</p>
          </LinkifyHelper>
        )}
      </div>
    </>
  );
}
