/** @format */

import { getUserStatus } from 'utils/getUserStatus';
import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  try {
    const userData = payload.User && payload.User[0];
    const {
      isRetailUser,
      hasValidEmployer,
      isCurrentlyEmployed,
      hasMultipleEmployers
    } = payload;

    let appRoutingData = {
      isRetailUser,
      hasValidEmployer,
      isCurrentlyEmployed,
      hasMultipleEmployers
    };

    /**
     * take actions if the user had already completed registration or performed part of it
     */

    if (userData) {
      Object.assign(userData, {
        ...userData.address,
        ...userData.validationCode
      });

      const {
        isPoaValid,
        isPoaViewed,
        isEmailVerified,
        isUserSubscribed,
        isStatePensionAdded,
        isRegistrationCompleted,
        isSubscriptionCancelled,
        showActionRequiredMessage
      } = getUserStatus({ user: userData, caller: 'app-routing-saga' });

      /**
       * update `user` state with userdata from the database
       */

      yield put({
        type: actionTypes.UPDATE_USER_DATA,
        payload: userData
      });

      /**
       * update `verification` state with email verification status
       */

      yield put({
        type: actionTypes.UPDATE_VERIFICATION_STATUS,
        payload: isEmailVerified
      });

      /**
       * update `subscription` state with subscription data from the database
       */

      const subscription =
        (userData.retailBilling && {
          ...userData.retailBilling.subscription
        }) ||
        {};

      yield put({
        type: actionTypes.UPDATE_SUBSCRIPTION_SUCCESS,
        payload: subscription
      });

      /**
       * update power-of-attorney state with poaviewed state
       */

      yield put({
        type: actionTypes.UPDATE_POWER_OF_ATTORNEY,
        payload: isPoaViewed,
        isPoaValid
      });

      /**
       * update state-pension state in redux store
       */

      yield put({
        type: actionTypes.UPDATE_STATE_PENSION,
        payload: userData.statePension
      });

      /**
       * check for registration completion status
       */

      appRoutingData = {
        ...appRoutingData,
        isPoaValid,
        isPoaViewed,
        isEmailVerified,
        isUserSubscribed,
        isStatePensionAdded,
        isRegistrationCompleted,
        isSubscriptionCancelled,
        showActionRequiredMessage
      };
    }

    /**
     * update appRouting state with critical registration checkpoints
     */

    yield put({
      type: actionTypes.__APP_ROUTING_DATA_SUCCESS,
      payload: appRoutingData
    });

    /**
     * update appRouting state with registration progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { checkRegistrationStatus: 'done' }
    });
  } catch (error) {
    /**
     * error must never happen. if it does. there is something wrong in the try block
     */

    yield put({
      type: actionTypes.__APP_ROUTING_DATA_FAILED,
      payload: error
    });
  }
}

export function* appRoutingStateSaga() {
  yield takeLatest(actionTypes.__APP_ROUTING_DATA_REQUESTED, workerSaga);
}
