/** @format */

import * as Yup from 'yup';

export const validationSchema = (t) =>
  Yup.object({
    firstName: Yup.string().required(t(`lang_error:forms.required`)),
    lastName: Yup.string().required(t(`lang_error:forms.required`)),
    address: Yup.string().required(t(`lang_error:forms.required`)),
    city: Yup.string().required(t(`lang_error:forms.required`)),
    postCode: Yup.string().required(t(`lang_error:forms.required`)),
    email: Yup.string()
      .email(t(`lang_error:forms.formatInvalid`))
      .required(t(`lang_error:forms.required`)),
    phone: Yup.number().optional(),
    salary: Yup.number().required(t(`lang_error:forms.required`))
  });
