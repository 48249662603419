/** @format */

import React from 'react';

export default function PersonDataBlock(props) {
  const { itemName, item } = props;
  const text = itemName === 'profile' ? <h1>{item}</h1> : <p>{item}</p>;
  return (
    <div className='personal-detail-block'>
      <div className='personal-details-image'>
        <div className={`icon-${itemName}-square`}></div>
      </div>
      <div className='personal-details-text'>{text}</div>
    </div>
  );
}
