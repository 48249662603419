/** @format */
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

export default withRouter(function LogoutConfirmationModal(props) {
  useEffect(() => {
    const that = props;
    setTimeout(() => {
      that.history.push(that.destinationUrl);
    }, 3000); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal
        className='message-modal payment-success-modal'
        show={props.showModal}>
        <Modal.Body>
          <div className='image-success'></div>
          <p>{props.message}</p>
        </Modal.Body>
      </Modal>
    </>
  );
});
