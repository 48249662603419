/** @format */
/**
 * Registration flow template with Left-Right split screens
 * where the left stays the same throughout the flow
 */

import React from 'react';
import Col from 'react-bootstrap/Col';
import Header from 'components/Layout/Header';
import PrevButton from 'components/PrevButton';
import StepsWizard from 'components/StepsWizard';
import LogoutButton from 'components/LogoutButton';

function SplitLayout(props) {
  const {
    flow,
    leftContent,
    stepsWizard,
    backgroundImage,
    hideLogoutButton,
    hideMobileHeader,
    hidePreviousButton
  } = props;

  let leftBackgroundImage;

  if (backgroundImage) {
    leftBackgroundImage = backgroundImage;
  } else if (!flow) {
    leftBackgroundImage = `registration-user-left-background-image`;
  } else {
    leftBackgroundImage = `${flow}-user-left-background-image`;
  }

  const hasTopPadding =
    props.className &&
    (props.className.includes('prelogin-template') ||
      props.className.includes('sign-poa-container') ||
      props.className.includes('validate-email-status-container'));

  const paddingClass = hasTopPadding
    ? 'split-layout-right-add-top-padding'
    : '';
  return (
    <>
      {!hideMobileHeader && (
        <Header {...props} hidePreviousButton={hidePreviousButton} />
      )}
      <div className='main-container align-items-center justify-content-center'>
        <div className='split-layout-container'>
          {!hidePreviousButton && <PrevButton flow={flow} />}
          <Col
            className={`col-left split-layout split-layout-left ${leftBackgroundImage}`}>
            <div className='split-layout-left-box-container text-center '>
              <div className='split-layout-left-box-content'>{leftContent}</div>
            </div>
          </Col>

          <Col className={`col-right split-layout-right ${paddingClass}`}>
            {!hideLogoutButton && <LogoutButton flow={flow} />}

            {stepsWizard && <StepsWizard stepsWizard={stepsWizard} />}
            <div
              className={`text-center split-layout-right-box  ${
                props.className || ''
              }`}>
              <div className='split-layout-right-inner-box-container'>
                {props.children}
              </div>
            </div>
          </Col>
        </div>
      </div>
    </>
  );
}

export default SplitLayout;
