/** @format */

import * as actionTypes from 'stateContainer/action-types';

const initialState = { requesting: false, data: {} };

export const statePensionReducer = (state = initialState, action) => {
  switch (action.type) {
    /** Add State Pension **/
    case actionTypes.ADD_STATE_PENSION_REQUESTED:
      return { ...state, requesting: true };

    case actionTypes.ADD_STATE_PENSION_SUCCESS:
      return { ...state, requesting: false };

    case actionTypes.ADD_STATE_PENSION_FAILED:
      return { ...state, requesting: false };

    case actionTypes.UPDATE_STATE_PENSION:
      return {
        ...state,
        data: { ...state.data, ...action.payload }
      };

    case actionTypes.ADD_POA_SPECIAL_COMPANY_REQUESTED:
      return { ...state, requesting: true };

    case actionTypes.ADD_POA_SPECIAL_COMPANY_SUCCESS:
      return { ...state, requesting: false };

    case actionTypes.ADD_POA_SPECIAL_COMPANY_FAILED:
      return { ...state, requesting: false };

    default:
      return state;
  }
};
