/** @format */
import moment from 'moment';
import { encode } from 'js-base64';
import { store } from 'stateContainer/store';

export const getEncodedSignText = (t) => {
  const {
    user: {
      data: { ssn, firstName, lastName }
    },
    powerOfAttorney: {
      data: { validity, expiryDate }
    }
  } = store.getState();

  const formattedExpiryDate = moment(expiryDate).format('YYYY-MM-DD');

  const validityText =
    validity === 'ongoing'
      ? t(`lang_registration:sign_power_of_attorney.validity.ongoing.text`)
      : t(`lang_registration:sign_power_of_attorney.validity.limited.text`, {
          expiryDate: formattedExpiryDate
        });

  return encode(
    t(`lang_registration:sign_power_of_attorney.signtext`, {
      ssn: ssn,
      name: `${firstName} ${lastName}`,
      validity: validityText
    })
  );
};
