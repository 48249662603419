/** @format */

import React from 'react';
import Header from 'components/Layout/Header';
import Footer from 'components/Layout/Footer';
import PrevButton from 'components/PrevButton';

export default function DashboardLayout(props) {
  const { children, goBackUrl, disabled, hidePreviousButton } = props;
  return (
    <>
      <Header
        flow={props.flow}
        goBackUrl={goBackUrl}
        disabled={disabled}
        hidePreviousButton={hidePreviousButton}
      />

      <div className='dashboard-view'>
        <div className='dashboard-container'>
          {/* Regular previous button is independant of the header */}
          {!hidePreviousButton && (
            <PrevButton
              className='go-previous-button-dashboard'
              flow={props.flow}
              goBackUrl={goBackUrl}
            />
          )}
          <div className='dashboard-content-wrapper'>{children}</div>
        </div>
        <Footer {...props} />
      </div>
    </>
  );
}
