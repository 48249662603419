/** @format */

import React from 'react';
import config from 'config';
import { routeService } from 'services';
import Table from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import PuffLoader from 'react-spinners/PuffLoader';
import { Elements } from '@stripe/react-stripe-js';
import SplitLayout from 'components/Layout/SplitLayout';
import DashboardLayout from 'components/Layout/DashboardLayout';
import NotificationMessage from 'components/NotificationMessage';
import CheckoutForm from 'views/Common/StripePayment/CheckoutForm';
import PersonDataBlock from 'components/AgreeTermsAndPoa/PersonDataBlock';

const PUBLISHABLE_KEY = config.app.stripePublishableKey;

const stripe = loadStripe(PUBLISHABLE_KEY);

export default function FaqPowerOfAttorney(props) {
  const { tprop } = props;
  const { t } = useTranslation();

  const { email, lastName, firstName } = useSelector(
    (state) => state.user.data
  );

  const subscriptionState = useSelector((state) => state.subscription);
  const subscriptionData = subscriptionState.data;
  const appRoutingState = useSelector((state) => state.appRouting);
  const powerOfAttorneyState = useSelector((state) => state.powerOfAttorney);

  const name = `${firstName} ${lastName}`;
  const price = subscriptionData.price
    ? parseInt(subscriptionData.price) / 100
    : '';
  const currency = subscriptionData.currency
    ? subscriptionData.currency.toUpperCase()
    : '';
  const priceWithCurrency = `${price} ${currency}`;

  const { priceId = null, taxRateId = null, stripeLocale = '' } =
    subscriptionData || {};

  const calculatedTax = price * 0.2;
  const calculatedTaxWithCurrency = `${calculatedTax} ${currency}`;

  const isPriceDataMissing =
    !subscriptionState.requesting &&
    (!subscriptionData || Object.keys(subscriptionData).length === 0);
  const isPriceDataReady = !isPriceDataMissing && !subscriptionState.requesting;

  const alertHeading = t(`lang_error:missingPrices.heading.heading.text`);
  const alertDescription = t(
    `lang_error:missingPrices.heading.description.text`
  );

  const PaymentContent = () => {
    return (
      <div className='content'>
        <PersonDataBlock itemName='profile' item={name} />
        <PersonDataBlock itemName='email' item={`${email}`} />

        {isPriceDataReady ? (
          <div>
            <div className='payment-headings bordered-bottom'>
              <h2 className='subheading-text'>{tprop.heading.text}</h2>
              <p className='total-fees-large'>
                {`${price} ${currency} /${tprop.currency.text}`}
              </p>
              <p className='subscription-description'>
                {tprop.description.text}
              </p>
            </div>

            <div className='payment-subheading-text-small-screen'>
              <h3 className='payment-subheading-text'>
                {tprop.paymentSummary.heading.text}
              </h3>
            </div>
            <Table className='stripe-payment-table table-borderless'>
              <thead>
                <tr>
                  <th className='payment-details'>
                    <h3 className='payment-subheading-text'>
                      {tprop.paymentSummary.heading.text}
                    </h3>
                    <div className='icon-invoice payment-invoice-logo'></div>
                  </th>
                  <th>{tprop.paymentSummary.company.text}</th>
                  <th>{priceWithCurrency} </th>
                </tr>
              </thead>
              <tbody>
                <tr className='bordered-bottom'>
                  <td></td>
                  <td>{tprop.paymentSummary.subTotal.text}</td>
                  <td>{priceWithCurrency}</td>
                </tr>
                <tr className='color-grey bordered-bottom'>
                  <td></td>
                  <td>{tprop.paymentSummary.tax.text}</td>
                  <td>{calculatedTaxWithCurrency}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>{tprop.paymentSummary.total.text}</td>
                  <td>{priceWithCurrency}</td>
                </tr>
              </tbody>
            </Table>
            <Elements stripe={stripe}>
              <p className='card-container-heading'>{tprop.cardNumber.text}</p>
              <CheckoutForm
                email={email}
                {...props}
                priceId={priceId}
                taxRateId={taxRateId}
                stripeLocale={stripeLocale}
              />
            </Elements>
          </div>
        ) : (
          <div className='loading-indicator-container'>
            <PuffLoader size={96} color={'var(--color-black)'} loading />
          </div>
        )}
      </div>
    );
  };

  const poaDestinationUrl =
    props.flowOrigin === config.constants.flowOrigins.profile
      ? routeService.profile_approve_power_of_attorney
      : routeService.register_approve_power_of_attorney;

  const agreeTermsDestinationUrl =
    props.flowOrigin === config.constants.flowOrigins.profile
      ? routeService.profile_approve_power_of_attorney
      : routeService.register_approve_power_of_attorney;

  return (
    <div className='stripe-payment-view'>
      {isPriceDataMissing && (
        <NotificationMessage
          heading={alertHeading}
          description={alertDescription}
        />
      )}

      {!email && !appRoutingState.isEmailVerified && (
        <Redirect to={routeService.register_welcome} />
      )}

      {!powerOfAttorneyState.isTermsAgreed && (
        <Redirect to={routeService.register_agree_to_terms} />
      )}

      {appRoutingState.hasValidEmployer && (
        <Redirect to={agreeTermsDestinationUrl} />
      )}

      {appRoutingState.isUserSubscribed && <Redirect to={poaDestinationUrl} />}
      {props.flowOrigin === config.constants.flowOrigins.profile ? (
        <DashboardLayout
          {...props}
          hidePreviousButton={false}
          goBackUrl={routeService.profile_agree_to_terms}>
          <div className='profile-resubscription-flow-container stripe-payment-content'>
            <PaymentContent />
          </div>
        </DashboardLayout>
      ) : (
        <div id='registration-flow'>
          <SplitLayout
            flow={props.flow}
            stepsWizard={props.stepsWizard}
            className='postlogin-template stripe-payment-content'
            backgroundImage='registration-welcome-left-background-image'>
            <PaymentContent />
          </SplitLayout>
        </div>
      )}
    </div>
  );
}
