/** @format */

import * as Yup from 'yup';
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import Radio from 'components/Forms/Radio';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import { setChosenEmployerAction } from 'stateContainer/actions';

const validationSchema = (t) =>
  Yup.object({
    organisationId: Yup.string().required(t(`lang_error:forms.required`))
  });

export default function CompanyChoiceModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(true);

  const toggleCloseModal = () => {
    setShowModal(false);
  };

  const headingText = t(
    `lang_${props.flow}:${props.view}.companySelectionModal.${props.textSelector}.heading.text`
  );

  const descriptionText = t(
    `lang_${props.flow}:${props.view}.companySelectionModal.${props.textSelector}.description`
  );

  const buttonText = t(
    `lang_${props.flow}:${props.view}.companySelectionModal.buttons.text`
  );

  const initialValues = {
    organisationId:
      (props.employersList &&
        props.employersList[0] &&
        props.employersList[0].organisationId) ||
      ''
  };

  const onSubmit = ({ organisationId }) => {
    dispatch(setChosenEmployerAction({ organisationId }));
  };

  return (
    <>
      <div id={props.id}>
        <Modal
          className='message-modal company-choice-modal-container'
          show={showModal}
          onHide={toggleCloseModal}>
          <Modal.Header>
            <Modal.Title className='message-modal-header'>
              {headingText}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {descriptionText.map((item, key) => (
              <p key={key}>{item.text}</p>
            ))}
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => onSubmit(values)}
              validationSchema={validationSchema(t)}>
              {() => (
                <Form className=''>
                  <div className='content'>
                    <div className='company-choice-modal-content'>
                      {props.employersList.map((item, key) => (
                        <span key={key}>
                          <Radio
                            type='radio'
                            name='organisationId'
                            value={item.organisationId}
                            labeltext={item.name}
                            labelstyle='radio-input'
                          />
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className='company-choice-modal-submit-button'>
                    <Button className={`${props.btnColor}`} type='submit'>
                      {buttonText}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
