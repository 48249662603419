/** @format */

import { makeGraphQlRequest } from 'services/graphqlService/makeGraphQlRequest';

export const updateUserPoaMutation = (data) => {
  return makeGraphQlRequest(`mutation {
      UpdatePOA(
        poaId: "${data.poaId}",
        isValid: ${data.isValid},
        signedDate: "${data.signedDate}"
      ) {
       _id
      }
    }`);
};
