/** @format */

import { routeService } from 'services';
import React, { useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import ValidatingEmailStatus from 'components/ValidatingEmailStatus';

export default withRouter(function ValidateUpdateEmailSuccess(props) {
  const { search } = useLocation();

  useEffect(() => {
    const that = props;
    const destinationUrl = `${routeService.profile_my_account}${search}`;
    setTimeout(() => {
      that.history.push(destinationUrl);
    }, 2000); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='validate-update-email-success'>
      <ValidatingEmailStatus isEmailVerified={true} flow={props.flow} />
    </div>
  );
});
