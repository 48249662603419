/** @format */

import { makeGraphQlRequest } from 'services/graphqlService/makeGraphQlRequest';

export const fetchUserProfileQuery = ({ ssn }) => {
  return makeGraphQlRequest(`
    {
      User(ssn:"${ssn}") {
        firstName
        lastName
        phone
        email
        salary
        address{
          postCode
          city
          address1
        }
        family {
          age
          type
        }
      }
    }
  `);
};
