/** @format */

export const getActionMessageSelector = (appRoutingState) => {
  /**
   * select the appropriate action message
   */

  let selector;
  if (appRoutingState.isRetailUser) {
    if (!appRoutingState.isUserSubscribed) {
      selector = 'subscription';
    } else if (
      !appRoutingState.isPoaValid &&
      !appRoutingState.isUserSubscribed
    ) {
      selector = 'subscription';
    } else if (
      !appRoutingState.isPoaValid &&
      appRoutingState.isUserSubscribed
    ) {
      selector = 'powerOfAttorney';
    }
  } else if (appRoutingState.hasValidEmployer) {
    if (!appRoutingState.isPoaValid) {
      selector = 'powerOfAttorney';
    }
  }
  return selector;
};
