/** @format */

/** Login action-types **/
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

/** Logout action-types **/
export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

/**fetch base data actions */

export const FETCH_BASEDATA_REQUESTED = 'FETCH_BASEDATA_REQUESTED';
export const FETCH_BASEDATA_SUCCESS = 'FETCH_BASEDATA_SUCCESS';
export const FETCH_BASEDATA_FAILED = 'FETCH_BASEDATA_FAILED';

/** Show session will expire is a fw minutes message */
export const SESSION_ABOUT_TO_EXPIRE_MESSAGE_REQUESTED =
  'SESSION_ABOUT_TO_EXPIRE_MESSAGE_REQUESTED';
export const SESSION_ABOUT_TO_EXPIRE_MESSAGE_SUCCESS =
  'SESSION_ABOUT_TO_EXPIRE_MESSAGE_SUCCESS';

/** Show final confirmation message before logging out user */
export const CONFIRM_END_OF_SESSION_REQUESTED =
  'CONFIRM_END_OF_SESSION_REQUESTED';
export const CONFIRM_END_OF_SESSION_SUCCESS = 'CONFIRM_END_OF_SESSION_SUCCESS';

/** Fetch-roaring-person-data action-types **/
export const ROARING_DATA_REQUESTED = 'ROARING_DATA_REQUESTED';
export const ROARING_DATA_SUCCESS = 'ROARING_DATA_SUCCESS';
export const ROARING_DATA_SKIPPED = 'ROARING_DATA_SKIPPED';
export const ROARING_DATA_FAILED = 'ROARING_DATA_FAILED';

/** Update-company-data for registration action-types **/
export const UPDATE_COMPANY_DATA_REQUESTED = 'UPDATE_COMPANY_DATA_REQUESTED';
export const UPDATE_COMPANY_DATA_SUCCESS = 'UPDATE_COMPANY_DATA_SUCCESS';
export const UPDATE_COMPANY_DATA_FAILED = 'UPDATE_COMPANY_DATA_FAILED';
export const SET_CHOSEN_EMPLOYER_REQUESTED = 'SET_CHOSEN_EMPLOYER_REQUESTED';
export const SET_CHOSEN_EMPLOYER_SUCCESS = 'SET_CHOSEN_EMPLOYER_SUCCESS';
export const SET_CHOSEN_EMPLOYER_FAILED = 'SET_CHOSEN_EMPLOYER_FAILED';

/** Clone-roaring-person-data on registration action-types **/
export const CREATE_USER_REQUESTED = 'CREATE_USER_REQUESTED';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_SKIPPED = 'CREATE_USER_SKIPPED';
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED';

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_USER_FAMILY = 'UPDATE_USER_FAMILY';

export const DELETE_USER_FAMILY_REQUESTED = 'DELETE_USER_FAMILY_REQUESTED';
export const DELETE_USER_FAMILY_SUCCESS = 'DELETE_USER_FAMILY_SUCCESS';
export const DELETE_USER_FAMILY_SKIPPED = 'DELETE_USER_FAMILY_SKIPPED';
export const DELETE_USER_FAMILY_FAILED = 'DELETE_USER_FAMILY_FAILED';

export const UPDATE_USER_PROFILE_REQUESTED = 'UPDATE_USER_PROFILE_REQUESTED';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_SKIPPED = 'UPDATE_USER_PROFILE_SKIPPED';
export const UPDATE_USER_PROFILE_FAILED = 'UPDATE_USER_PROFILE_FAILED';

/** Send-verification-code action-types **/
export const SEND_VERIFICATION_CODE_REQUESTED =
  'SEND_VERIFICATION_CODE_REQUESTED';
export const SEND_VERIFICATION_CODE_SUCCESS = 'SEND_VERIFICATION_CODE_SUCCESS';
export const SEND_VERIFICATION_CODE_FAILED = 'SEND_VERIFICATION_CODE_FAILED';

export const UPDATE_VERIFICATION_STATUS = 'UPDATE_VERIFICATION_STATUS';

/** Confirm-verification-code action-types **/
export const CONFIRM_VERIFICATION_CODE_REQUESTED =
  'CONFIRM_VERIFICATION_CODE_REQUESTED';
export const CONFIRM_VERIFICATION_CODE_SUCCESS =
  'CONFIRM_VERIFICATION_CODE_SUCCESS';
export const CONFIRM_VERIFICATION_CODE_FAILED =
  'CONFIRM_VERIFICATION_CODE_FAILED';

/** update-email action-types **/
export const UPDATE_EMAIL_REQUESTED = 'UPDATE_EMAIL_REQUESTED';
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS';
export const UPDATE_EMAIL_FAILED = 'UPDATE_EMAIL_FAILED';

/** set-power-of-attorney-validity action-types **/
export const SET_VALIDITY_FOR_POWER_OF_ATTORNEY =
  'SET_VALIDITY_FOR_POWER_OF_ATTORNEY';

/** set-terms-agreed state **/
export const SET_IS_TERMS_AGREED_REQUESTED = 'SET_IS_TERMS_AGREED_REQUESTED';

/** subscription related actions */
export const STORE_SUBSCRIPTION_REQUESTED = 'STORE_SUBSCRIPTION_REQUESTED';
export const STORE_SUBSCRIPTION_SUCCESS = 'STORE_SUBSCRIPTION_SUCCESS';
export const STORE_SUBSCRIPTION_FAILED = 'STORE_SUBSCRIPTION_FAILED';

export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';

export const CANCEL_SUBSCRIPTION_REQUESTED = 'CANCEL_SUBSCRIPTION_REQUESTED';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILED = 'CANCEL_SUBSCRIPTION_FAILED';

export const LIST_SUBSCRIPTION_DATA_REQUESTED =
  'LIST_SUBSCRIPTION_DATA_REQUESTED';
export const LIST_SUBSCRIPTION_DATA_SUCCESS = 'LIST_SUBSCRIPTION_DATA_SUCCESS';
export const LIST_SUBSCRIPTION_DATA_FAILED = 'LIST_SUBSCRIPTION_DATA_FAILED';

export const LIST_PAYMENT_DATA_REQUESTED = 'LIST_PAYMENT_DATA_REQUESTED';
export const LIST_PAYMENT_DATA_SUCCESS = 'LIST_PAYMENT_DATA_SUCCESS';
export const LIST_PAYMENT_DATA_FAILED = 'LIST_PAYMENT_DATA_FAILED';

/** fetch subscription prices  **/
export const UPDATE_SUBSCRIPTION_PRICES_REQUESTED =
  'UPDATE_SUBSCRIPTION_PRICES_REQUESTED';
export const UPDATE_SUBSCRIPTION_PRICES_SUCCESS =
  'UPDATE_SUBSCRIPTION_PRICES_SUCCESS';
export const UPDATE_SUBSCRIPTION_PRICES_FAILED =
  'UPDATE_SUBSCRIPTION_PRICES_FAILED';

/** create-power-of-attorney action-types **/
export const CREATE_POWER_OF_ATTORNEY_REQUESTED =
  'CREATE_POWER_OF_ATTORNEY_REQUESTED';
export const CREATE_POWER_OF_ATTORNEY_FAILED =
  'CREATE_POWER_OF_ATTORNEY_FAILED';
export const CREATE_POWER_OF_ATTORNEY_SUCCESS =
  'CREATE_POWER_OF_ATTORNEY_SUCCESS';

/** store-power-of-attorney action-types **/
export const STORE_POWER_OF_ATTORNEY_REQUESTED =
  'STORE_POWER_OF_ATTORNEY_REQUESTED';
export const STORE_POWER_OF_ATTORNEY_SUCCESS =
  'STORE_POWER_OF_ATTORNEY_SUCCESS';
export const STORE_POWER_OF_ATTORNEY_FAILED = 'STORE_POWER_OF_ATTORNEY_FAILED';
export const UPDATE_POWER_OF_ATTORNEY = 'UPDATE_POWER_OF_ATTORNEY';

/** check registration state of user to define app routing logic **/
export const __APP_ROUTING_DATA_REQUESTED = '__APP_ROUTING_DATA_REQUESTED';
export const __APP_ROUTING_DATA_SUCCESS = '__APP_ROUTING_DATA_SUCCESS';
export const __APP_ROUTING_DATA_FAILED = '__APP_ROUTING_DATA_FAILED';
export const __APP_ROUTING_CHECKPOINT_LOGGED =
  '__APP_ROUTING_CHECKPOINT_LOGGED';

/** fetch fullmaktskollen provided poa-text **/
export const UPDATE_POATEXT_REQUESTED = 'UPDATE_POATEXT_REQUESTED';
export const UPDATE_POATEXT_SUCCESS = 'UPDATE_POATEXT_SUCCESS';
export const UPDATE_POATEXT_FAILED = 'UPDATE_POATEXT_FAILED';

/** add-state-pension **/
export const ADD_STATE_PENSION_REQUESTED = 'ADD_STATE_PENSION_REQUESTED';
export const ADD_STATE_PENSION_SUCCESS = 'ADD_STATE_PENSION_SUCCESS';
export const ADD_STATE_PENSION_FAILED = 'ADD_STATE_PENSION_FAILED';
export const UPDATE_STATE_PENSION = 'UPDATE_STATE_PENSION';

/** add-poa-special-company **/
export const ADD_POA_SPECIAL_COMPANY_REQUESTED =
  'ADD_POA_SPECIAL_COMPANY_REQUESTED';
export const ADD_POA_SPECIAL_COMPANY_SUCCESS =
  'ADD_POA_SPECIAL_COMPANY_SUCCESS';
export const ADD_POA_SPECIAL_COMPANY_FAILED = 'ADD_POA_SPECIAL_COMPANY_FAILED';
