/** @format */

import React from 'react';
import moment from 'moment';
export default function ActivityHistoryItems(props) {
  const { t, itemLink, activityList } = props;

  const convertTime = (time) => {
    const newTime = new Date(time * 1000);
    return moment(newTime).format('YYYY-MM-DD');
  };

  const getSubscriptionDescription = (price) => {
    return t(
      `lang_${props.flow}:${props.view}.information.active.activity.description.text`,
      {
        price: price / 100
      }
    );
  };

  if (activityList.length > 0) {
    return activityList.map((item, key) => (
      <div className='list-item-row' key={key}>
        <div className='list-item-left'>{convertTime(item.created)}</div>
        <div className='list-item-middle'>
          {getSubscriptionDescription(item.amount || item.plan.amount_decimal)}
        </div>
        <div className='list-item-right'>
          {itemLink && (
            <a
              href={item.receipt_url}
              target='_blank'
              rel='noopener noreferrer'>
              <div className='icon-go-forward'></div>
            </a>
          )}
        </div>
      </div>
    ));
  } else {
    return <></>;
  }
}
