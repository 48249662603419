/** @format */

import React from 'react';
import config from 'config';
import { routeService } from 'services';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import SplitLayout from 'components/Layout/SplitLayout';
import CollapsibleList from 'components/CollapsibleList';
import DashboardLayout from 'components/Layout/DashboardLayout';

export default function FaqPowerOfAttorney(props) {
  const { tprop } = props;

  const userState = useSelector((state) => state.user.data);
  const emailVerificationState = useSelector((state) => state.verification);

  const FaqContent = () => {
    return (
      <>
        <Accordion defaultActiveKey='0' className='text-left'>
          {tprop.faq_content.map((faq, key) => (
            <CollapsibleList
              key={key}
              eventKey={key + 1}
              className='poa-faq-accordion-link'
              heading={faq.heading}
              text={faq.text}
            />
          ))}
        </Accordion>

        <div className='set-validity-for-poa-disclaimer'>
          <div className='logo-fullmaktskollen' />
          <div className='set-validity-for-poa-disclaimer-text-container'>
            <small>
              {tprop.disclaimer.text}{' '}
              <a
                className='terms-url font-weight-bold'
                href={tprop.disclaimer.link.url}
                target='_blank'
                rel='noopener noreferrer'>
                {tprop.disclaimer.link.text}
              </a>
            </small>
          </div>
        </div>
      </>
    );
  };
  return (
    <div className='poa-faq-view' id='registration-flow'>
      {!userState.email && !emailVerificationState.isEmailVerified && (
        <Redirect to={routeService.register_welcome} />
      )}
      {props.flowOrigin === config.constants.flowOrigins.profile ? (
        <DashboardLayout
          {...props}
          hidePreviousButton={false}
          goBackUrl={routeService.onboarding_welcome_to_onboarding}>
          <div className='profile-set-validity-for-poa-container poa-faq-content'>
            <FaqContent />
          </div>
        </DashboardLayout>
      ) : (
        <SplitLayout
          flow={props.flow}
          stepsWizard={props.stepsWizard}
          className='postlogin-template poa-faq-content'
          backgroundImage='registration-welcome-left-background-image'>
          <FaqContent />
        </SplitLayout>
      )}
    </div>
  );
}
