/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import SplitLayout from 'components/Layout/SplitLayout';

export default function Start(props) {
  const { flow, tprop, btnColor } = props;

  const leftContent = (
    <>
      <div className='start-text-container'>
        <h1>{tprop.description.heading.text}</h1>
        {tprop.description.content.map((item, key) => (
          <p key={key}>
            {item.text}
            <span className='pink-dot'>.</span>
          </p>
        ))}
      </div>
    </>
  );

  return (
    <>
      <SplitLayout
        flow={flow}
        hidePreviousButton={true}
        hideMobileHeader={true}
        hideLogoutButton={true}
        className='prelogin-template'
        leftContent={leftContent}>
        <Link to={'/'}>
          <div className='logo-the-third-act' />
        </Link>

        {tprop.heading && (
          <h1 className='start-page-heading'>{tprop.heading.text}</h1>
        )}

        {leftContent}

        <div className='logo-bankid' />

        {tprop.buttons && (
          <div className='button-container'>
            {tprop.buttons.map((button, key) => (
              <div key={key}>
                <Link to={button.url}>
                  <Button className={`btn-primary-transparent ${btnColor}`}>
                    {button.text}{' '}
                    <span className='text-capitalize'>{button.subText}</span>
                  </Button>
                </Link>
              </div>
            ))}
          </div>
        )}
      </SplitLayout>
    </>
  );
}
