/** @format */

import React from 'react';
import FormField from 'components/Forms/FormField';

export default function AgeInputForChildren(props) {
  const { updateAge, decrementChild, isAgeUpdated, childrensObject } = props;

  const addClassName = (age) => {
    return ` ${age === 0 ? 'children-age-error' : ''}`;
  };

  if (childrensObject) {
    return childrensObject.map((item, key) => {
      return (
        <div key={key} className='children-block' dataid={isAgeUpdated}>
          <FormField
            key={key}
            name={`childsAge_${item.id}`}
            value={item.age}
            onChange={(e) => updateAge(e.target.value, key)}
            className={`form-control children-input ${addClassName(item.age)}`}
            maxLength={2}
          />
          <div
            className='family-container decrement icon-minus children-remove'
            onClick={() => decrementChild({ key, _id: item._id })}></div>
        </div>
      );
    });
  } else {
    return <> </>;
  }
}
