/** @format */

import { mutations } from 'services';
import { store } from 'stateContainer/store';
import { push } from 'connected-react-router';
import { put, call, take, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';
function* workerSaga({ payload }) {
  try {
    /**
     * hold until all family nodes are deleted if any
     */

    if (payload.hasFamilyNodesToDelete) {
      yield take([actionTypes.DELETE_USER_FAMILY_REQUESTED]);
    }

    /**
     * update user personal data in the database
     */

    yield call(mutations.updateUserMutation, payload);

    /**
     * update user address in the database
     */

    yield call(mutations.updateAddressOnUserMutation, payload);
    const { user } = store.getState();

    const data = {
      ...user.data,
      ...payload
    };

    /**
     * update user data in the redux store
     */

    yield put({
      type: actionTypes.UPDATE_USER_DATA,
      payload: data
    });

    /**
     * update user family in the database
     * Adults
     */

    for (let i = 0; i < payload.family.adults.length; i++) {
      if (!payload.family.adults[i]._id) {
        const data = {
          family: payload.family.adults[i],
          ssn: payload.ssn
        };
        yield call(mutations.createFamilyOnUserMutation, data);
      }
    }

    /**
     * update user family in the database
     * Children
     */

    for (let i = 0; i < payload.family.children.length; i++) {
      if (
        !payload.family.children[i]._id &&
        payload.family.children[i].age !== 0
      ) {
        yield call(mutations.createFamilyOnUserMutation, {
          family: payload.family.children[i],
          ssn: payload.ssn
        });
      }
    }

    /**
     * update user state with update user data status
     */

    yield put({ type: actionTypes.UPDATE_USER_PROFILE_SUCCESS });

    /**
     * update appRouting state with  progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { updateUserProfile: 'done' }
    });

    yield put({
      type: actionTypes.__APP_ROUTING_DATA_SUCCESS,
      payload: { isProfileUpdated: true }
    });

    /**
     * redirect to dashboard once update has been completed
     */

    yield put(push(payload.successUrl));
  } catch (error) {
    yield put({ type: actionTypes.UPDATE_USER_PROFILE_FAILED, error });
    yield put(push(payload.failedUrl));
  }
}

export function* updateUserProfileSaga() {
  yield takeLatest(actionTypes.UPDATE_USER_PROFILE_REQUESTED, workerSaga);
}
