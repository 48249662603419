/** @format */

import React from 'react';
import { routeService } from 'services';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import PuffLoader from 'react-spinners/PuffLoader';
import ButtonLoader from 'components/ButtonLoader';
import SplitLayout from 'components/Layout/SplitLayout';
import { getIsUserDataMissing } from 'utils/getIsUserDataMissing';
import CompanyChoiceModal from 'components/Modals/CompanyChoiceModal';
import UserDataMissingModal from 'components/Modals/UserDataMissingModal';

export default (function Welcome(props) {
  const { tprop } = props;
  const { t } = useTranslation();

  const {
    requesting,
    data: { details = {} }
  } = useSelector((state) => state.roaring) || {};
  const userState = useSelector((state) => state.user) || {};
  const companyState = useSelector((state) => state.company) || {};
  const appRoutingState = useSelector((state) => state.appRouting);

  let { firstName = '' } = (details && details[0]) || {};

  if (!firstName) {
    firstName = userState.data.firstName || '';
  }

  firstName = t(`lang_${props.flow}:${props.view}.sub_heading.text`, {
    name: `${firstName}`
  });
  const employersList =
    (companyState.hasValidEmployer && companyState.data) || [];

  const isStatePensionPending =
    appRoutingState.isPoaViewed && !appRoutingState.isStatePensionAdded;

  const isUserDataMissing = getIsUserDataMissing({
    appRoutingState,
    userState
  });

  return (
    <>
      <div className='welcome-view' id='registration-flow'>
        {/** user data is missing -- inform the user and logout */}
        {isUserDataMissing && <UserDataMissingModal show={true} />}

        {/* user has already completed registration. send them to onboarding page */}
        {!isUserDataMissing && appRoutingState.isRegistrationCompleted && (
          <Redirect to={routeService.onboarding_welcome_to_onboarding} />
        )}

        {/* user has already signed poa but not added state pension. send them to add state pension page */}
        {isStatePensionPending && (
          <Redirect to={routeService.register_add_state_pension} />
        )}

        {appRoutingState.requesting ? (
          <div className='welcome-loader-wrapper'>
            <div className='loader-box'>
              <PuffLoader size={96} color={'var(--color-black)'} loading />
            </div>
          </div>
        ) : (
          <SplitLayout
            flow={props.flow}
            hidePreviousButton
            stepsWizard={props.stepsWizard}
            className='postlogin-template'
            backgroundImage='registration-welcome-left-background-image'>
            <div className='welcome-inner-container'>
              <div className='content'>
                <div className='split-layout-right-heading-container'>
                  <h1>{firstName}</h1>
                </div>
                <span className='split-layout-right-subheading-container'>
                  {!appRoutingState.isCurrentlyEmployed &&
                    employersList.length === 1 && (
                      <h2>{employersList[0].name}</h2>
                    )}
                  {!appRoutingState.isCurrentlyEmployed &&
                    companyState.chosenEmployer &&
                    companyState.chosenEmployer.name && (
                      <h2>{companyState.chosenEmployer.name}</h2>
                    )}
                </span>
                <div className='split-layout-right-description-container'>
                  {tprop.description.map((item, key) => (
                    <p className='text-left' key={key}>
                      {item.text}
                    </p>
                  ))}
                </div>
              </div>

              <div className='split-layout-right-button-container'>
                {requesting || companyState.requesting ? (
                  <ButtonLoader />
                ) : (
                  <div className='button-container'>
                    <Link to={tprop.buttons.url}>
                      <Button className={`${props.btnColor}`}>
                        {tprop.buttons.text}
                      </Button>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </SplitLayout>
        )}

        {!companyState.requesting &&
          appRoutingState.hasMultipleEmployers &&
          !companyState.chosenEmployer &&
          !appRoutingState.isCurrentlyEmployed && (
            <CompanyChoiceModal
              id='choose-company'
              textSelector='chooseCompany'
              employersList={employersList}
              {...props}
            />
          )}
      </div>
    </>
  );
});
