/** @format */

import config from 'config';
import { invokeRestApi } from 'utils/invokeRestApi';
import { verificationCodeTemplate } from 'services/emailService/templates/verification-code';

const {
  services: {
    SEND_EMAIL_SERVICE_PATHNAME,
    CONFIRM_VERIFICATION_CODE_SERVICE_PATHNAME
  }
} = config.app;

export async function emailService({ email, firstName }) {
  const template = verificationCodeTemplate({ email, firstName });
  return await invokeRestApi({
    service: SEND_EMAIL_SERVICE_PATHNAME,
    payload: template
  });
}

export async function confirmVerificationCodeService(payload) {
  return await invokeRestApi({
    service: CONFIRM_VERIFICATION_CODE_SERVICE_PATHNAME,
    payload
  });
}

export default {
  emailService,
  confirmVerificationCodeService
};
