/** @format */

import { listSubscriptionService } from 'services';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  try {
    /**
     * fetch subscription-data from Stripe
     */

    const response = yield call(listSubscriptionService, {
      customerId: payload.customerId
    });

    /**
     * update subscription state with info fetched from Stripe
     */

    yield put({
      type: actionTypes.LIST_SUBSCRIPTION_DATA_SUCCESS,
      payload: {
        subscriptions: response.subscriptions && response.subscriptions.data
      }
    });

    /**
     * update appRouting state with  progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { listSubscriptionData: 'done' }
    });
  } catch (error) {
    /**
     * throw error when the api call fails
     */

    yield put({ type: actionTypes.LIST_SUBSCRIPTION_DATA_FAILED, error });
  }
}

export function* listSubscriptionSaga() {
  yield takeLatest(actionTypes.LIST_SUBSCRIPTION_DATA_REQUESTED, workerSaga);
}
