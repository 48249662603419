/** @format */

/**
 *
 * Get employment details of user based on the basedata object
 * @param {Object} {user : Object, caller: String}
 *  @basedata: {Object} Mandatory - the basedata query result object
 *  @caller: {String} Optional - the caller from where the user object initited from. This can be used for debugging purposes
 * @returns JSON object with boolean values for every the steps taken by the user in their profile
 */

export const getEmploymentStatus = ({ baseData, caller }) => {
  /**
   *
   * default response object
   */
  const data = {
    caller,
    hasValidEmployer: false,
    isCurrentlyEmployed: false,
    currentEmployer: {},
    validEmployersList: [],
    isRetailUser: false,
    defaultCompany: {},
    hasMultipleEmployers: false
  };

  if (!baseData) {
    return data;
  }

  data.hasValidEmployer =
    baseData.Employee &&
    baseData.Employee[0] &&
    baseData.Employee.length > 0 &&
    baseData.Employee[0].identity.length > 0;

  data.isCurrentlyEmployed =
    baseData.User &&
    baseData.User[0] &&
    baseData.User[0].employedBy &&
    baseData.User[0].employedBy.organisationId &&
    baseData.User[0].employedBy.organisationId.length > 0;

  data.currentEmployer =
    baseData.User &&
    baseData.User[0] &&
    baseData.User[0].employedBy &&
    baseData.User[0].employedBy;

  data.validEmployersList =
    (baseData.Employee &&
      baseData.Employee[0] &&
      baseData.Employee[0].companies) ||
    {};

  data.isRetailUser = !data.hasValidEmployer;

  data.defaultCompany = baseData.DefaultCompany && baseData.DefaultCompany[0];

  data.hasMultipleEmployers = data.validEmployersList.length > 1;
  return data;
};
