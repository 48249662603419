/** @format */

import { makeGraphQlRequest } from 'services/graphqlService/makeGraphQlRequest';

/**
 * Graphql mutation to add a special company on a user
 * @param {Object} data {ssn, code}
 * @returns graphQL mutation
 */
export const addPOASpecialCompanyMutation = (data) => {
  return makeGraphQlRequest(`mutation {
       addSpecialPoaOnUser(
          ssn: "${data.ssn}"      
          code: "${data.code}"
      ){
          _id
      }
    }`);
};
