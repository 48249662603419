/** @format */

import { makeGraphQlRequest } from 'services/graphqlService/makeGraphQlRequest';

export const deleteFamilyMemberMutation = ({ ssn, _id }) => {
  return makeGraphQlRequest(`mutation {
    deleteFamilyMemberOnUser(
      ssn:"${ssn}",      
      id:${_id}
    ) {
      _id
    }
  }`);
};
