/** @format */

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import { routeService } from 'services';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ButtonLoader from 'components/ButtonLoader';
import FormField from 'components/Forms/FormField';
import { useDispatch, useSelector } from 'react-redux';
import SplitLayout from 'components/Layout/SplitLayout';
import { addStatePensionAction } from 'stateContainer/actions';
import { getItemLinkified } from 'views/Registration/AddStatePension/getItemLinkified';
import { handleNumericValue } from 'views/Registration/AddStatePension/handleNumericValue';

export default function AddStatePension(props) {
  const { tprop } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { search } = useLocation();
  const [ppmPensionValue, setPpmPensionValue] = useState('');
  const [generalPensionValue, setGeneralPensionValue] = useState('');

  const sessionState = useSelector((state) => state.session.data);
  const { requesting } = useSelector((state) => state.statePension);

  const initialValues = {
    generalPension: generalPensionValue,
    ppmPension: ppmPensionValue,
    Avanza: false,
    Skandikon: false
  };

  /**
   *
   * Handles the form submit event
   * @param {Object} values consists of the form input values
   * @returns {function} dispatches a redux action that takes the data object as input
   *
   */
  const onSubmit = (values) => {
    const { Avanza, Skandikon } = values;

    const data = {
      ssn: sessionState.ssn,
      generalPensionValue: generalPensionValue > 0 ? generalPensionValue : 0,
      ppmPensionValue: ppmPensionValue > 0 ? ppmPensionValue : 0,
      poaSpecialCompanies: [],
      successUrl: `${routeService.register_registration_complete}${search}`,
      failedUrl: `${routeService.register_add_state_pension}?status=failed`
    };

    // add company codes to the poaSpecialCompanies array if they were checked
    Avanza && data['poaSpecialCompanies'].push('Avanza');
    Skandikon && data['poaSpecialCompanies'].push('Skandikon');

    dispatch(addStatePensionAction(data));
  };

  return (
    <div className='add-state-pension-view' id='registration-flow'>
      <SplitLayout
        flow={props.flow}
        stepsWizard={props.stepsWizard}
        className='postlogin-template add-state-pension-content'
        backgroundImage='registration-user-left-background-image'>
        <div className='text-left add-state-pension-container'>
          <div className='split-layout-right-heading-container'>
            <h1>{tprop.heading.text}</h1>

            <div>
              {tprop.description.map((item, key) => (
                <p key={key}>{item.text}</p>
              ))}
            </div>
          </div>

          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            <Form className='form-group'>
              <div className='content'>
                <div className='add-state-pension-instructions-container'>
                  {tprop.instructions.steps.map(
                    (item, key) =>
                      item.props.section === 'top' &&
                      (item.props.url ? (
                        <p
                          key={key}
                          dangerouslySetInnerHTML={getItemLinkified({
                            t,
                            item,
                            key
                          })}
                        />
                      ) : (
                        <p key={key}>{item.text}</p>
                      ))
                  )}
                </div>

                <Row>
                  <Col>
                    <FormField
                      name='generalPension'
                      labeltext={tprop.instructions.input[0].label}
                      maxLength={20}
                      value={generalPensionValue}
                      onChange={(e) =>
                        handleNumericValue({
                          e,
                          setGeneralPensionValue
                        })
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormField
                      name='ppmPension'
                      labeltext={tprop.instructions.input[1].label}
                      maxLength={20}
                      value={ppmPensionValue}
                      onChange={(e) =>
                        handleNumericValue({
                          e,
                          setPpmPensionValue
                        })
                      }
                    />
                  </Col>
                </Row>
                <div className='add-state-pension-instructions-container'>
                  {tprop.instructions.steps.map(
                    (item, key) =>
                      item.props.section === 'bottom' && (
                        <p key={key}>{item.text}</p>
                      )
                  )}
                </div>

                <Row className='checkbox-container'>
                  {tprop.instructions.insuranceCompanies.map((item, key) => (
                    <Col sm={12} key={key}>
                      <FormField
                        className='form-check-input'
                        name={item.code}
                        type='checkbox'
                      />
                      <label className='form-check-label'>{item.text}</label>
                    </Col>
                  ))}
                </Row>
              </div>
              <div className='split-layout-right-form-button-container'>
                <div className='text-center'>
                  {requesting ? (
                    <ButtonLoader />
                  ) : (
                    <Button className={`${props.btnColor}`} type='submit'>
                      {tprop.buttons.text}
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </SplitLayout>
    </div>
  );
}
