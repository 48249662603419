/** @format */

/**
 * Check if user data is missing in the user state
 * @param {object} { appRoutingState, userState }
 * @returns boolean  if user data is missing
 */

export const getIsUserDataMissing = ({ appRoutingState, userState }) => {
  const isUserStateMissingData =
    appRoutingState.isRegistrationCompleted &&
    userState.data &&
    (userState.data.firstName === '' ||
      !userState.data.firstName ||
      userState.data.address1 === '' ||
      !userState.data.address1 ||
      userState.data.city === '' ||
      !userState.data.city ||
      userState.data.postCode === '' ||
      !userState.data.postCode);
  return isUserStateMissingData;
};
