/** @format */

import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  try {
    /**
     * update company state with users relationship with company
     */

    yield put({
      type: actionTypes.SET_CHOSEN_EMPLOYER_SUCCESS,
      payload
    });

    /**
     * update appRouting state with registration progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { setEmployer: 'done' }
    });
  } catch (error) {
    yield put({
      type: actionTypes.SET_CHOSEN_EMPLOYER_FAILED,
      data: error.errors[0] || error
    });
  }
}

export function* setChosenEmployerSaga() {
  yield takeLatest(actionTypes.SET_CHOSEN_EMPLOYER_REQUESTED, workerSaga);
}
