/** @format */

import { makeGraphQlRequest } from 'services/graphqlService/makeGraphQlRequest';

export const updateUserMutation = (data) => {
  return makeGraphQlRequest(`mutation {
    UpdateUser(
      fid: "${data.fid}",
      firstName: "${data.firstName}",
      lastName: "${data.lastName}",
      phone: "${data.phone}",
      email: "${data.email}",
      salary:${data.salary}
    ) {
      _id
    }
  }`);
};
