/** @format */

import config from 'config';
import React, { useState } from 'react';
import { routeService } from 'services';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import SplitLayout from 'components/Layout/SplitLayout';
import { isTermsAgreedAction } from 'stateContainer/actions';
import DashboardLayout from 'components/Layout/DashboardLayout';
import PersonDataBlock from 'components/AgreeTermsAndPoa/PersonDataBlock';
import TermsAndConditions from 'components/AgreeTermsAndPoa/TermsAndConditions';
import ToggleCheckBoxAndDisclaimer from 'components/AgreeTermsAndPoa/ToggleCheckBoxAndDisclaimer';

export default function ApprovePowerOfAttorney(props) {
  const { tprop } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isTermsAgreed, setTermsIsAgreed] = useState(false);

  const userState = useSelector((state) => state.user.data);
  const emailVerificationState = useSelector((state) => state.verification);

  const toggleTerms = (bool) => {
    setTermsIsAgreed(bool);
    dispatch(isTermsAgreedAction(bool));
  };

  const {
    city,
    email,
    phone,
    lastName,
    firstName,
    postCode,
    address1
  } = useSelector((state) => state.user.data);

  const name = `${firstName} ${lastName}`;

  const address = `${address1} ${postCode} ${city}`;

  const companyState = useSelector((state) => state.company);

  const getButtonUrl = (url) => {
    if (!companyState.isRetailUser) {
      url = routeService.register_approve_power_of_attorney;
    }
    if (props.flowOrigin === config.constants.flowOrigins.profile) {
      url = routeService.profile_stripe_payment;
    }
    return url;
  };

  const PageContent = () => (
    <div className='content'>
      <PersonDataBlock itemName='profile' item={name} />

      <PersonDataBlock itemName='location' item={address} />

      <PersonDataBlock itemName='mobile-phone' item={`${phone}`} />

      <PersonDataBlock itemName='email' item={`${email}`} />

      <TermsAndConditions
        heading={tprop.bottom_section.heading}
        description={tprop.bottom_section.description}
        {...props}
      />
    </div>
  );

  return (
    <div className='agree-terms-view'>
      {!userState.email && !emailVerificationState.isEmailVerified && (
        <Redirect to={routeService.register_welcome} />
      )}
      {props.flowOrigin === config.constants.flowOrigins.profile ? (
        <DashboardLayout
          {...props}
          hidePreviousButton={false}
          goBackUrl={routeService.profile_set_power_of_attorney}>
          <div className='profile-resubscription-flow-container '>
            <PageContent />

            <div className='agree-terms-button-container'>
              <ToggleCheckBoxAndDisclaimer
                t={t}
                setTermsIsAgreed={toggleTerms}
                isTermsAgreed={isTermsAgreed}
                {...props}
              />

              <div className='approve-poa-submit-button-container'>
                {tprop.buttons.map((button, key) => (
                  <Link key={key} to={() => getButtonUrl(button.url)}>
                    <Button
                      className={`btn-primary-transparent ${props.btnColor}`}
                      disabled={!isTermsAgreed}>
                      {button.text}
                    </Button>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </DashboardLayout>
      ) : (
        <div id='registration-flow'>
          <SplitLayout
            flow={props.flow}
            stepsWizard={props.stepsWizard}
            className='postlogin-template agree-terms-container'
            backgroundImage='registration-welcome-left-background-image'>
            <PageContent />

            <div className='agree-terms-button-container'>
              <ToggleCheckBoxAndDisclaimer
                t={t}
                setTermsIsAgreed={toggleTerms}
                isTermsAgreed={isTermsAgreed}
              />

              <div className='approve-poa-submit-button-container'>
                {tprop.buttons.map((button, key) => (
                  <Link key={key} to={() => getButtonUrl(button.url)}>
                    <Button
                      className={`btn-primary-transparent ${props.btnColor}`}
                      disabled={!isTermsAgreed}>
                      {button.text}
                    </Button>
                  </Link>
                ))}
              </div>
            </div>
          </SplitLayout>
        </div>
      )}
    </div>
  );
}
