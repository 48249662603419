/** @format */

import moment from 'moment';
import config from 'config';
import { Modal } from 'react-bootstrap';
import { routeService } from 'services';
import React, { useEffect } from 'react';
import getUrlParams from 'utils/getUrlParams';
import Header from 'components/Layout/Header';
import { useTranslation } from 'react-i18next';
import PuffLoader from 'react-spinners/PuffLoader';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import { getEncodedSignText } from 'utils/getEncodedSignText';
import { createPowerOfAttorneyAction } from 'stateContainer/actions';

function CreatingPowerOfAttorney(props) {
  let { expiryDate, validity } = useSelector(
    (state) => state.powerOfAttorney.data
  );
  expiryDate = moment(expiryDate).format('YYYY-MM-DD');

  const { ssn, email, firstName, lastName } = useSelector(
    (state) => state.user.data
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { search } = useLocation();
  const { tprop, flowOrigin } = props;
  const { key } = getUrlParams(['key']);

  const encodedSignText = getEncodedSignText(t);

  const successUrl =
    flowOrigin === config.constants.flowOrigins.profile
      ? `${routeService.onboarding_welcome_to_onboarding}${search}`
      : `${routeService.register_add_state_pension}${search}`;

  const failedUrl =
    flowOrigin === config.constants.flowOrigins.profile
      ? `${routeService.profile_approve_power_of_attorney}?status=failed`
      : `${routeService.register_approve_power_of_attorney}?status=failed`;

  const data = {
    key: key, // mandatory: obtained from query string parameters in digital-signature-service client redirect url
    email: email, // mandatory,
    firstName: firstName, // mandatory,
    lastName: lastName, // mandatory,
    ssnUsedToRegister: ssn, //mandatory
    signText: encodedSignText, //mandatry -- base64 encoded signtext -- same exact text sent to criipto
    successUrl: successUrl,
    failedUrl: failedUrl
  };

  if (validity === 'limited') {
    data.expiryDate = expiryDate; //optional -- poa expiry date chosen by the user
  }

  useEffect(() => {
    dispatch(createPowerOfAttorneyAction(data)); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CreatingPoaContent = () => {
    return (
      <div className='profile-resubscription-flow-container'>
        <div className='centered-content'>
          <div className='centered-content-top'>
            <h1>{tprop.heading.text}</h1>
          </div>
          <div className='centered-content-bottom'>
            <div className='loading-indicator-container'>
              <PuffLoader size={96} color={'var(--color-pink)'} loading />
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {flowOrigin === config.constants.flowOrigins.profile ? (
        <div>
          <Header flow={props.flow} />
          <Modal
            className='message-modal profile-resubscription-flow-container'
            show={true}>
            <Modal.Body>
              <CreatingPoaContent />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
      ) : (
        <>
          <div className='creating-power-of-attorney-container centered-container centered-container-default-background-image'>
            <CreatingPoaContent />
          </div>
        </>
      )}
    </>
  );
}

export default withRouter(CreatingPowerOfAttorney);
