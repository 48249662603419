/** @format */

import config from 'config';
import { invokeRestApi } from 'utils/invokeRestApi';

const {
  services: {
    SUBSCRIPTION_SERVICE,
    LIST_PAYMENT_SERVICE,
    LIST_SUBSCRIPTION_SERVICE,
    CANCEL_SUBSCRIPTION_SERVICE
  }
} = config.app;

export async function subscriptionService(payload) {
  return await invokeRestApi({ service: SUBSCRIPTION_SERVICE, payload });
}

export async function cancelSubscriptionService(payload) {
  return await invokeRestApi({ service: CANCEL_SUBSCRIPTION_SERVICE, payload });
}

export async function listSubscriptionService(payload) {
  return await invokeRestApi({ service: LIST_SUBSCRIPTION_SERVICE, payload });
}

export async function listPaymentService(payload) {
  return await invokeRestApi({ service: LIST_PAYMENT_SERVICE, payload });
}

export default {
  listPaymentService,
  subscriptionService,
  listSubscriptionService,
  cancelSubscriptionService
};
