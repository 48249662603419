/** @format */

import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  try {
    const data = {
      ...payload.retailPrice[0],
      ...payload.taxRate[0],
      ...payload.stripeLocale[0]
    };

    /**
     * update subscription price in the subscription state
     */

    yield put({
      type: actionTypes.UPDATE_SUBSCRIPTION_PRICES_SUCCESS,
      payload: data
    });

    /**
     * update appRouting state with progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { fetchSubscriptionPrices: 'done' }
    });
  } catch (error) {
    yield put({ type: actionTypes.UPDATE_SUBSCRIPTION_PRICES_FAILED, error });
  }
}

export function* updateSubscriptionPricesSaga() {
  yield takeLatest(
    actionTypes.UPDATE_SUBSCRIPTION_PRICES_REQUESTED,
    workerSaga
  );
}
