/** @format */

import { push } from 'connected-react-router';
import { cancelSubscriptionService } from 'services';
import { put, call, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  try {
    yield call(cancelSubscriptionService, payload);

    const appRoutingData = {
      isUserSubscribed: false,
      isSubscriptionCancelled: true,
      showActionRequiredMessage: true
    };

    /**
     * update appRouting state with registration progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { cancelSubscription: 'done' }
    });

    yield put({
      type: actionTypes.__APP_ROUTING_DATA_SUCCESS,
      payload: appRoutingData
    });

    /**
     * update subscription status in the subscription state
     */

    yield put({
      type: actionTypes.CANCEL_SUBSCRIPTION_SUCCESS,
      payload: appRoutingData
    });
  } catch (error) {
    yield put({ type: actionTypes.CANCEL_SUBSCRIPTION_FAILED, error });
    yield put(push(payload.failedUrl));
  }
}

export function* cancelSubscriptionSaga() {
  yield takeLatest(actionTypes.CANCEL_SUBSCRIPTION_REQUESTED, workerSaga);
}
