/** @format */

import { makeGraphQlRequest } from 'services/graphqlService/makeGraphQlRequest';

export const updateAddressOnUserMutation = (data) => {
  return makeGraphQlRequest(`mutation {
    updateAddressOnUser(
      ssn: "${data.ssn}",      
      address1: "${data.address1}",
      address2:"",
      city: "${data.city}",
      postCode: "${data.postCode}"
    ) {
      _id
    }
  }`);
};
