/** @format */

import { fetchBaseDataQuery } from 'services/graphqlService/queries/fetch-base-data-query';
import { fetchUserProfileQuery } from 'services/graphqlService/queries/fetch-user-profile-query.js';
import { isUserEmailVerifiedQuery } from 'services/graphqlService/queries/is-user-email-verified-query';

export const queries = {
  fetchBaseDataQuery,
  fetchUserProfileQuery,
  isUserEmailVerifiedQuery
};
