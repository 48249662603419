/** @format */

import React from 'react';
import { useSelector } from 'react-redux';
import MobileHeaderLeft from 'components/Layout/Header/MobileHeader/MobileHeaderLeft';
import MobileHeaderRight from 'components/Layout/Header/MobileHeader/MobileHeaderRight';
import MobileHeaderCenter from 'components/Layout/Header/MobileHeader/MobileHeaderCenter';

export default function MobileHeader(props) {
  const userState = useSelector((state) => state.user);

  const { city = '', lastName = '', firstName = '' } = userState.data || {};

  const fullName = `${firstName} ${lastName}`;

  return (
    <>
      <div className='mobile-header'>
        <MobileHeaderLeft homePageUrl={props.homePageUrl} {...props} />

        <MobileHeaderCenter {...props} />

        <MobileHeaderRight
          flow={props.flow}
          location={city}
          fullName={fullName}
          profileUrl={props.profileUrl}
          {...props}
        />
      </div>
    </>
  );
}
