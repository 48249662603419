/** @format */
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

export default withRouter(function SubscriptionModal(props) {
  useEffect(() => {
    const that = props;
    setTimeout(() => {
      that.history.push(that.destinationUrl);
    }, 3000); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal
        className='message-modal subscription-modal'
        show={props.showModal}>
        <div className='modal-container'>
          <div className='subscription-modal-header'>
            <div className='subscription-modal-icon'>
              <div className='icon-exclamation-black'></div>
            </div>
            <div className='subscription-modal-heading'>
              <h1>{props.heading}</h1>
            </div>
          </div>
          <div className='subscription-modal-body'>{props.message}</div>
          <div className='subscription-modal-buttons'>{props.children}</div>
        </div>
      </Modal>
    </>
  );
});
