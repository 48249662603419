/** @format */

import { push } from 'connected-react-router';
import { put, takeLatest, call } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';
import { mutations, storePowerOfAttorneyService } from 'services';

function* workerSaga(payload) {
  let signedDate = new Date(payload.signature.iat * 1000);
  signedDate = signedDate.toISOString();

  try {
    yield call(storePowerOfAttorneyService, payload);
    const data = {
      poaId: payload.poaId,
      isValid: true,
      signedDate: signedDate
    };
    yield call(mutations.updateUserPoaMutation, data);

    /**
     * update store with store poa status
     */

    yield put({ type: actionTypes.STORE_POWER_OF_ATTORNEY_SUCCESS });

    /**
     * update appRouting state with progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { saveSignedPOA: 'done' }
    });

    yield put({
      type: actionTypes.__APP_ROUTING_DATA_SUCCESS,
      payload: { isPoaViewed: true }
    });

    yield put(push(payload.successUrl));
  } catch (error) {
    yield put({ type: actionTypes.STORE_POWER_OF_ATTORNEY_FAILED, error });
    yield put(push(payload.failedUrl));
  }
}

export function* storePowerOfAttorneySaga() {
  yield takeLatest(actionTypes.STORE_POWER_OF_ATTORNEY_REQUESTED, workerSaga);
}
