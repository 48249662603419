/** @format */

import { Formik, Form } from 'formik';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import routeService from 'services/routeService';
import PuffLoader from 'react-spinners/PuffLoader';
import React, { useState, useEffect } from 'react';
import FormField from 'components/Forms/FormField';
import ButtonLoader from 'components/ButtonLoader';
import { getNumericValue } from 'utils/geNumericValue';
import { useDispatch, useSelector } from 'react-redux';
import DashboardLayout from 'components/Layout/DashboardLayout';
import RowItemBlock from 'views/Dashboard/MyAccount/RowItemBlock';
import LineItemBlock from 'views/Dashboard/MyAccount/LineItemBlock';
import AdultsNumberBox from 'views/Dashboard/MyAccount/AdultsNumberBox';
import ChildrenNumberBox from 'views/Dashboard/MyAccount/ChildrenNumberBox';
import { validationSchema } from 'views/Dashboard/MyAccount/validationSchema';
import AgeInputForChildren from 'views/Dashboard/MyAccount/AgeInputForChildren';
import {
  fetchListPaymentsAction,
  deleteFamilyMemberAction,
  updateFamilyMemberAction,
  fetchListSubscriptionAction,
  updateUserProfileRequestAction
} from 'stateContainer/actions';

export default function MyAccount(props) {
  const { tprop } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [getSalary, setSalary] = useState('');
  const [getPhoneNumber, setPhoneNumber] = useState('');
  const [getIsAgeUpdated, setIsAgeUpdated] = useState();
  const [getNoOfChildren, setNoOfChildren] = useState(0);
  const [getNumberOfAdults, setNumberOfAdults] = useState(0);
  const [getDeletedFamilyNodes, setDeletedFamilyNodes] = useState([]);

  const userState = useSelector((state) => state.user);

  const {
    data: { stripeCustomerId = '' }
  } = useSelector((state) => state.subscription);

  const { isEmailVerified } = useSelector((state) => state.verification);

  const appRoutingState = useSelector((state) => state.appRouting);

  let {
    data: { price }
  } = useSelector((state) => state.subscription);

  const family = userState.data.family || {};

  const {
    ssn = '',
    fid = '',
    phone = '',
    email = '',
    lastName = '',
    firstName = ''
  } = userState.data || {};

  /**
   * update phone number
   */

  useEffect(() => {
    const phoneNumber =
      (userState.data && userState.data.phone && userState.data.phone) ||
      getPhoneNumber;
    setPhoneNumber(phoneNumber);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * update salary state
   */

  useEffect(() => {
    const salary =
      (userState.data && userState.data.salary && userState.data.salary) ||
      getSalary;
    setSalary(salary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * update adults state
   */

  useEffect(() => {
    const adults = (family && family.adults && family.adults) || [];
    setNumberOfAdults(adults.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [family, family.adults, getNumberOfAdults]);

  /**
   * update children state
   */

  useEffect(() => {
    const children = (family && family.children && family.children) || [];
    setNoOfChildren(children.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNoOfChildren, family.children]);

  /**
   * fetch subscriptions
   */

  useEffect(() => {
    appRoutingState.isUserSubscribed &&
      dispatch(
        fetchListSubscriptionAction({
          customerId: stripeCustomerId
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * fetch list of completed payments
   */

  useEffect(() => {
    appRoutingState.isUserSubscribed &&
      dispatch(
        fetchListPaymentsAction({
          customerId: stripeCustomerId
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const salary = userState.data.salary || 0;

  let { city = '', address1 = '', postCode = '' } =
    (userState && userState.data && userState.data.address) || {};

  if (!address1) {
    city = userState.data.city;
    address1 = userState.data.address1;
    postCode = userState.data.postCode;
  }

  const initialValues = {
    city: city,
    phone: phone,
    email: email,
    salary: salary,
    address: address1,
    postCode: postCode,
    lastName: lastName,
    firstName: firstName
  };

  /**
   * increment the number of adults
   * @param none
   * @returns
   *    1. increments local state with the number of adults
   *    2. fires redux action and updates the family object
   */

  const incrementAdult = () => {
    if (getNumberOfAdults < 10) {
      setNumberOfAdults(getNumberOfAdults + 1);

      dispatch(
        updateFamilyMemberAction({
          ...family,
          adults: [
            ...family.adults,
            { age: 0, type: 'Adult', id: getNumberOfAdults + 1 }
          ]
        })
      );
    }
  };

  /**
   * decrement the number of adults
   * @param none
   * @returns
   *    1. decrement local state with the number of adults
   *    2. fires redux action and updates the family object
   */

  const decrementAdult = () => {
    if (getNumberOfAdults > 0) {
      setNumberOfAdults(getNumberOfAdults - 1);

      const adultsObject = family.adults;
      const deletetedItem = adultsObject.pop();

      if (deletetedItem._id) {
        setDeletedFamilyNodes([
          ...getDeletedFamilyNodes,
          parseInt(deletetedItem._id)
        ]);
      }

      dispatch(
        updateFamilyMemberAction({
          ...family,
          adults: adultsObject
        })
      );
    }
  };

  /**
   * increment the number of children
   * @param none
   * @returns
   *    1. increments local state with the number of children
   *    2. fires redux action and update the family object
   *    3. adds input field to add childs age
   */

  const incrementChild = () => {
    const ageIsZero =
      family.children[getNoOfChildren - 1] &&
      family.children[getNoOfChildren - 1].age === 0;

    if (ageIsZero) return;

    const noOfChildren = getNoOfChildren + 1;

    if (getNoOfChildren < 10) {
      setNoOfChildren(noOfChildren);
      addChildInput(noOfChildren);
    }
  };

  /**
   * decrement the number of children
   * @param object { key, age, _id }
   * @returns
   *    1. decrements local state with the number of children
   *    2. fires redux action and update the family object
   *    3. removes input field to add childs age
   */

  const decrementChild = ({ key, age, _id }) => {
    getNoOfChildren > 0 && setNoOfChildren(getNoOfChildren - 1);
    removeChildInput({ key, age, _id });
  };

  /**
   * adds input to update childs age
   * @param String noOfChildren, age
   * @returns adds input field in the form
   */

  const addChildInput = (noOfChildren, age) => {
    dispatch(
      updateFamilyMemberAction({
        ...family,
        children: [
          ...family.children,
          { age: age || 0, type: 'Child', id: noOfChildren }
        ]
      })
    );
  };

  /**
   * removes input that consists of childs age
   * @param object { key, _id }
   * @returns removes input field in the form
   */

  const removeChildInput = ({ key, _id }) => {
    const inputValues = family.children;
    inputValues.splice(key, 1);

    dispatch(
      updateFamilyMemberAction({
        ...family,
        children: inputValues
      })
    );

    /**
     * remove the child from the db if it pre existed in the database
     */

    if (_id) {
      setDeletedFamilyNodes([...getDeletedFamilyNodes, parseInt(_id)]);
    }
  };

  /**
   * updates the age values of individual children
   * @param (String, String) value, key
   * @returns parsed Integer
   */

  const updateAge = (value, key) => {
    let inputValues = family.children;
    inputValues[key] = {
      age: getNumericValue(value),
      type: 'Child',
      id: key + 1
    };

    setIsAgeUpdated(value);

    dispatch(
      updateFamilyMemberAction({
        ...family,
        children: inputValues
      })
    );
  };

  /**
   * handles form submission
   * @param object values
   * @returns fires redux actions to save the form data
   */

  const onSubmit = (values) => {
    const hasFamilyNodesToDelete = getDeletedFamilyNodes.length > 0;
    if (hasFamilyNodesToDelete) {
      dispatch(
        deleteFamilyMemberAction({
          ssn,
          items: getDeletedFamilyNodes
        })
      );
    }
    const data = {
      fid,
      ssn,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: getPhoneNumber,
      email: values.email,
      address1: values.address,
      city: values.city,
      postCode: values.postCode,
      salary: getSalary,
      family: {
        adults: family.adults,
        children: family.children
      },
      successUrl: `${routeService.onboarding_welcome_to_onboarding}?profile_update=success`,
      failedUrl: `${routeService.onboarding_welcome_to_onboarding}?profile_update=failed`,
      hasFamilyNodesToDelete
    };

    dispatch(updateUserProfileRequestAction(data));
  };

  price = price / 100;

  const selector = !appRoutingState.isUserSubscribed ? 'inactive' : 'active';

  const subscriptionActivity = t(
    `lang_${props.flow}:${props.view}.subscription.description.${selector}.text`,
    { price }
  );

  const isFamilyDetailsPending =
    getNoOfChildren === 0 && getNumberOfAdults === 0;

  const updateSalary = (value) => {
    setSalary(getNumericValue(value));
  };

  return (
    <>
      <div>
        <div className='profile profile-view '>
          <DashboardLayout
            {...props}
            hidePreviousButton={false}
            goBackUrl={routeService.onboarding_welcome_to_onboarding}>
            <div className='profile-container'>
              {userState.requesting ? (
                <div className='loading-indicator-container'>
                  {!props.error && (
                    <PuffLoader size={96} color={'var(--color-pink)'} loading />
                  )}
                </div>
              ) : (
                <>
                  <div className='profile-heading'>
                    <h1>{tprop.heading.text}</h1>
                  </div>
                  <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema(t)}>
                    {() => (
                      <Form className='form-group'>
                        <RowItemBlock heading={tprop.personalInfo.heading.text}>
                          <LineItemBlock icon='profile' className='name-block'>
                            <FormField
                              name='firstName'
                              className='form-control input-first-name'
                              placeholder={
                                tprop.personalInfo.input[0].placeholder
                              }
                              errorstyle='profile-form-error'
                            />
                            <FormField
                              name='lastName'
                              className='form-control input-last-name'
                              placeholder={
                                tprop.personalInfo.input[1].placeholder
                              }
                              errorstyle='profile-form-error'
                            />
                          </LineItemBlock>
                          <LineItemBlock icon='mobile-phone'>
                            <FormField
                              name='phone'
                              onChange={(e) =>
                                setPhoneNumber(getNumericValue(e.target.value))
                              }
                              value={getPhoneNumber}
                              placeholder={
                                tprop.personalInfo.input[2].placeholder
                              }
                              errorstyle='profile-form-error'
                            />
                          </LineItemBlock>
                        </RowItemBlock>
                        <RowItemBlock
                          exclamation={!isEmailVerified}
                          heading={tprop.email.heading.text}>
                          <LineItemBlock
                            icon='email'
                            editButtonWithUrl={routeService.profile_email}>
                            <FormField
                              className='form-control'
                              name='email'
                              value={email}
                              disabled
                            />
                          </LineItemBlock>
                        </RowItemBlock>

                        <RowItemBlock heading={tprop.address.heading.text}>
                          <LineItemBlock
                            icon='location'
                            className='address-line-block'>
                            <div className='address-container'>
                              <FormField
                                className='form-control address-large'
                                name='address'
                                placeholder={tprop.address.input[0].placeholder}
                                errorstyle='profile-form-error address-address'
                              />
                              <div className='address-duo'>
                                <FormField
                                  className=' form-control address-medium'
                                  name='city'
                                  placeholder={
                                    tprop.address.input[1].placeholder
                                  }
                                  errorstyle='profile-form-error address-city'
                                />

                                <FormField
                                  className='form-control address-small'
                                  name='postCode'
                                  placeholder={
                                    tprop.address.input[2].placeholder
                                  }
                                  errorstyle='profile-form-error address-postal-code'
                                />
                              </div>
                            </div>
                          </LineItemBlock>
                        </RowItemBlock>

                        <RowItemBlock
                          heading={tprop.salary.heading.text}
                          exclamation={!getSalary > 0 || getSalary === ''}>
                          <LineItemBlock
                            icon='salary'
                            exclamation={!getSalary > 0 || getSalary === ''}
                            unit={tprop.salary.input[0].currency}>
                            <input
                              type='text'
                              name='salary'
                              className='form-control'
                              value={getSalary}
                              onChange={(e) => updateSalary(e.target.value)}
                              placeholder={tprop.salary.input[0].placeholder}
                              errorstyle='profile-form-error'
                            />
                          </LineItemBlock>
                        </RowItemBlock>

                        <RowItemBlock
                          heading={tprop.family.heading.text}
                          exclamation={isFamilyDetailsPending && true}
                          className='family-content-left'>
                          <LineItemBlock
                            icon='family'
                            text=''
                            exclamation={isFamilyDetailsPending && true}
                            className='family-content'>
                            <div className='family-block'>
                              <div className='family-container'>
                                <div
                                  className='family-container decrement icon-minus'
                                  onClick={() => decrementAdult()}></div>
                                <div className='family-container input'>
                                  <AdultsNumberBox
                                    value={getNumberOfAdults}
                                    isSmallAvatar={false}
                                    labelText='Adult'
                                  />
                                  <FormField
                                    name='adults'
                                    value={getNumberOfAdults}
                                    hidden
                                  />
                                </div>
                                <div
                                  className='family-container increment icon-plus'
                                  onClick={() => incrementAdult()}></div>
                              </div>

                              <div className='child-container'>
                                <div className='family-container decrement'></div>
                                <div className='child-container input'>
                                  <ChildrenNumberBox
                                    value={getNoOfChildren}
                                    isSmallAvatar={true}
                                    labelText='Children'
                                  />

                                  <FormField
                                    name='children'
                                    value={getNoOfChildren}
                                    hidden
                                  />
                                </div>
                                <div
                                  className='child-container increment icon-plus'
                                  onClick={() => incrementChild()}></div>
                              </div>
                              {family.children &&
                                family.children.length > 0 && (
                                  <p>{tprop.family.input[1].description}</p>
                                )}
                              <AgeInputForChildren
                                updateAge={updateAge}
                                childrensObject={family.children}
                                decrementChild={decrementChild}
                                isAgeUpdated={getIsAgeUpdated}
                              />
                            </div>
                          </LineItemBlock>
                        </RowItemBlock>

                        {appRoutingState.isRetailUser &&
                          appRoutingState.isUserSubscribed && (
                            <RowItemBlock
                              heading={tprop.subscription.heading.text}>
                              <LineItemBlock
                                icon='subscription'
                                editButtonWithUrl={
                                  routeService.profile_subscription
                                }>
                                <FormField
                                  className='form-control'
                                  name='email'
                                  value={subscriptionActivity}
                                  disabled
                                />
                              </LineItemBlock>
                            </RowItemBlock>
                          )}

                        <div className='profile-button-container'>
                          <div className='text-center'>
                            {userState.requesting ? (
                              <ButtonLoader />
                            ) : (
                              <Button
                                className={`${props.btnColor}`}
                                type='submit'>
                                {tprop.buttons.text}
                              </Button>
                            )}
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
            </div>
          </DashboardLayout>
        </div>
      </div>
    </>
  );
}
