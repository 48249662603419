/** @format */

import React from 'react';
import Button from 'react-bootstrap/Button';
import PuffLoader from 'react-spinners/PuffLoader';
import SubscriptionModal from 'components/Modals/SubscriptionModal';

export default function CancelConfirmModal(props) {
  const {
    tprop,
    getShowModal,
    showCancelConfirm,
    abortCancellation,
    cancelSubscription,
    showCancelCompleted,
    showCancelProcessing,
    setCancellationComplete
  } = props;
  return (
    <>
      {showCancelConfirm && (
        <SubscriptionModal
          showModal={getShowModal}
          heading={tprop.modals[0].heading.text}
          message={tprop.modals[0].message.text}>
          <Button
            className='btn btn-pink btn-cancel'
            onClick={() => cancelSubscription()}>
            {tprop.modals[0].buttons[0].text}
          </Button>
          <Button
            className='btn btn-pink-transparent btn-abort '
            onClick={() => abortCancellation()}>
            {tprop.modals[0].buttons[1].text}
          </Button>
        </SubscriptionModal>
      )}

      {showCancelProcessing && (
        <SubscriptionModal
          showModal={getShowModal}
          heading={tprop.modals[1].heading.text}>
          <div className='loading-indicator-container'>
            <PuffLoader size={96} color={'var(--color-black)'} loading />
          </div>
        </SubscriptionModal>
      )}

      {showCancelCompleted && (
        <SubscriptionModal
          showModal={getShowModal}
          message={tprop.modals[2].message.text}>
          <Button
            className='btn btn-pink btn-continue'
            onClick={() => setCancellationComplete()}>
            {tprop.modals[2].buttons[0].text}
          </Button>
        </SubscriptionModal>
      )}
    </>
  );
}
