/** @format */

import React from 'react';
import config from 'config';
import { routeService } from 'services';
import Header from 'components/Layout/Header';
import PrevButton from 'components/PrevButton';
import LogoutButton from 'components/LogoutButton';
import TermsAndPolicy from 'components/TermsAndPolicy';
import DashboardLayout from 'components/Layout/DashboardLayout';

export default function HowWeChooseFunds(props) {
  const goBackUrl = routeService.onboarding_welcome_to_onboarding;
  const selector = `lang_how_we_choose_funds:how_we_choose_funds`;

  return (
    <>
      <div>
        {props.flowOrigin === config.constants.flowOrigins.profile ? (
          <DashboardLayout
            {...props}
            hidePreviousButton={false}
            goBackUrl={goBackUrl}>
            <div className='profile-resubscription-flow-container'>
              <TermsAndPolicy
                selector={selector}
                className='terms-policy-container-single-column'
              />
            </div>
          </DashboardLayout>
        ) : (
          <>
            <Header
              flow={props.flow}
              goBackUrl={goBackUrl}
              hidePreviousButton={false}
            />
            <PrevButton
              className='go-previous-button'
              flow={props.flow}
              goBackUrl={goBackUrl}
            />
            <div
              className='terms-policy-registration-view'
              id='registration-flow'>
              <LogoutButton flow={props.flow} />
              <TermsAndPolicy
                selector={selector}
                className='terms-policy-container-single-column'
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
