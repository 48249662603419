/** @format */

/*****************
 **  Root-Saga  **
 *****************/
import { all, fork } from 'redux-saga/effects';
import { loginSaga } from 'stateContainer/sagas/login-saga';
import { logoutSaga } from 'stateContainer/sagas/logout-saga';
import { createUserSaga } from 'stateContainer/sagas/create-user-saga';
import { updateEmailSaga } from 'stateContainer/sagas/update-email-saga';
import { listPaymentSaga } from 'stateContainer/sagas/list-payment-saga';
import { updatePOATextSaga } from 'stateContainer/sagas/update-poatext-saga';
import { fetchBaseDataSaga } from 'stateContainer/sagas/fetch-base-data-saga';
import { addStatePensionSaga } from 'stateContainer/sagas/add-state-pension-saga';
import { appRoutingStateSaga } from 'stateContainer/sagas/app-routing-state-saga';
import { listSubscriptionSaga } from 'stateContainer/sagas/list-subscription-saga';
import { fetchRoaringDataSaga } from 'stateContainer/sagas/fetch-roaring-data-saga';
import { fetchCompanyDataSaga } from 'stateContainer/sagas/fetch-company-data-saga';
import { storeSubscriptionSaga } from 'stateContainer/sagas/store-subscription-saga';
import { setChosenEmployerSaga } from 'stateContainer/sagas/set-chosen-employer-saga';
import { updateUserProfileSaga } from 'stateContainer/sagas/update-user-profile-saga';
import { cancelSubscriptionSaga } from 'stateContainer/sagas/cancel-subscription-saga';
import { deleteFamilyMemberSaga } from 'stateContainer/sagas/delete-family-member-saga';
import { sendVerificationCodeSaga } from 'stateContainer/sagas/send-verification-code-saga';
import { addPOASpecialCompanySaga } from 'stateContainer/sagas/add-poa-special-company-saga';
import { storePowerOfAttorneySaga } from 'stateContainer/sagas/store-power-of-attorney-saga';
import { createPowerOfAttorneySaga } from 'stateContainer/sagas/create-power-of-attorney-saga';
import { confirmVerificationCodeSaga } from 'stateContainer/sagas/confirm-verification-code-saga';
import { updateSubscriptionPricesSaga } from 'stateContainer/sagas/update-subscription-prices-saga';

import { setValidityForPowerOfAttorneySaga } from 'stateContainer/sagas/set-validity-for-power-of-attorney-saga';

const sagas = [
  loginSaga,
  logoutSaga,
  createUserSaga,
  listPaymentSaga,
  updateEmailSaga,
  updatePOATextSaga,
  fetchBaseDataSaga,
  addStatePensionSaga,
  appRoutingStateSaga,
  fetchRoaringDataSaga,
  listSubscriptionSaga,
  fetchCompanyDataSaga,
  storeSubscriptionSaga,
  updateUserProfileSaga,
  setChosenEmployerSaga,
  cancelSubscriptionSaga,
  deleteFamilyMemberSaga,
  sendVerificationCodeSaga,
  storePowerOfAttorneySaga,
  addPOASpecialCompanySaga,
  createPowerOfAttorneySaga,
  confirmVerificationCodeSaga,
  updateSubscriptionPricesSaga,
  setValidityForPowerOfAttorneySaga
];

function* rootSaga() {
  const sagaForks = sagas.map((saga) => fork(saga));
  yield all([...sagaForks]);
}

export default rootSaga;
