/** @format */

import React from 'react';
import config from 'config';
import Button from 'react-bootstrap/Button';
import { withRouter } from 'react-router-dom';
import { getPrevButtonDestinationUrl } from 'utils/getPrevButtonDestinationUrl';

function PrevButton(props) {
  const {
    flow,
    history,
    goBackUrl,
    location: { pathname },
    className
  } = props;

  const destinationUrl = getPrevButtonDestinationUrl(pathname);
  const goPrevious = () => {
    if (flow === config.constants.flows.registration) {
      return history.push(destinationUrl);
    } else if (flow === config.constants.flows.common) {
      return history.goBack();
    } else {
      return history.push(goBackUrl);
    }
  };
  return (
    <Button
      className={`go-previous-button btn-pink ${className}`}
      onClick={() => goPrevious()}
    />
  );
}

export default withRouter(PrevButton);
