/** @format */

import config from 'config';
import { isMobile } from 'react-device-detect';
import routeService from 'services/routeService';
import React, { useEffect, useState } from 'react';
import MobileHeader from 'components/Layout/Header/MobileHeader';
import RegularHeader from 'components/Layout/Header/RegularHeader';

export default function Header(props) {
  const [getWindowWidth, setWindowWidth] = useState(0);

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  const isMobileView =
    getWindowWidth <= config.app.mobileScreenMaxWidth || isMobile;

  const isRegistrationFlow = props.flow === config.constants.flows.registration;

  const homePageUrl = isRegistrationFlow
    ? routeService.register_welcome
    : routeService.onboarding_welcome_to_onboarding;

  const profileUrl = routeService.profile_my_account;

  return (
    <>
      {isMobileView && (
        <div className='mobile-header-container'>
          <MobileHeader
            {...props}
            profileUrl={profileUrl}
            isMobile={isMobileView}
            homePageUrl={homePageUrl}
            hidePreviousButton={true}
          />
        </div>
      )}
      {!isRegistrationFlow && !isMobileView && (
        <RegularHeader
          profileUrl={profileUrl}
          homePageUrl={homePageUrl}
          {...props}
        />
      )}
    </>
  );
}
