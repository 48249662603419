/** @format */

import React from 'react';
import config from 'config';
import { routeService } from 'services';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import LinkifyHelper from 'utils/linkifyHelper';
import PuffLoader from 'react-spinners/PuffLoader';
import SplitLayout from 'components/Layout/SplitLayout';
import { Link, Redirect, useLocation } from 'react-router-dom';
import DashboardLayout from 'components/Layout/DashboardLayout';
import PersonDataBlock from 'components/AgreeTermsAndPoa/PersonDataBlock';
import TermsAndConditions from 'components/AgreeTermsAndPoa/TermsAndConditions';

export default function ApprovePowerOfAttorney(props) {
  /** scroll window o the top for all views  */
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });

  const { tprop } = props;
  const { t } = useTranslation();

  const emailVerificationState = useSelector((state) => state.verification);

  const {
    data: { validity, expiryDate },
    poaText,
    requesting
  } = useSelector((state) => state.powerOfAttorney);

  const userState = useSelector((state) => state.user.data);

  const getButtonUrl = ({ key, url }) => {
    const samedevice = key === 1;

    if (props.flowOrigin === config.constants.flowOrigins.profile) {
      return `${routeService.profile_sign_power_of_attorney}?samedevice=${samedevice}`;
    }

    return `${url}?samedevice=${samedevice}`;
  };

  const poaValidity =
    validity === 'ongoing'
      ? t(`lang_registration:sign_power_of_attorney.validity.ongoing.text`)
      : t(`lang_registration:sign_power_of_attorney.validity.limited.text`, {
          expiryDate: expiryDate
        });

  const name = `${userState.firstName} ${userState.lastName}`;
  const validityText = `${tprop.top_section.validity.text} : ${poaValidity}`;

  const { search } = useLocation();
  const ssnMismatchFound = search.includes('ssnMismatch=true');
  const ssnErrorHeading = t(`lang_error:ssnMismatch.heading.text`);
  const ssnErrorDescription = t(`lang_error:ssnMismatch.description.text`);

  const appRoutingState = useSelector((state) => state.appRouting);

  const isRegisteredUserWithValidPoa =
    props.flowOrigin === config.constants.flowOrigins.profile &&
    appRoutingState.isPoaValid;

  const ApprovePoaContent = () => {
    return (
      <>
        <div className='content'>
          {ssnMismatchFound && (
            <div className='form-error'>
              <h5>{ssnErrorHeading}</h5>
              <p>{ssnErrorDescription}</p>
            </div>
          )}
          <PersonDataBlock itemName='profile' item={name} />
          <PersonDataBlock itemName='power-of-attorney' item={validityText} />

          {requesting ? (
            <div className='loading-indicator-container'>
              <PuffLoader size={96} color={'var(--color-black)'} loading />
            </div>
          ) : (
            <div
              className='poa-text-container'
              dangerouslySetInnerHTML={{ __html: poaText }}
            />
          )}

          <TermsAndConditions
            heading={tprop.bottom_section.heading}
            description={tprop.bottom_section.description}
            {...props}
          />

          <div className='approve-poa-logo-container'>
            <div className='logo-fullmaktskollen' />
          </div>
        </div>
        <div className='approve-poa-button-container'>
          <div className='approve-poa-submit-button-container'>
            {tprop.buttons.map((button, key) => (
              <Link key={key} to={getButtonUrl({ key, url: button.url })}>
                <Button className={`btn-primary-transparent ${props.btnColor}`}>
                  {button.text}
                </Button>
              </Link>
            ))}
          </div>
        </div>
      </>
    );
  };

  return (
    <LinkifyHelper>
      <div className='approve-poa-view'>
        {!userState.email && !emailVerificationState.isEmailVerified && (
          <Redirect to={routeService.register_welcome} />
        )}

        {isRegisteredUserWithValidPoa && (
          <Redirect to={routeService.register_welcome} />
        )}

        {props.flowOrigin === config.constants.flowOrigins.profile ? (
          <DashboardLayout
            {...props}
            hidePreviousButton={false}
            goBackUrl={routeService.profile_agree_to_terms}>
            <div className='profile-resubscription-flow-container'>
              <div className='resubscription-approve-poa-container'>
                <ApprovePoaContent />
              </div>
            </div>
          </DashboardLayout>
        ) : (
          <div id='registration-flow'>
            <SplitLayout
              flow={props.flow}
              stepsWizard={props.stepsWizard}
              className='postlogin-template approve-poa-container'
              backgroundImage='registration-welcome-left-background-image'>
              <ApprovePoaContent />
            </SplitLayout>
          </div>
        )}
      </div>
    </LinkifyHelper>
  );
}
