/** @format */

export const cardSettings = {
  style: {
    base: {
      color: '000000',
      fontFamily: 'Roboto, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '14px',
      '::placeholder': {
        color: '#bfb8af'
      }
    },
    invalid: {
      color: '#f36269',
      iconColor: '#fa7278'
    }
  }
};
