/** @format */

import React from 'react';

export default function ChildrenNumberBox(props) {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='102'
        height='66'
        viewBox='0 0 102 66'>
        <defs>
          <clipPath id='clipPath'>
            <rect width='20' height='20' fill='none' />
          </clipPath>
        </defs>
        <g
          id='Group_766'
          data-name='Group 766'
          transform='translate(-202 -266)'>
          <rect
            id='Rectangle_1495'
            data-name='Rectangle 1495'
            width='102'
            height='66'
            rx='20'
            transform='translate(202 266)'
            fill='#fac7c6'
          />
          <g id='Group_764' data-name='Group 764' transform='translate(147)'>
            <g
              id='Profile'
              transform='translate(81 298)'
              clipPath='url(#clipPath)'>
              <path
                id='Union_1'
                data-name='Union 1'
                d='M0,20V17.5c0-2.751,4.5-5,10-5s10,2.25,10,5V20ZM5,5a5,5,0,1,1,5,5A5,5,0,0,1,5,5Z'
                fill='#0c0c0c'
              />
            </g>
            <text
              id='_2'
              data-name='2'
              transform='translate(124 313)'
              fill='#0c0c0c'
              fontSize='20'>
              <tspan x='0' y='0'>
                {props.value}
              </tspan>
            </text>
          </g>
          <text
            id={props.labelText}
            transform='translate(236 284)'
            fill='#0c0c0c'
            fontSize='12'>
            <tspan x='0' y='0'>
              {props.labelText}
            </tspan>
          </text>
        </g>
      </svg>
    </>
  );
}
