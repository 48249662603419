/** @format */

// translate the error codes into readable text
export const translateErrorCode = (t, error_code) => {
  if (t(`lang_stripe_errors:${error_code}`) === error_code) {
    return t(`lang_stripe_errors:unknown_error`);
  } else {
    return t(`lang_stripe_errors:${error_code}`);
  }
};
