/** @format */

export const getIsUserExists = ({
  values,
  userState,
  emailVerificationState
}) => {
  const { email, phone } = values;

  const isEmailExists = email === userState.data.email;

  const isPhoneNumberExists = phone === userState.data.phone;

  const isEmailVerified = emailVerificationState.isEmailVerified;

  const isUserExists = isEmailVerified && isEmailExists && isPhoneNumberExists;

  return {
    isPhoneNumberExists,
    isEmailVerified,
    isUserExists
  };
};
