/** @format */

import React from 'react';
import config from 'config';
import { routeService } from 'services';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import getUrlParams from 'utils/getUrlParams';
import { useTranslation } from 'react-i18next';
import PuffLoader from 'react-spinners/PuffLoader';
import SplitLayout from 'components/Layout/SplitLayout';
import { getEncodedSignText } from 'utils/getEncodedSignText';
import DashboardLayout from 'components/Layout/DashboardLayout';
import SocialSecurityNumber from 'components/SocialSecurityNumber';
import getCriiptoDigitalSignatureUri from 'utils/getCriiptoDigitalSignatureUri';

export default function SignPowerOfAttorney(props) {
  const { t } = useTranslation();

  const { flow, tprop } = props;

  const userState = useSelector((state) => state.user.data);
  const powerOfAttorneyState = useSelector((state) => state.powerOfAttorney);
  const emailVerificationState = useSelector((state) => state.verification);

  const { samedevice } = getUrlParams(['samedevice']);
  const { origin } = window.location;

  const encodedSignText = getEncodedSignText(t);

  const clientReturnUrl =
    props.flowOrigin === config.constants.flowOrigins.profile
      ? `${origin}${routeService.profile_bankid_redirect}`
      : `${origin}${routeService.register_bankid_redirect}`;

  const sign_url = getCriiptoDigitalSignatureUri({
    samedevice,
    clientReturnUrl: clientReturnUrl,
    signtext: encodedSignText
  });

  const termsNotAgreedUrl =
    props.flowOrigin === config.constants.flowOrigins.profile
      ? `${origin}${routeService.profile_agree_to_terms}`
      : `${origin}${routeService.register_agree_to_terms}`;

  const SignPoaForm = () => {
    return (
      <>
        {tprop.heading && (
          <h1 className='sign-poa-heading'>{tprop.heading.text}</h1>
        )}

        <div className='sign-poa-text-container'>
          {tprop.description.map((item, key) => (
            <p key={key}>{item.text}</p>
          ))}
        </div>

        <div className='signature-power-of-attorney-container'>
          {samedevice === 'false' && (
            <>
              <div className='logo-bankid' />
              <div className='signature-ssn-label-container'>
                <p>{tprop.input.label}</p>
              </div>
            </>
          )}

          {samedevice === 'true' && (
            <div className='same-device-container'>
              <div className='loader-wrapper'>
                <PuffLoader size={96} color={'var(--color-black)'} loading />
              </div>
              <SocialSecurityNumber src={sign_url} title={tprop.input.label} />
            </div>
          )}

          {samedevice === 'false' && (
            <div className='another-device-container'>
              <div className='loader-wrapper'>
                <PuffLoader size={50} color={'var(--color-black)'} loading />
              </div>
              <SocialSecurityNumber src={sign_url} title={tprop.input.label} />
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {!userState.email && !emailVerificationState.isEmailVerified && (
        <Redirect to={routeService.register_welcome} />
      )}

      {!powerOfAttorneyState.isTermsAgreed && (
        <Redirect to={termsNotAgreedUrl} />
      )}

      {props.flowOrigin === config.constants.flowOrigins.profile ? (
        <div className='sign-poa-view'>
          <DashboardLayout
            {...props}
            hidePreviousButton={false}
            goBackUrl={routeService.profile_agree_to_terms}>
            <div className='profile-resubscription-flow-container'>
              <div className='resubscription-sign-poa-container'>
                <SignPoaForm />
              </div>
            </div>
          </DashboardLayout>
        </div>
      ) : (
        <div className='sign-poa-view' id='registration-flow'>
          <SplitLayout
            flow={flow}
            className='postlogin-template sign-poa-container'>
            <SignPoaForm />
          </SplitLayout>
        </div>
      )}
    </>
  );
}
