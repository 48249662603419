/** @format */

import i18next from 'i18next';

const getRoute = (key) => {
  return i18next.t(`lang_route:${key}`);
};

const routeService = {
  register_start: getRoute(`register_start`),
  register_login_mode: getRoute(`register_login_mode`),
  register_login: getRoute(`register_login`),
  register_bankid_redirect: getRoute(`register_bankid_redirect`),
  common_logging_in: getRoute(`common_logging_in`),
  common_logging_out: getRoute(`common_logging_out`),
  register_welcome: getRoute(`register_welcome`),
  register_personal_info: getRoute(`register_personal_info`),
  register_validate_email: getRoute(`register_validate_email`),
  register_validating_email: getRoute(`register_validating_email`),
  register_set_power_of_attorney: getRoute(`register_set_power_of_attorney`),
  register_faq_power_of_attorney: getRoute(`register_faq_power_of_attorney`),
  register_agree_to_terms: getRoute(`register_agree_to_terms`),
  register_terms_and_conditions: getRoute(`register_terms_and_conditions`),
  register_privacy_policy: getRoute(`register_privacy_policy`),
  register_stripe_payment: getRoute(`register_stripe_payment`),
  register_approve_power_of_attorney: getRoute(
    `register_approve_power_of_attorney`
  ),
  register_sign_power_of_attorney: getRoute(`register_sign_power_of_attorney`),
  register_creating_power_of_attorney: getRoute(
    `register_creating_power_of_attorney`
  ),
  register_add_state_pension: getRoute(`register_add_state_pension`),
  register_registration_complete: getRoute(`register_registration_complete`),
  onboarding_welcome_to_onboarding: getRoute(
    `onboarding_welcome_to_onboarding`
  ),

  profile_home: getRoute(`profile_home`),
  profile_my_account: getRoute(`profile_my_account`),
  profile_subscription: getRoute(`profile_subscription`),
  profile_email: getRoute(`profile_email`),

  profile_set_power_of_attorney: getRoute(`profile_set_power_of_attorney`),
  profile_faq_power_of_attorney: getRoute(`profile_faq_power_of_attorney`),
  profile_agree_to_terms: getRoute(`profile_agree_to_terms`),
  profile_terms_and_conditions: getRoute(`profile_terms_and_conditions`),
  profile_how_we_choose_funds: getRoute(`profile_how_we_choose_funds`),
  profile_how_we_calculate: getRoute(`profile_how_we_calculate`),
  profile_sustainability_policy: getRoute(`profile_sustainability_policy`),
  profile_cookies: getRoute(`profile_cookies`),
  profile_privacy_policy: getRoute(`profile_privacy_policy`),
  profile_stripe_payment: getRoute(`profile_stripe_payment`),
  profile_approve_power_of_attorney: getRoute(
    `profile_approve_power_of_attorney`
  ),
  profile_sign_power_of_attorney: getRoute(`profile_sign_power_of_attorney`),
  profile_creating_power_of_attorney: getRoute(
    `profile_creating_power_of_attorney`
  ),
  profile_bankid_redirect: getRoute(`profile_bankid_redirect`)
};

export default routeService;
