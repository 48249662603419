/** @format */

import { createUserMutation } from 'services/graphqlService/mutations/create-user-mutation';
import { updateUserMutation } from 'services/graphqlService/mutations/update-user-mutation';
import { updateEmailMutation } from 'services/graphqlService/mutations/update-email-mutation';
import { updateUserPoaMutation } from 'services/graphqlService/mutations/update-user-poa-mutation';
import { createFamilyOnUserMutation } from 'services/graphqlService/mutations/create-family-mutation';
import { addStatePensionMutation } from 'services/graphqlService/mutations/add-state-pension-mutation';
import { updateAddressOnUserMutation } from 'services/graphqlService/mutations/update-address-mutation';
import { storeSubscriptionMutation } from 'services/graphqlService/mutations/store-subscription-mutation';
import { deleteFamilyMemberMutation } from 'services/graphqlService/mutations/delete-family-member-mutation';
import { addPOASpecialCompanyMutation } from 'services/graphqlService/mutations/add-poa-special-company-mutation';

export const mutations = {
  updateUserMutation,
  createUserMutation,
  updateEmailMutation,
  updateUserPoaMutation,
  addStatePensionMutation,
  storeSubscriptionMutation,
  createFamilyOnUserMutation,
  deleteFamilyMemberMutation,
  updateAddressOnUserMutation,
  addPOASpecialCompanyMutation
};
