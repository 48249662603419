/** @format */

/**
 *
 * Get registration completion states based on the user object in the database
 * @param {Object} {user : Object, caller: String}
 *  @user: {Object} Mandatory - the user object
 *  @caller: {String} Optional - the caller from where the user object initited from. This can be used for debugging purposes
 * @returns JSON object with boolean values for every the steps taken by the user in their profile
 */

export const getUserStatus = ({ user, caller }) => {
  /**
   *
   * default response object
   */
  const data = {
    caller,
    isPoaValid: false,
    isPoaViewed: false,
    isEmailVerified: false,
    isUserSubscribed: false,
    isStatePensionAdded: false,
    isRegistrationCompleted: false,
    isSubscriptionCancelled: false,
    showActionRequiredMessage: false
  };

  if (!user) {
    return data;
  }

  /**
   *
   * A user that has a retalBilling node attached must have subscribed at some point   *
   */

  data.isSubscriptionCancelled =
    user.retailBilling && user.retailBilling.subscription === null;

  /**
   *
   * return users subscription state in boolean form
   */

  data.isUserSubscribed =
    (user.retailBilling &&
      user.retailBilling.subscription &&
      user.retailBilling.subscription.subscriptionId !== null) ||
    false;

  /**
   *
   * The date when the user signed the POA for the first time.
   * This date will remain unchanged even when the POA is revoked   *
   */

  data.isPoaViewed =
    (user &&
      user.poa &&
      user.poa.poaSignedDate &&
      user.poa.poaSignedDate.length > 0) ||
    false;

  /**
   *
   * A user who once verified their email will have the validationCode node attached
   * The isEmailVerified boolean will show whether is was verified
   */

  data.isEmailVerified =
    (user.validationCode && user.validationCode.isEmailVerified) || false;

  /**
   *
   * A user who has completed registration will have the ppm and general StatePension nodes attached
   * The nodes will be created  even if the user did not enter any values
   */

  data.isStatePensionAdded = user ? user.statePension.length === 2 : false;

  /**
   *
   * A user who has viewed or created the POA and added state pension is considered a user who has completed registration
   */
  data.isRegistrationCompleted = data.isPoaViewed && data.isStatePensionAdded;

  /**
   *
   * user who has an invalid POA   *
   */
  data.isPoaValid = user && user.poa && user.poa.isPoaValid;

  /**
   *
   * show actionRequired message if one of POA or subscription is missing
   */
  if (data.hasValidEmployer) {
    data.showActionRequiredMessage = !data.isPoaValid;
  } else {
    data.showActionRequiredMessage =
      !data.hasValidEmployer &&
      (data.isSubscriptionCancelled ||
        !data.isUserSubscribed ||
        !data.isPoaValid);
  }
  return data;
};
