/** @format */

import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Formik, Form } from 'formik';
import { Modal } from 'react-bootstrap';
import { routeService } from 'services';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ButtonLoader from 'components/ButtonLoader';
import FormField from 'components/Forms/FormField';
import { useSelector, useDispatch } from 'react-redux';
import ValidatingEmailStatus from 'components/ValidatingEmailStatus';
import ValidateUpdateEmailSuccess from 'components/Modals/ValidateEmailModal/ValidateUpdateEmailSuccess';
import {
  sendVerificationCodeAction,
  confirmVerificationCodeAction
} from 'stateContainer/actions';
import { getValidationCodeSchema } from 'utils/getValidationCodeSchema';

export default function ValidateEmailModal(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { search } = useLocation();
  const { tprop, getShowModal, setShowModal } = props;

  const verificationFailedError = t(
    `lang_error:validateEmail.verificationFailed`
  );
  const technicalError = t(`lang_error:technicalError`);

  const {
    user: {
      data: { ssn, firstName }
    },
    verification: { error, requesting }
  } = useSelector((state) => state);

  const initialValues = {
    verificationCode: ''
  };

  const onSubmit = (values) => {
    const data = {
      ssn: ssn,
      email: props.newEmail,
      verificationCode: values.verificationCode,
      successUrl: `${routeService.onboarding_welcome_to_onboarding}?profile_update=success`,
      failedUrl: `${routeService.profile_email}?validation_failed`
    };
    dispatch(confirmVerificationCodeAction(data));
  };

  const requestResendCode = () => {
    dispatch(
      sendVerificationCodeAction({
        email: props.newEmail,
        firstName,
        successUrl: `${routeService.profile_email}?validation_failed`
      })
    );
  };

  const isValidationFailed = search.includes('validation_failed');
  const isValidationSuccess = search.includes('validation_success');

  return (
    <>
      <Modal
        className='message-modal validate-email-modal'
        show={getShowModal}
        onHide={() => setShowModal(false)}>
        {isValidationSuccess ? (
          <ValidateUpdateEmailSuccess flow={props.flow} />
        ) : (
          <div className='modal-container'>
            <div className='validate-email-modal-header'>
              <div className='validate-email-modal-icon'></div>
              <div className='validate-email-modal-heading'>
                <h1>{tprop.modal.heading.text}</h1>
              </div>
              <div className='validate-email-modal-description'>
                <p>{tprop.modal.description.text}</p>
              </div>
            </div>

            <div className='validate-email-modal-body '>
              {requesting ? (
                <ValidatingEmailStatus
                  isEmailVerified={false}
                  flow={props.flow}
                />
              ) : (
                <Formik
                  initialValues={initialValues}
                  validationSchema={getValidationCodeSchema(t)}
                  onSubmit={onSubmit}>
                  {({ isValid, dirty }) => (
                    <Form className='form-group validate-email-form'>
                      <div className='content'>
                        <Row>
                          <Col>
                            <FormField
                              name='verificationCode'
                              className='form-control validate-email-modal-form-control'
                              labeltext={
                                t(`lang_${props.flow}:validate_email.input`)[0]
                                  .label
                              }
                              errorstyle='validate-email-form-error'
                              labelstyle='validate-email-form-label'
                              maxLength='5'
                              autoComplete='false'
                            />
                          </Col>
                        </Row>
                        {requesting ? (
                          <ButtonLoader className='resend-validation-button-loader' />
                        ) : (
                          <div className='resend-code-button-container'>
                            <Button
                              type='button'
                              className='resend-code-button'
                              onClick={() => requestResendCode()}>
                              <small className='font-weight-bold'>
                                {tprop.modal.buttons[0].text}
                              </small>
                            </Button>
                          </div>
                        )}
                      </div>
                      {isValidationFailed && (
                        <div className='validation-error'>
                          <small>
                            {verificationFailedError.text}{' '}
                            <span
                              className='resend-link'
                              onClick={() => requestResendCode()}>
                              <u>{verificationFailedError.button.text}</u>
                            </span>
                          </small>
                        </div>
                      )}
                      {error && !isValidationFailed && (
                        <div className='validation-error'>
                          <small>{technicalError.text}</small>
                        </div>
                      )}
                      <div className='validate-email-modal-button'>
                        <Button
                          type='submit'
                          className={props.btnColor}
                          disabled={!isValid || !dirty}>
                          {tprop.modal.buttons[1].text}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
            <div className='validate-email-modal-buttons'>{props.children}</div>
          </div>
        )}
      </Modal>
    </>
  );
}
