/** @format */

import { mutations } from 'services';
import { push } from 'connected-react-router';
import { put, call, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';
function* workerSaga({ payload }) {
  const {
    email,
    firstName,
    successUrl,
    isUserExists,
    isEmailVerified,
    isPhoneNumberExists,
    setPowerOfAttorneyUrl
  } = payload;
  try {
    if (isUserExists && isPhoneNumberExists) {
      /**
       * skip user creation
       */

      yield put({ type: actionTypes.CREATE_USER_SKIPPED });
      yield put(push(setPowerOfAttorneyUrl));
    } else {
      yield call(mutations.createUserMutation, payload);

      /**
       * create and update user state with create user status
       */

      yield put({ type: actionTypes.CREATE_USER_SUCCESS });

      /**
       * update appRouting state with registration progress
       */

      yield put({
        type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
        payload: { createAndStoreUser: 'done' }
      });

      if (!isEmailVerified) {
        /**
         * resend email verification code
         */

        yield put({
          type: actionTypes.SEND_VERIFICATION_CODE_REQUESTED,
          payload: { email, firstName }
        });
        yield put(push(successUrl));
      } else {
        yield put(push(setPowerOfAttorneyUrl));
      }
    }
  } catch (error) {
    yield put({ type: actionTypes.CREATE_USER_FAILED, error });
  }
}

export function* createUserSaga() {
  yield takeLatest(actionTypes.CREATE_USER_REQUESTED, workerSaga);
}
