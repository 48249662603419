/** @format */

import { queries } from 'services';
import { getUserStatus } from 'utils/getUserStatus';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';
import { getEmploymentStatus } from 'utils/getEmploymentStatus';

function* workerSaga({ ssn }) {
  try {
    const baseData = yield call(queries.fetchBaseDataQuery, {
      ssn
    });
    const userProgress =
      (baseData &&
        baseData.User &&
        baseData.User.length > 0 &&
        getUserStatus({
          user: baseData.User[0],
          caller: 'fetch-base-data-saga'
        })) ||
      {};

    const employmentStatus =
      (baseData &&
        getEmploymentStatus({
          baseData: baseData,
          caller: 'fetch-base-data-saga'
        })) ||
      {};

    /**
     * request for person data from roaring
     */

    if (!userProgress.isEmailVerified && !userProgress.isPoaValid) {
      yield put({
        type: actionTypes.ROARING_DATA_REQUESTED,
        ssn
      });
    } else {
      yield put({
        type: actionTypes.ROARING_DATA_SKIPPED
      });
    }

    /**
     * check user given companyid for existence and if user is employed under the same
     */
    yield put({
      type: actionTypes.UPDATE_COMPANY_DATA_REQUESTED,
      data: employmentStatus.validEmployersList,
      ...employmentStatus
    });

    /**
     * fetch power of attorney text
     */

    yield put({
      type: actionTypes.UPDATE_POATEXT_REQUESTED,
      payload: { poaText: baseData.POAText }
    });

    /**
     * fetch subscription prices and tax
     */

    yield put({
      type: actionTypes.UPDATE_SUBSCRIPTION_PRICES_REQUESTED,
      payload: {
        retailPrice: baseData.RetailPrice,
        taxRate: baseData.TaxRate,
        stripeLocale: baseData.StripeLocale
      }
    });

    /**
     * check how far user had completed registration
     */

    yield put({
      type: actionTypes.__APP_ROUTING_DATA_REQUESTED,
      payload: {
        ...baseData,
        ...employmentStatus
      }
    });

    /**
     * check if user data already exists in the database
     */

    if (userProgress.isRegistrationCompleted) {
      const data = baseData.User[0];
      const retailBilling =
        (data.retailBilling && data.retailBilling.subscription) || {};

      Object.assign(data, {
        ...data.address,
        ...data.employee,
        ...retailBilling
      });

      /**
       * Organize the family array
       */

      const adults = [];
      const children = [];

      if (data.family.length > 0) {
        data.family.forEach((item) => {
          if (item.type === 'Adult') adults.push(item);

          if (item.type === 'Child') children.push(item);
        });
      }

      const family = {
        adults,
        children
      };

      /**
       * Replace the family object with the formatted one
       */

      data.family && delete data.family;
      data['family'] = family;

      /**
       * update user state if user data already exists in the database
       */

      yield put({
        type: actionTypes.UPDATE_USER_DATA,
        payload: data
      });

      /**
       * update the subscription state with subscription data
       */

      yield put({
        type: actionTypes.UPDATE_SUBSCRIPTION_SUCCESS,
        payload: baseData.User[0].retailBilling || {}
      });

      /**
       * update app routing state with progress
       */

      yield put({
        type: actionTypes.__APP_ROUTING_DATA_SUCCESS,
        payload: { ...userProgress }
      });
    }
    yield put({ type: actionTypes.FETCH_BASEDATA_SUCCESS });
  } catch (error) {
    console.log('error: ', error);
    yield put({ type: actionTypes.FETCH_BASEDATA_FAILED });
  }
}
export function* fetchBaseDataSaga() {
  yield takeLatest(actionTypes.FETCH_BASEDATA_REQUESTED, workerSaga);
}
