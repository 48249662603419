/** @format */

import { makeGraphQlRequest } from 'services/graphqlService/makeGraphQlRequest';

export const storeSubscriptionMutation = (data) => {
  return makeGraphQlRequest(`mutation {
    createSubscriptionAndBillingOnRetailUser(
        ssn: "${data.ssn}",      
        currentPeriodFrom: "${data.currentPeriodFrom}",
        currentPeriodTo: "${data.currentPeriodTo}",
        active: ${data.active},
        stripeCustomerId:"${data.stripeCustomerId}",
        subscriptionId: "${data.subscriptionId}",
        priceId:"${data.priceId}"
    ) {
      _id
    }
  }`);
};
