/** @format */

import config from 'config';
import { invokeRestApi } from 'utils/invokeRestApi';

const {
  services: {
    CREATE_POWER_OF_ATTORNEY_SERVICE_PATHNAME,
    STORE_POWER_OF_ATTORNEY_SERVICE_PATHNAME
  }
} = config.app;

/** create-power-of-attorney-service */
export async function createPowerOfAttorneyService(payload) {
  return await invokeRestApi({
    service: CREATE_POWER_OF_ATTORNEY_SERVICE_PATHNAME,
    payload
  });
}

/** store-power-of-attorney-service */
export async function storePowerOfAttorneyService(payload) {
  return await invokeRestApi({
    service: STORE_POWER_OF_ATTORNEY_SERVICE_PATHNAME,
    payload
  });
}

export default {
  storePowerOfAttorneyService,
  createPowerOfAttorneyService
};
