/** @format */

import { makeGraphQlRequest } from 'services/graphqlService/makeGraphQlRequest';

/**
 * Graphql mutation to add state pension on a user
 * @param {Object} data {ssn, ppmPensionValue, generalPensionValue}
 * @returns graphQL mutation
 */
export const addStatePensionMutation = (data) => {
  return makeGraphQlRequest(`mutation {
      addStatePensionOnUser(
        ssn: "${data.ssn}"
        ppmPensionValue: ${data.ppmPensionValue}
        generalPensionValue: ${data.generalPensionValue}
      ){
        _id
      }      
    }`);
};
