/** @format */

import * as actionTypes from 'stateContainer/action-types';

const initialState = {
  requesting: false,
  data: { family: { adults: [], children: [] } }
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    /** Create user **/
    case actionTypes.CREATE_USER_REQUESTED:
      return {
        ...state,
        requesting: true,
        data: { ...state.data, ...action.payload }
      };

    case actionTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        requesting: false
      };

    case actionTypes.CREATE_USER_SKIPPED:
      return { ...state, requesting: false };

    case actionTypes.CREATE_USER_FAILED:
      return {
        ...state,
        requesting: false,
        data:
          (action && action.data && { ...state.data, ...action.data }) ||
          state.data
      };

    /** update user data for users who have partially completed registration already */
    case actionTypes.UPDATE_USER_DATA:
      return {
        ...state,
        requesting: false,
        data: { ...state.data, ...action.payload }
      };
    case actionTypes.UPDATE_USER_FAMILY:
      return {
        ...state,
        requesting: false,
        data:
          {
            ...state.data,
            family: { ...action.payload }
          } || state.data
      };

    /**user-profile */
    case actionTypes.UPDATE_USER_PROFILE_REQUESTED:
      return {
        ...state,
        requesting: true
      };

    case actionTypes.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        requesting: false
      };

    case actionTypes.UPDATE_USER_PROFILE_SKIPPED:
      return { ...state, requesting: false };

    case actionTypes.UPDATE_USER_PROFILE_FAILED:
      return {
        ...state,
        requesting: false,
        error:
          (action && action.data && { ...state.data, ...action.data }) ||
          state.data
      };

    /**user-family deletion */
    case actionTypes.DELETE_USER_FAMILY_REQUESTED:
      return {
        ...state,
        requesting: true
      };

    case actionTypes.DELETE_USER_FAMILY_SUCCESS:
      return {
        ...state,
        requesting: false
      };

    case actionTypes.DELETE_USER_FAMILY_SKIPPED:
      return { ...state, requesting: false };

    case actionTypes.DELETE_USER_FAMILY_FAILED:
      return {
        ...state,
        requesting: false,
        error:
          (action && action.data && { ...state.data, ...action.data }) ||
          state.data
      };

    /**
     * base-data-actions
     */

    case actionTypes.FETCH_BASEDATA_REQUESTED:
      return { ...state, requesting: true };

    case actionTypes.FETCH_BASEDATA_SUCCESS:
      return { ...state, requesting: false };

    case actionTypes.FETCH_BASEDATA_FAILED:
      return { ...state, requesting: false };

    default:
      return state;
  }
};
