/** @format */

import * as Yup from 'yup';

export const validationSchema = (t) =>
  Yup.object({
    address: Yup.string().required(t(`lang_error:forms.required`)),
    city: Yup.string().required(t(`lang_error:forms.required`)),
    postCode: Yup.string().required(t(`lang_error:forms.required`)),
    email: Yup.string()
      .email(t(`lang_error:forms.formatInvalid`))
      .required(t(`lang_error:forms.required`)),
    phone: Yup.number().optional()
  });
