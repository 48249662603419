/** @format */

import { makeGraphQlRequest } from 'services/graphqlService/makeGraphQlRequest';
let date = new Date();
date = date.toISOString();

export const createFamilyOnUserMutation = ({ ssn, family }) => {
  return makeGraphQlRequest(`mutation {
    createFamilyOnUser(
      ssn:"${ssn}",
      type:"${family.type}",
      age:"${family.age}",
      createdAt:"${date}"
    ) {
      _id
    }
  }`);
};
