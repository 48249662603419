/** @format */

import {
  useStripe,
  useElements,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement
} from '@stripe/react-stripe-js';
import {
  fetchBaseDataAction,
  storeSubscriptionAction
} from 'stateContainer/actions';
import config from 'config';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import ButtonLoader from 'components/ButtonLoader';
import { useSelector, useDispatch } from 'react-redux';
import { routeService, subscriptionService } from 'services';
import PaymentSuccess from 'components/Modals/PaymentSuccessModal';
import { cardSettings } from 'views/Common/StripePayment/CheckoutForm/cardStyles';
import { translateErrorCode } from 'views/Common/StripePayment/CheckoutForm/translateErrorCode';

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const elements = useElements();
  const { tprop, btnColor } = props;

  const [messages, setMessages] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [processing, setProcessing] = useState('');
  const [succeeded, setSucceeded] = useState(false);

  const {
    city = '',
    ssn = null,
    phone = null,
    email = null,
    lastName = '',
    firstName = '',
    postCode = '',
    address1 = ''
  } = useSelector((state) => state.user.data || {});

  const handleChange = async (event) => {
    setDisabled(event.empty || event.error);
    setMessages(event.error ? translateErrorCode(t, event.error.code) : '');
  };

  /**
   * Create a Retail Stripe subscription with recurring payments
   * @param {Object} event
   * @returns creates a Stripe customer, subscription and returns the customer and subscription objects
   * @retrns error if the Stripe subscription process fails
   */

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    const cardElement = elements.getElement(CardNumberElement);

    const billing_details = {
      address: {
        city: city,
        line1: address1,
        postal_code: postCode
      },
      email: email,
      name: `${firstName} ${lastName}`
    };

    !!phone && (billing_details['phone'] = phone);

    /**
     * initiate  a Stripe card payment method to be used in a subscription
     */

    const { paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      billing_details,
      card: cardElement
    });

    /**
     * create a Stripe customer subscription
     */

    const createSubscription = await subscriptionService({
      priceId: props.priceId,
      taxRateId: props.taxRateId,
      stripeLocale: props.stripeLocale,
      paymentMethodId: paymentMethod.id,
      billing_details
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });

    if (
      createSubscription.response &&
      createSubscription.response.status &&
      createSubscription.response.status !== 200
    ) {
      /**
       * handle application or Stripe configuration errors while creating subscription
       */

      setMessages(translateErrorCode(t, 'unknown_error'));
      setProcessing(false);
      dispatch(fetchBaseDataAction(ssn));
    } else if (createSubscription.error) {
      /**
       * handle Stripe configuration errors while creating subscription
       */
      setMessages(translateErrorCode(t, createSubscription.error.code));
      setProcessing(false);
    } else {
      /**
       * Create Stripe subscription
       */

      const data = {
        ssn: ssn,
        active: true,
        priceId: props.priceId,
        subscriptionId: createSubscription.id,
        stripeCustomerId: createSubscription.customer,
        currentPeriodTo: createSubscription.current_period_end,
        currentPeriodFrom: createSubscription.current_period_start
      };

      switch (createSubscription.status) {
        case 'active': {
          dispatch(storeSubscriptionAction(data));
          setProcessing(false);
          setSucceeded(true);

          break;
        }

        case 'incomplete': {
          const confirmCardPayment = await stripe.confirmCardPayment(
            createSubscription.latest_invoice.payment_intent.client_secret
          );

          if (confirmCardPayment.error) {
            setMessages(translateErrorCode(t, confirmCardPayment.error.code));
            setProcessing(false);
            setSucceeded(false);
          } else {
            dispatch(storeSubscriptionAction(data));
            setProcessing(false);
            setSucceeded(true);
          }

          break;
        }

        default: {
          setMessages(`${translateErrorCode(t, 'unknown_error')}`);
          setProcessing(false);
          setSucceeded(false);
        }
      }
    }
  };

  const destinationUrl =
    props.flowOrigin === config.constants.flowOrigins.profile
      ? routeService.profile_approve_power_of_attorney
      : routeService.register_approve_power_of_attorney;

  return (
    <form id='payment-form' onSubmit={handleSubmit}>
      <div className='card-content'>
        <Row>
          <Col>
            <CardNumberElement
              id='card-number-element'
              options={cardSettings}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} className='col-card-expiry'>
            <CardExpiryElement
              id='card-expiry-element'
              options={cardSettings}
              onChange={handleChange}
            />
          </Col>
          <Col sm={6} className='col-card-cvc'>
            <CardCvcElement
              id='card-cvc-element'
              options={cardSettings}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </div>
      {!succeeded &&
        (processing ? (
          <ButtonLoader className='payment-button-loader' />
        ) : (
          <Button
            type='submit'
            disabled={processing || disabled || succeeded}
            id='submit'
            className={`btn-primary-transparent ${btnColor}`}>
            <span id='button-text'>{tprop.button.text}</span>
          </Button>
        ))}
      {messages && (
        <div className='payment-message-container'>
          <div className='icon-payment-error payment-message-logo'></div>
          <p className='payment-message-text result-message'>{messages}</p>
        </div>
      )}

      {succeeded && (
        <PaymentSuccess
          showModal={true}
          message={tprop.successMessage.text}
          destinationUrl={destinationUrl}
        />
      )}

      <div className='payment-disclaimer'>
        {tprop.disclaimer.map((item, key) => (
          <p key={key}>{item.text}</p>
        ))}
      </div>
    </form>
  );
}
