/** @format */

import * as actionTypes from 'stateContainer/action-types';

const initialState = { requesting: false, data: {} };

export const companyDataReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * update company data
     */
    case actionTypes.UPDATE_COMPANY_DATA_REQUESTED:
      return {
        ...state,
        requesting: true
      };

    case actionTypes.UPDATE_COMPANY_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload.validEmployersList,
        hasValidEmployer: action.payload.hasValidEmployer,
        hasMultipleEmployers: action.payload.hasMultipleEmployers,
        isCurrentlyEmployed: action.payload.isCurrentlyEmployed,
        defaultCompany: action.payload.defaultCompany,
        currentEmployer: action.payload.currentEmployer,
        isRetailUser: action.payload.isRetailUser,
        requesting: false
      };

    case actionTypes.UPDATE_COMPANY_DATA_FAILED:
      return { ...state, data: action.payload, requesting: false };

    case actionTypes.SET_CHOSEN_EMPLOYER_REQUESTED:
      return {
        ...state,
        requesting: true
      };

    case actionTypes.SET_CHOSEN_EMPLOYER_SUCCESS:
      return {
        ...state,
        chosenEmployer: action.payload,
        requesting: false
      };

    case actionTypes.SET_CHOSEN_EMPLOYER_FAILED:
      return {
        ...state,
        requesting: false
      };

    default:
      return state;
  }
};
