/** @format */

import React from 'react';
import * as Yup from 'yup';
import config from 'config';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { routeService } from 'services';
import Radio from 'components/Forms/Radio';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import DatePicker from 'components/Forms/DatePicker';
import { useSelector, useDispatch } from 'react-redux';
import SplitLayout from 'components/Layout/SplitLayout';
import DashboardLayout from 'components/Layout/DashboardLayout';
import { setValidityForPowerOfAttorneyAction } from 'stateContainer/actions';

const validationSchema = (t) =>
  Yup.object({
    validity: Yup.string().required(t(`lang_error:forms.required`))
  });

export default function SetValidityForPowerOfAttorney(props) {
  const { t } = useTranslation();
  const { tprop } = props;
  const { numberOfDisabledDays, poaExpiryYears } = config.app.poaDatepicker;

  const userState = useSelector((state) => state.user.data);
  const emailVerificationState = useSelector((state) => state.verification);

  let date = new Date();
  date = moment(date).add(numberOfDisabledDays, 'days');
  const defaultDate = date.format('yyyy-MM-DD');
  const defaultPoaExpiryDate = moment(date)
    .add(poaExpiryYears, 'years')
    .format('yyyy-MM-DD');

  const initialValues = {
    validity: 'ongoing',
    expiryDate: defaultDate
  };
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    const { validity } = values;
    let { expiryDate } = values;

    expiryDate = moment(expiryDate).format('yyyy-MM-DD');

    if (validity === 'ongoing') expiryDate = defaultPoaExpiryDate;

    const successUrl =
      props.flowOrigin === config.constants.flowOrigins.profile
        ? routeService.profile_agree_to_terms
        : routeService.register_agree_to_terms;

    dispatch(
      setValidityForPowerOfAttorneyAction({
        validity,
        expiryDate,
        successUrl
      })
    );
  };

  const appRoutingState = useSelector((state) => state.appRouting);

  const isRegisteredUserWithValidPoa =
    props.flowOrigin === config.constants.flowOrigins.profile &&
    appRoutingState.isPoaValid;

  const faqUrl =
    props.flowOrigin === config.constants.flowOrigins.profile
      ? routeService.profile_faq_power_of_attorney
      : tprop.faq.link.url;

  const ValidityContent = () => {
    return (
      <div className='text-left set-validity-for-poa-content'>
        <div className='split-layout-right-heading-container'>
          <h1>{tprop.heading.text}</h1>
        </div>

        <p>
          {tprop.description.text}{' '}
          <Link className='terms-url' to={faqUrl}>
            {tprop.faq.link.text}
          </Link>
        </p>
        {tprop.subheading.map((subheading, key) => (
          <span key={key}>
            <span className='split-layout-right-subheading-container'>
              <h2 className='subheading-text'>{subheading.text}</h2>
            </span>
            <div className='set-validity-for-poa-details-list'>
              {subheading.description &&
              Array.isArray(subheading.description) ? (
                <ul>
                  {subheading.description.map((description, ref) => (
                    <li key={ref}> {description.text}</li>
                  ))}
                </ul>
              ) : (
                <p>{subheading.description.text}</p>
              )}
            </div>
          </span>
        ))}
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => onSubmit(values)}
          validationSchema={validationSchema(t)}>
          {({ values }) => (
            <Form className='set-validty-for-poa-view-form'>
              <div className='content'>
                <div className='poa-validity-selector'>
                  <div className='poa-validity-selector-left'>
                    <Radio
                      type='radio'
                      name='validity'
                      value='ongoing'
                      labeltext={tprop.options[0].text}
                      labelstyle='radio-input'
                    />
                    <Radio
                      type='radio'
                      name='validity'
                      value='limited'
                      labeltext={tprop.options[1].text}
                      labelstyle='radio-input'
                    />
                  </div>
                  <div className='poa-validity-selector-right'>
                    <DatePicker
                      name='expiryDate'
                      dateFormat={tprop.datepicker.dateformat}
                      placeholder={tprop.datepicker.placeholder}
                      minDate={date.toDate()}
                      disabled={
                        values.validity === 'ongoing' || !values.validity
                      }
                    />
                  </div>
                </div>

                <div className='set-validity-for-poa-disclaimer'>
                  <div className='logo-fullmaktskollen' />
                  <div className='set-validity-for-poa-disclaimer-text-container'>
                    <small>
                      {tprop.disclaimer.text}{' '}
                      <a
                        className='terms-url font-weight-bold'
                        href={tprop.disclaimer.link.url}
                        target='_blank'
                        rel='noopener noreferrer'>
                        {tprop.disclaimer.link.text}
                      </a>
                    </small>
                  </div>
                </div>
              </div>
              <div className='set-validity-for-poa-submit-button'>
                <Button className={`${props.btnColor}`} type='submit'>
                  {tprop.buttons.text}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  return (
    <div className='set-validty-for-poa-view'>
      {!userState.email && !emailVerificationState.isEmailVerified && (
        <Redirect to={routeService.register_welcome} />
      )}

      {isRegisteredUserWithValidPoa && (
        <Redirect to={routeService.profile_agree_to_terms} />
      )}

      {props.flowOrigin === config.constants.flowOrigins.profile ? (
        <DashboardLayout
          {...props}
          hidePreviousButton={false}
          goBackUrl={routeService.onboarding_welcome_to_onboarding}>
          <div className='profile-set-validity-for-poa-container'>
            <ValidityContent />
          </div>
        </DashboardLayout>
      ) : (
        <div id='registration-flow'>
          <SplitLayout
            flow={props.flow}
            stepsWizard={props.stepsWizard}
            className='postlogin-template'
            backgroundImage='registration-welcome-left-background-image'>
            <ValidityContent />
          </SplitLayout>
        </div>
      )}
    </div>
  );
}
