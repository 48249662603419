/** @format */

import React from 'react';
import { routeService } from 'services';
import getUrlParams from 'utils/getUrlParams';
import { useLocation } from 'react-router-dom';

export default function BankIdRedirect() {
  const { search, pathname } = useLocation();

  const { id_token, error } = getUrlParams(['id_token', 'error']);

  const poaSuccessInProfileFlow =
    pathname.includes('profile') && search.includes('status=success');

  const poaSuccessInRegistrationFlow =
    !pathname.includes('profile') && search.includes('status=success');

  const poaFailedInProfileFlow =
    pathname.includes('profile') && search.includes('status=failed');

  const poaFailedInRegistrationFlow =
    !pathname.includes('profile') && search.includes('status=failed');

  if (id_token || error) {
    // id token or error received use it to authenticate to aws
    window.top.location.href = `${routeService.common_logging_in}${search}`;
  } else if (poaSuccessInProfileFlow) {
    // power_of_attorney signed successfully in profile flow
    window.top.location.href = `${routeService.profile_creating_power_of_attorney}${search}`;
  } else if (poaSuccessInRegistrationFlow) {
    // power_of_attorney signed successfully in registration flow
    window.top.location.href = `${routeService.register_creating_power_of_attorney}${search}`;
  } else if (poaFailedInProfileFlow) {
    // power_of_attorney signature failed  in profile flow
    window.top.location.href = `${routeService.profile_approve_power_of_attorney}${search}`;
  } else if (poaFailedInRegistrationFlow) {
    // power_of_attorney signature failed in registration flow
    window.top.location.href = `${routeService.register_approve_power_of_attorney}${search}`;
  } else {
    // user is logging out
    window.top.location.href = `${routeService.register_start}${search}`;
  }
  document.body.style.backgroundColor = 'var(--color-white)';
  return <></>;
}
