/** @format */

import React, { useEffect } from 'react';
import { routeService } from 'services';
import { useSelector } from 'react-redux';
import LogoutButton from 'components/LogoutButton';
import { Redirect, withRouter } from 'react-router-dom';

export default withRouter(function RegistrationComplete(props) {
  const { tprop, flow } = props;

  useEffect(() => {
    const that = props;
    const destinationUrl = `${routeService.onboarding_welcome_to_onboarding}`;
    setTimeout(() => {
      that.history.push(destinationUrl);
    }, 5000); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userState = useSelector((state) => state.user.data);
  const appRoutingState = useSelector((state) => state.appRouting);
  const emailVerificationState = useSelector((state) => state.verification);

  return (
    <>
      {!userState.email && !emailVerificationState.isEmailVerified && (
        <Redirect to={routeService.register_welcome} />
      )}
      {!appRoutingState.isPoaViewed && (
        <Redirect to={routeService.register_approve_power_of_attorney} />
      )}

      <LogoutButton flow={flow} />

      <div className='registration-complete-container centered-container centered-container-default-background-image'>
        <div className='centered-content'>
          <div className='registration-complete-top-container centered-content-top'>
            <div className='image-success'></div>

            <h1>{tprop.heading.text}</h1>
            <div className='registration-complete-top-contentlist'>
              {tprop.description.map((item, key) => (
                <p key={key}>{item.text}</p>
              ))}
            </div>
          </div>
          <div className='centered-content-bottom '>
            <div className='logo-the-third-act-white' />
            <div>
              <h2>{tprop.logo.text}</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
