/** @format */

import { makeGraphQlRequest } from 'services/graphqlService/makeGraphQlRequest';

export const updateEmailMutation = (data) => {
  return makeGraphQlRequest(`mutation {
    updateEmailOnUser(
      ssn: "${data.ssn}",      
      email:"${data.email}", 
      newEmail:"${data.newEmail}"
    ) {
      _id
    }
  }`);
};
