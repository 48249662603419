/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

export default function ActionRequiredMessage(props) {
  if (!props.selector) {
    return <> </>;
  }

  const headingText = props.tprop.actionMessage[props.selector].heading.text;

  return props.showActionMessage && !props.isMessageDismissed ? (
    <div className='action-required-message-container'>
      <div className='action-required-message-container-left'>
        <div className='action-required-message-content'>
          <div className='action-required-message-exclamation'>
            <div className='icon-action-exclamation'></div>
          </div>
        </div>
      </div>
      <div className='action-required-message-container-right'>
        <div className='action-required-message-content heading'>
          <h2>{headingText}</h2>
        </div>
        <div className='action-required-message-content button'>
          <Link to={props.actionUrl}>
            <Button className='btn btn-pink'>
              {props.tprop.actionMessage.buttons[0].text}
            </Button>
          </Link>
        </div>
        <div className='action-required-message-content'>
          <Link
            to='#'
            className='action-required-message-content-dismiss'
            onClick={() => props.setIsMessageDismissed(true)}>
            {props.tprop.actionMessage.buttons[1].text}
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
