/** @format */

// import { push } from 'connected-react-router';
import { mutations } from 'services';
import { put, takeLatest, call } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  try {
    yield call(mutations.storeSubscriptionMutation, payload);

    /**
     * update store with storesubscription status
     */

    yield put({ type: actionTypes.STORE_SUBSCRIPTION_SUCCESS, payload });

    /**
     * update appRouting state with registration progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { saveStripeSubscription: 'done' }
    });

    yield put({
      type: actionTypes.__APP_ROUTING_DATA_SUCCESS,
      payload: { isUserSubscribed: true, isSubscriptionCancelled: false }
    });
  } catch (error) {
    yield put({ type: actionTypes.STORE_SUBSCRIPTION_FAILED, error });
  }
}

export function* storeSubscriptionSaga() {
  yield takeLatest(actionTypes.STORE_SUBSCRIPTION_REQUESTED, workerSaga);
}
