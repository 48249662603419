/** @format */

import rootSaga from 'stateContainer/sagas';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import { history } from 'stateContainer/history';
import rootReducer from 'stateContainer/reducers';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const sagaMiddleware = createSagaMiddleware();
const enhancers = [];
const middleware = [
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  sagaMiddleware,
  routerMiddleware(history)
];

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [
    'appRouting',
    'company',
    'powerOfAttorney',
    'roaring',
    'router',
    'session',
    'statePension',
    'subscription',
    'user',
    'verification'
  ]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  connectRouter(history)(persistedReducer),
  composeWithDevTools(applyMiddleware(...middleware, ...enhancers))
  // other store enhancers if any
);
export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
