/** @format */

import * as actionTypes from 'stateContainer/action-types';

export const loginRequestAction = (payload) => ({
  type: actionTypes.LOGIN_REQUESTED,
  payload
});

export const fetchBaseDataAction = (payload) => ({
  type: actionTypes.FETCH_BASEDATA_REQUESTED,
  ssn: payload
});

export const logoutRequestAction = (payload) => ({
  type: actionTypes.LOGOUT_REQUESTED,
  payload
});

export const sessionIsAboutToExpireMessageAction = (payload) => ({
  type: actionTypes.SESSION_ABOUT_TO_EXPIRE_MESSAGE_REQUESTED,
  payload
});

export const confirmEndOfSessionAction = (payload) => ({
  type: actionTypes.CONFIRM_END_OF_SESSION_REQUESTED,
  payload
});

export const roaringDataRequestAction = (payload) => ({
  type: actionTypes.ROARING_DATA_REQUESTED,
  payload
});

export const createUserRequestAction = (payload) => ({
  type: actionTypes.CREATE_USER_REQUESTED,
  payload
});

export const updateUserProfileRequestAction = (payload) => ({
  type: actionTypes.UPDATE_USER_PROFILE_REQUESTED,
  payload
});

export const updateUserDataAction = (payload) => ({
  type: actionTypes.UPDATE_USER_DATA,
  payload
});

export const updateFamilyMemberAction = (payload) => ({
  type: actionTypes.UPDATE_USER_FAMILY,
  payload
});

export const deleteFamilyMemberAction = (payload) => ({
  type: actionTypes.DELETE_USER_FAMILY_REQUESTED,
  payload
});

export const sendVerificationCodeAction = (payload) => ({
  type: actionTypes.SEND_VERIFICATION_CODE_REQUESTED,
  payload
});

export const confirmVerificationCodeAction = (payload) => ({
  type: actionTypes.CONFIRM_VERIFICATION_CODE_REQUESTED,
  payload
});

export const updateEmailAction = (payload) => ({
  type: actionTypes.UPDATE_EMAIL_REQUESTED,
  payload
});

export const setValidityForPowerOfAttorneyAction = (payload) => ({
  type: actionTypes.SET_VALIDITY_FOR_POWER_OF_ATTORNEY,
  payload
});

export const isTermsAgreedAction = (payload) => ({
  type: actionTypes.SET_IS_TERMS_AGREED_REQUESTED,
  payload
});

export const storeSubscriptionAction = (payload) => ({
  type: actionTypes.STORE_SUBSCRIPTION_REQUESTED,
  payload
});

export const fetchListSubscriptionAction = (payload) => ({
  type: actionTypes.LIST_SUBSCRIPTION_DATA_REQUESTED,
  payload
});

export const fetchListPaymentsAction = (payload) => ({
  type: actionTypes.LIST_PAYMENT_DATA_REQUESTED,
  payload
});

export const cancelSubscriptionAction = (payload) => ({
  type: actionTypes.CANCEL_SUBSCRIPTION_REQUESTED,
  payload
});

export const createPowerOfAttorneyAction = (payload) => ({
  type: actionTypes.CREATE_POWER_OF_ATTORNEY_REQUESTED,
  payload
});

export const addStatePensionAction = (payload) => ({
  type: actionTypes.ADD_STATE_PENSION_REQUESTED,
  payload
});

export const setChosenEmployerAction = (payload) => ({
  type: actionTypes.SET_CHOSEN_EMPLOYER_REQUESTED,
  payload
});
