/** @format */

import { push } from 'connected-react-router';
import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga(action) {
  try {
    yield put(push(action.payload.successUrl));

    /**
     * update appRouting state with registration progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { setPOAValidity: 'done' }
    });
  } catch (error) {
    console.log('error: ', error);
  }
}

export function* setValidityForPowerOfAttorneySaga() {
  yield takeLatest(actionTypes.SET_VALIDITY_FOR_POWER_OF_ATTORNEY, workerSaga);
}
