/** @format */

import { makeGraphQlRequest } from 'services/graphqlService/makeGraphQlRequest';

export const createUserMutation = (data) => {
  return makeGraphQlRequest(`mutation {
    createUserOnCompanyV2(
      fid: "${data.fid}",
      ssn: "${data.ssn}",
      firstName: "${data.firstName}",
      lastName: "${data.lastName}",
      sex: "${data.sex}",
      birthDate: "${data.birthDate}",
      phone: "${data.phone}",
      email: "${data.email}",
      organisationId: "${data.organisationId}",
      knowledgeAssessmentDone: false,
      knowledgeAssessmentDate:"",
      secrecyMarked: false,
      isInPlanningPhase: false,
      isFreemium: false,
      address1: "${data.address1}",
      city: "${data.city}",
      address2: "",          
      postCode: "${data.postCode}",
      countryCode:"SE"
    ) {
      _id
    }
  }`);
};
