/** @format */

export const verificationCodeTemplate = ({ email, firstName }) => {
  return {
    template: 'tta_web_send_verification_code',
    source: 'NO_REPLY',
    templateData: {
      destination: `${email}`,
      dataFields: {
        name: `${firstName}`
      }
    }
  };
};
