/** @format */

import { push } from 'connected-react-router';
import { createPowerOfAttorneyService } from 'services';
import { put, takeLatest, call } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  try {
    const response = yield call(createPowerOfAttorneyService, payload);
    const data = {
      ...response,
      ...payload
    };

    /**
     * update powerOfAttorney state with poa progress
     */

    yield put({
      type: actionTypes.CREATE_POWER_OF_ATTORNEY_SUCCESS,
      ...data
    });

    /**
     * update appRouting state with registration progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { createPOAatFMK: 'done' }
    });

    /**
     * store signed power of attorney
     */

    yield put({
      type: actionTypes.STORE_POWER_OF_ATTORNEY_REQUESTED,
      ...data
    });
  } catch (error) {
    yield put({ type: actionTypes.CREATE_POWER_OF_ATTORNEY_FAILED, error });
    const isSsnMismatch =
      error.response && error.response.data && error.response.data.ssnMismatch;
    const returnUrl = isSsnMismatch
      ? `${payload.failedUrl}&ssnMismatch=true`
      : `${payload.failedUrl}`;

    yield put(push(returnUrl));
  }
}

export function* createPowerOfAttorneySaga() {
  yield takeLatest(actionTypes.CREATE_POWER_OF_ATTORNEY_REQUESTED, workerSaga);
}
