/** @format */

import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga(payload) {
  try {
    /**
     * update company state with users relationship with company
     */

    yield put({
      type: actionTypes.UPDATE_COMPANY_DATA_SUCCESS,
      payload
    });

    /**
     * update appRouting state with registration progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { fetchCompanyData: 'done' }
    });
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_COMPANY_DATA_FAILED,
      data: error.errors[0] || error
    });
  }
}

export function* fetchCompanyDataSaga() {
  yield takeLatest(actionTypes.UPDATE_COMPANY_DATA_REQUESTED, workerSaga);
}
