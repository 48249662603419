/** @format */

import config from 'config';
import { push } from 'connected-react-router';
import { loginService } from 'services';
import { call, put, take, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

const { sessionEndTime } = config.app;

function* workerSaga({ payload }) {
  try {
    const response = yield call(loginService, payload);

    /**
     * fetch all available user data
     */

    yield put({
      type: actionTypes.FETCH_BASEDATA_REQUESTED,
      ssn: response.ssn
    });

    /**
     * wait until base-data fetching is complete
     */

    yield take([
      actionTypes.FETCH_BASEDATA_SUCCESS,
      actionTypes.FETCH_BASEDATA_FAILED
    ]);

    /**
     * finally conclude the login action
     */

    yield put({
      type: actionTypes.LOGIN_SUCCESS,
      data: response,
      sessionEndTime
    });

    /**
     * update appRouting state with progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { loginSucceeded: 'done' }
    });

    yield put(push(payload.successUrl));
  } catch (error) {
    yield put({ type: actionTypes.LOGIN_FAILED, error });
    yield put(push(payload.failedUrl));
  }
}

export function* loginSaga() {
  yield takeLatest(actionTypes.LOGIN_REQUESTED, workerSaga);
}
