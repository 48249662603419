/** @format */

import React from 'react';

export default function NotificationMessage(props) {
  const { className, heading, description } = props;
  return (
    <div className={`alert alert-warning ${className ? className : ''}`}>
      <strong>{heading}</strong> {description}
    </div>
  );
}
