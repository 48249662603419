/** @format */

/********************
 **  Root-Reducer  **
 ********************/

import { combineReducers } from 'redux';
import { history } from 'stateContainer/history';
import { connectRouter } from 'connected-react-router';
import * as actionTypes from 'stateContainer/action-types';

import { userReducer } from 'stateContainer/reducers/user-reducer';
import { loginReducer } from 'stateContainer/reducers/login-reducer';
import { companyDataReducer } from 'stateContainer/reducers/company-data-reducer';
import { roaringDataReducer } from 'stateContainer/reducers/roaring-data-reducer';
import { appRoutingReducer } from 'stateContainer/reducers/app-routing-reducer.js';
import { subscriptionReducer } from 'stateContainer/reducers/subscription-reducer';
import { statePensionReducer } from 'stateContainer/reducers/state-pension-reducer';
import { powerOfAttorneyReducer } from 'stateContainer/reducers/power-of-attorney-reducer';
import { verificationCodeReducer } from 'stateContainer/reducers/verification-code-reducer.js';

const appReducer = combineReducers({
  appRouting: appRoutingReducer,
  company: companyDataReducer,
  powerOfAttorney: powerOfAttorneyReducer,
  roaring: roaringDataReducer,
  router: connectRouter(history),
  session: loginReducer,
  statePension: statePensionReducer,
  subscription: subscriptionReducer,
  user: userReducer,
  verification: verificationCodeReducer
});

const rootReducer = (state, action) => {
  if (action.type === actionTypes.LOGOUT_SUCCESS) {
    return (state = {
      router: state.router
    });
  }
  return appReducer(state, action);
};

export default rootReducer;
