/** @format */

import React from 'react';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';

export default function CollapsibleList(props) {
  return (
    <div key={props.eventKey}>
      <h1>
        <Accordion.Toggle
          as={Button}
          variant='link'
          className={`text-left ${props.className || ''}`}
          eventKey={props.eventKey}>
          {props.heading}
        </Accordion.Toggle>
      </h1>
      <Accordion.Collapse eventKey={props.eventKey}>
        <p>{props.text}</p>
      </Accordion.Collapse>
    </div>
  );
}
