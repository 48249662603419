/** @format */

import React from 'react';
import { useSelector } from 'react-redux';
import RegularHeaderLeft from 'components/Layout/Header/RegularHeader/RegularHeaderLeft';
import RegularHeaderRight from 'components/Layout/Header/RegularHeader/RegularHeaderRight';
import RegularHeaderCenter from 'components/Layout/Header/RegularHeader/RegularHeaderCenter';

export default function RegularHeader(props) {
  const userState = useSelector((state) => state.user);

  const { city = '', lastName = '', firstName = '' } = userState.data || {};

  const fullName = `${firstName} ${lastName}`;

  return (
    <>
      <div className='header'>
        <div className='header-inner'>
          <RegularHeaderLeft homePageUrl={props.homePageUrl} {...props} />

          <RegularHeaderCenter {...props} />

          <RegularHeaderRight
            flow={props.flow}
            location={city}
            fullName={fullName}
            profileUrl={props.profileUrl}
            {...props}
          />
        </div>
      </div>
    </>
  );
}
