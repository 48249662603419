/** @format */

import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  try {
    /**
     * store poatext in powerOfAttorney state
     */

    yield put({
      type: actionTypes.UPDATE_POATEXT_SUCCESS,
      payload: payload.poaText[0].text
    });

    /**
     * update appRouting state with progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { fetchPOAText: 'done' }
    });
  } catch (error) {
    yield put({ type: actionTypes.UPDATE_POATEXT_FAILED, error });
  }
}

export function* updatePOATextSaga() {
  yield takeLatest(actionTypes.UPDATE_POATEXT_REQUESTED, workerSaga);
}
