/** @format */

import { mutations } from 'services';
import { push } from 'connected-react-router';
import { put, takeLatest, call } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  try {
    yield call(mutations.addStatePensionMutation, {
      ...payload
    });

    /**
     * Loop through the poaSpecialCompanies array and store each of them in the database relating to the user
     */

    const data = payload.poaSpecialCompanies;
    if (data.length === 0) {
      yield put(push(payload.successUrl));
    } else {
      for (let i = 0; i < data.length; i++) {
        yield put({
          type: actionTypes.ADD_POA_SPECIAL_COMPANY_REQUESTED,
          payload: {
            ssn: payload.ssn,
            code: data[i],
            isLastItemInArray: i === data.length - 1,
            successUrl: payload.successUrl,
            failedUrl: payload.failedUrl
          }
        });
      }
    }

    /**
     * update statePension state once state-pension has been stored
     */

    yield put({
      type: actionTypes.ADD_STATE_PENSION_SUCCESS
    });

    /**
     * update appRouting state with registration progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { storeStatePensionData: 'done' }
    });

    yield put({
      type: actionTypes.__APP_ROUTING_DATA_SUCCESS,
      payload: { isStatePensionAdded: true, isRegistrationCompleted: true }
    });
  } catch (error) {
    yield put({
      type: actionTypes.ADD_STATE_PENSION_FAILED,
      data: error.errors[0]
    });
  }
}

export function* addStatePensionSaga() {
  yield takeLatest(actionTypes.ADD_STATE_PENSION_REQUESTED, workerSaga);
}
